import React, { useEffect, useState } from "react";
// import "./sharlotte-home.css";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import { useNavigate } from "react-router-dom";

function DSHelp() {
  const [state, setState] = useState({
    value: "Preferences",
    subscriptioncode: "",
    list: [],
  });
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    let list = [
      "Preferences",
      "Explore",
      "Home Finding",
      "Resources",
      "Diversity & Inclusion",
      "Profile",
    ];
    let subscriptioncode =
      localStorage.getItem("loginSubscribeDetails") != null
        ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
            .subscriptioncode
        : "";
    if (subscriptioncode == "Commander") {
      list = [
        "Preferences",
        "Explore",
        "Home Finding",
        "Resources",
        "Diversity & Inclusion",
        "Commander",
        "Profile",
      ];
      setState((prev) => ({
        ...prev,
        subscriptioncode: subscriptioncode,
        list: list,
      }));
    } else if (subscriptioncode == "ReloNavigator") {
      list = [
        "Preferences",
        "Explore",
        "Resources",
        "Diversity & Inclusion",
        "Commander",
        "Profile",
      ];
      setState((prev) => ({
        ...prev,
        subscriptioncode: subscriptioncode,
        list: list,
      }));
    }
  }, []);
  const selectrow = (row) => {
    setState((prev) => ({
      ...prev,
      value: row,
    }));
  };
  const goBack = () => {
    navigate("/dsLanding"); // Go back to the previous route
  };

  return (
    <div className="sharlotte-help-page">
      {/* <Header /> */}
      <div className="container">
        <h1 className="text-center mt-2 mb-5">Help section</h1>
        <div className="view">
          <h2>How to Navigate</h2>
          <p>
            <b>Welcome!</b> Below, you'll find helpful instructions on how to
            navigate the Destination Services portal.
          </p>

          <h1 className="backBtn">
            <button className="btn " onClick={goBack}>
              <i class="fa-solid fa-arrow-left-long"></i>
            </button>{" "}
          </h1>

          <ul className="help_ul nav nav-pills">
            {state.list.map((row, i) => {
              return (
                <li key={i} className={state.value == row ? "active" : ""}>
                  <button className="btn" onClick={() => selectrow(row)}>
                    {row}{" "}
                  </button>{" "}
                </li>
              );
            })}
          </ul>
        </div>
        {state.value == "Diversity & Inclusion" ? (
          <div className="view">
            <h2>Equality Index</h2>
            <p>
              You will be able to view state-by-state comprehensive information
              about statewide laws and policies related to major Equality
              issues.
            </p>
            <img src="./assets/images/D&I_1.png" alt="" />
            <h2>Score Cards</h2>
            <p>
              By clicking on Score cards, you will be able to view in detail
              information about issues that are supported, partially supported
              and Not supported by each state of the U.S.
            </p>
            <img src="./assets/images/D&I_2.png" alt="" />
            <h2>Religious Centers</h2>
            <p>
              By clicking on religious centers, you will be able to search for
              the religious centers around any location.
            </p>
            <img src="./assets/images/D&I_3.png" alt="" />
            {/* <h2>Diversity in Your Neighborhood</h2>
            <p>
              You will be able to generate a neighborhood report for each rental
              by clicking on the Generate report button and download by clicking
              on Download Report.
            </p>
            <img src="./assets/images/D&I_4.png" alt="" /> */}
          </div>
        ) : (
          ""
        )}
        {state.value == "Preferences" ? (
          <div className="view">
            <h2>Fill out your preferences</h2>
            <p>
              In order to get city and rental recommendations, you will need to
              fill out your preferences in the 'My Preferences' section. Your
              responses can be changed at any time. Please note, that the
              recommended cities and rentals may adjust if preferences are
              changed.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/1.png"
              alt=""
            />
          </div>
        ) : (
          ""
        )}
        {state.value == "Explore" ? (
          <div className="view">
            <h2>Choose your city</h2>
            <p>
              First, you need to decide which city is best for you and your
              lifestyle! A quick snapshot of each city is available. To see more
              details, click on the city name. You will be directed to the city
              page where you can see schools, points of interest, weather. You
              can also view other nearby cities and see what they have to offer.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/2.png"
              alt=""
            />
            <p>
              You can also explore any location by entering an address and
              clicking on Explore.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/2-2.png"
              alt=""
            />
          </div>
        ) : (
          ""
        )}
        {state.value == "Home Finding" ? (
          <div className="view">
            <h2>Look through rentals</h2>
            <p>
              There are two types of rentals listed for you to look through.
              Recommended Rentals are based on the preferences you enter in the
              My Preferences page. Percentages in the top right corner of the
              rentals represents how well they match with your preferences.
              Hover on to the percentages to view the preferences that are not
              matched.
            </p>

            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/3-1.png"
              alt=""
            />
            <p>
              Rentals can also be added by you by clicking on Add Rental option
              if you have already found some rentals during your own research.
              Please note, Rentals added by you will be showed under My Rentals
              section of Home Finding.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/3-2.png"
              alt=""
            />
            <p>
              You can always compare rentals by clicking on Compare Rentals
              option. This will allow you to see a quick comparison of the
              rentals.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/3-3.png"
              alt=""
            />
            <p>
              You can change your priorities at any time by clicking on the
              Rental Priorities option.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/3-4.png"
              alt=""
            />
            <p>
              By selecting More in the bottom right corner of a rental, you'll
              be directed to the rental's page, where you can view more details
              about the property, including amenities, weather, nearby transit,
              and more.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/3-5.png"
              alt=""
            />
            <p>
              If you're not too excited about a rental, click on the trash
              button in the bottom left of the rental. It will then be moved to
              the Not Interested section, which you will still have access to,
              just in case.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/3-6.png"
              alt=""
            />
            <p>
              By Clicking on the map icon, you will be directed to a page where
              you will be able to explore area around each rental. Click on the
              rental option and explore the categories that includes
              Restaurants, Groceries, Parks, Shopping, Entertainment and
              Hospitals.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/3-7.png"
              alt=""
            />
          </div>
        ) : (
          ""
        )}
        {state.value == "Resources" ? (
          <div className="view">
            <h2>Resources </h2>
            <p>
              By Clicking on Resources, you will be directed to a page where you
              will be able to create your personalized Renters and Pet Resume.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/4.png"
              alt=""
            />
          </div>
        ) : (
          ""
        )}
        {state.value == "Commander" ? (
          <div className="view">
            <h2>Contact your Consultant </h2>
            <p>
              Your consultant is always here to assist. You can view their
              calendar, Schedule appointment and message them at any time.
            </p>
            <p>
              By clicking on commander, you can view your consultant details,
              schedule appointment, view your Itinerary details and talk to your
              consultant through message center.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/5-1.png"
              alt=""
            />
            <p>
              Click on My Itinerary to view your home finding Tours and the
              properties you will be looking at.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/5-2.png"
              alt=""
            />
            <p>
              By Clicking on Message Center, you can talk to your consultant if
              you have any questions related to a particular rental or if you
              need any general information.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/5-3.png"
              alt=""
            />
          </div>
        ) : (
          ""
        )}
        {state.value == "Profile" ? (
          <div className="view">
            <h2>Profile </h2>
            <p>
              By Clicking on Profile icon in the top right corner, you will be
              able to view your details along with allotted hours and Used
              hours.
            </p>
            <img
              src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Home_Help_slides/6.png"
              alt=""
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
}

export default DSHelp;
