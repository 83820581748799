import React from "react";

function ButtonComponent(props) {
  const { state, questionList, onChange, questionIndex } = props;
  return (
    <>
      <div key={"sub" + questionIndex} className="checkbtns">
        <h1>{questionList.question}</h1>
        {questionList.description != null ? (
          <h6>{questionList.description}</h6>
        ) : null}
        {(" " + questionList.options)
          .split(",")
          .sort()
          .map((optionText, optionIndex) => {
            return (
              <button
                type="button"
                className={
                  state.answersPrefer[questionList.preferenceUId] != undefined
                    ? state.answersPrefer[
                        questionList.preferenceUId
                      ].preferencesanswer
                        .split(",")
                        .indexOf(optionText.trim()) > -1
                      ? "btn btn-clr me-md-4"
                      : "btn me-md-4"
                    : "btn"
                }
                key={optionIndex}
                onClick={(e) =>
                  onChange(
                    questionList.preferenceUId,
                    questionList.type,
                    optionText.trim()
                  )
                }
              >
                {optionText}
              </button>
              // <ButtonComponent
              //   state={state}
              //   onChange={onChange}
              //   questionList={questionList}
              //   optionText={optionText}
              //   optionIndex={optionIndex}
              // />
            );
          })}
        {state.isError &&
          state.answersPrefer[questionList.preferenceUId] != undefined &&
          state.answersPrefer[questionList.preferenceUId].preferencesanswer ==
            "" && (
            <div className="tooltip-validation">
              <div className="tooltip-inner">{state.toolTipText}</div>
            </div>
          )}
      </div>
    </>
  );
}

export default ButtonComponent;
