import React, { useEffect, useState } from "react";
import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";
import { GoogleMap } from "@react-google-maps/api";

function PublicTransit(props) {
  console.log(props, "propsline");
  const [state, setState] = useState({
    busline: "",
    railines: "",
    lines: "",
    transitMenu: "commute",
    workAddress: "",
    livingAddress: "",
    getDistance: 0,
    workAddressPreference: "",
    worklatitude: "",
    worklongitude: "",
    activeNumber: -1,
    activeMain: 0,
    map: "",
    markerBus: "",
    markerRail: "",
  });
  useEffect(() => {
    async function fetchData() {
      try {
        let workAddress = JSON.parse(await localStorage.getItem("workAddress"));
        workAddress = workAddress.preferencesanswer;
        console.log(workAddress);
        await setState((prev) => ({
          ...prev,
          workAddress: workAddress,
        }));
        jQuery(function ($) {
          $.getJSON(
            "https://maps.googleapis.com/maps/api/geocode/json?address=" +
              workAddress +
              "&key=AIzaSyDiSo4yN97tS6CIfMqJCXItYCwgzVkIJVc",
            (success) => {
              // console.log(workAddress, "success", success);
              //       setState((prev) => ({
              //         ...prev,
              //         worklatitude: success.results[0].geometry.location.lat,
              //         worklongitude: success.results[0].geometry.location.lng,
              //       }));
            }
          );
        });
      } catch (error) {
        console.log(error);
      }
      await method();
      await getRailsData();
    }
    fetchData();
  }, []);

  const getRailsData = async () => {
    const lines = props.lines;
    let rails = [];
    let busline = [];
    _.filter(lines, function (o) {
      if (
        o.route_summary[0].category == "Bus" ||
        o.route_summary[0].category == "bus"
      ) {
        busline.push(o);
      }
      if (
        o.route_summary[0].category == "Rail" ||
        o.route_summary[0].category == "rail"
      ) {
        rails.push(o);
      }
    });
    setState((prev) => ({ ...prev, busline: busline, railines: rails }));
    const markerss = [];
    const markers = [];
    var map = (map = new google.maps.Map(document.getElementById("map2"), {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      center: new google.maps.LatLng(props.latitude, props.longitude),
      zoom: 15,
    }));
    let latlong = props.latitude + ", " + props.longitude;
    var widget = new walkscore.TravelTimeWidget({
      map: map,
      origin: latlong,
      show: true,
      zoom: 15,
      mode: walkscore.TravelTime.Mode.DRIVE,
    });
    var infowindow = new google.maps.InfoWindow();
    var marker, i;
    function clearSelectedMarker() {
      markerss.forEach(function (marker) {
        marker.setIcon("assets/images/icons/bluetrain.svg");
      });
      markers.forEach(function (marker) {
        marker.setIcon("assets/images/icons/bluebus.svg");
      });
    }
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(props.latitude, props.longitude),
      map: map,
      icon:
        props.isProperty == true
          ? "../assets/images/propertyMarker.svg"
          : "../assets/images/maps-301.svg",
    });
    let isProperty = props.isProperty;
    let city = "";
    let city1 = "";
    if (isProperty == true) {
      city = JSON.parse(
        await localStorage.getItem("myPropertyDetails")
      ).address;
    } else {
      city1 = JSON.parse(await localStorage.getItem("cDetails")).city;
    }

    google.maps.event.addListener(
      marker,
      "click",
      (function (marker, i) {
        return function () {
          let address = "";
          if (isProperty == true) {
            address = city;
          } else {
            address = city1;
          }

          var contentString = "<h6>" + address + "</h6><p>" + "</p>";
          infowindow.setContent(contentString);
          infowindow.open(map, marker);
        };
      })(marker, i)
    );
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(state.worklatitude, state.worklongitude),
      map: map,
      icon: "../assets/images/home-icon.svg",
    });
    let workAddress = JSON.parse(await localStorage.getItem("workAddress"));
    workAddress = workAddress.preferencesanswer;
    google.maps.event.addListener(
      marker,
      "click",
      (function (marker, i) {
        return function () {
          var contentString = "<h6>" + workAddress + "</h6><p>" + "</p>";
          infowindow.setContent(contentString);
          infowindow.open(map, marker);
        };
      })(marker, i)
    );
    for (i = 0; i < rails.length; i++) {
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(rails[i].lat, rails[i].lon),
        map: map,
        icon: "assets/images/icons/bluetrain.svg",
      });

      google.maps.event.addListener(
        marker,
        "click",
        (function (marker, i) {
          return function () {
            var datass = [];
            for (var j = 0; j < rails[i].route_summary.length; j++) {
              datass.push(rails[i].route_summary[j].name);
            }
            var contentString =
              "<h6>" + rails[i].name + "</h6><p>" + datass + "</p>";
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
            clearSelectedMarker();
            marker.setIcon("assets/images/icons/redtrain.svg");
          };
        })(marker, i)
      );
      markerss.push(marker);
    }
    for (i = 0; i < busline.length; i++) {
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(busline[i].lat, busline[i].lon),
        map: map,
        icon: "assets/images/icons/bluebus.svg",
      });
      google.maps.event.addListener(
        marker,
        "click",
        (function (marker, i) {
          return function () {
            var datass = [];
            for (var j = 0; j < busline[i].route_summary.length; j++) {
              datass.push(busline[i].route_summary[j].name);
            }
            var contentString =
              "<h6>" + busline[i].name + "</h6><p>" + datass + "</p>";
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
            clearSelectedMarker();
            marker.setIcon("assets/images/icons/redbus.svg");
          };
        })(marker, i)
      );
      markers.push(marker);
    }

    setState((prev) => ({
      ...prev,
      map: map,
      markerBus: markers,
      markerRail: markerss,
      busline: busline,
      railines: rails,
    }));
  };
  const ChangeTransit = async (id) => {
    setState((prev) => ({ ...prev, transitMenu: id }));
    if ($("input[type=checkbox]").is(":checked") == false && id == "commute")
      $("input[type=checkbox]").click();
    if ($("input[type=checkbox]").is(":checked") == true && id != "commute")
      $("input[type=checkbox]").click();
  };
  const method = async () => {
    let myPropertyDetails = JSON.parse(
      await localStorage.getItem("myPropertyDetails")
    );
    let getSessionData = await LoginStore.getLoginInfo({});
    let userObject = {
      transfereeuniqeuid:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.userUniqueId
          : myPropertyDetails.transfereeUId,
      istemporary:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.istemporary
          : false,
    };
    let getQuestionList = await ApiServices.GetPreferencesList(userObject);
    let userPreferanceObject = {
      transfereeuniqeuid:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.userUniqueId
          : myPropertyDetails.transfereeUId,
      istemporary:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.istemporary
          : false,
    };
    let userSavedPreferances = await ApiServices.GetTransfereePreferences(
      userPreferanceObject
    );
    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      if (userAnswerPreferanceObject != undefined) {
        let answersPrefer = {};
        if (userAnswerPreferanceObject.length > 0) {
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;
          });
          let sampleAnswerArray = _.filter(
            getQuestionList.message.preferencesList,
            function (o) {
              return answersPrefer[o.preferenceUId] == undefined;
            }
          );
          if (sampleAnswerArray.length > 0) {
            let obj = sampleAnswerArray[0];
            obj.preferencesanswer = "";
            answersPrefer[sampleAnswerArray[0].preferenceUId] = obj;
          }
          setWorkAddress(answersPrefer, getQuestionList);
          setState((prev) => ({ ...prev, answersPrefer: answersPrefer }));
        } else {
          _.forEach(
            getQuestionList.message.preferencesList,
            function (answerObject) {
              let answerDefaultVal = "";
              if (answerObject.type == "money-range") {
                answerDefaultVal = "500, 1000";
              }
              if (answerObject.type == "Time-range") {
                answerDefaultVal = "15, 30";
              }
              answerObject.preferencesanswer = answerDefaultVal;
              answerObject.transfereeuniqeuid = getSessionData.userUniqueId;
              answerObject.preferencesmasteruniqueid =
                answerObject.preferenceUId;
              answersPrefer[answerObject.preferenceUId] = answerObject;
            }
          );
          setWorkAddress(answersPrefer, getQuestionList);
          setState((prev) => ({ ...prev, answersPrefer: answersPrefer }));
        }
      }
    }
  };
  const setWorkAddress = async (answersPrefer, getQuestionList) => {
    // console.log(answersPrefer, getQuestionList, "hello");
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });

      const preferenceUId = workAddressArray[0]?.preferenceUId;
      if (state.answersPrefer && preferenceUId !== undefined) {
        const preferencesanswer =
          state.answersPrefer[preferenceUId]?.preferencesanswer;
        if (preferencesanswer !== undefined) {
          await localStorage.setItem("address", preferencesanswer);
        }
      }
      var geocoder = new google.maps.Geocoder();
      let workAddress = {
        lat: answersPrefer[workAddressArray[0].preferenceUId].latitude,
        lng: answersPrefer[workAddressArray[0].preferenceUId].longitude,
        workAddress:
          answersPrefer[workAddressArray[0].preferenceUId] != undefined
            ? answersPrefer[workAddressArray[0].preferenceUId].preferencesanswer
            : "",
      };
      // console.log(workAddress, "workAddress");
      setState((prev) => ({ ...prev, workAddress: workAddress }));

      if (props.isSaproperty == true) {
        let myPropertyDetails = JSON.parse(
          await localStorage.getItem("saDetails")
        );
        let livingAddress = {
          lat: props.latitude,
          lng: props.longitude,
          workAddress:
            myPropertyDetails.address +
            ", " +
            myPropertyDetails.city +
            ", " +
            myPropertyDetails.state,
        };
        setState((prev) => ({ ...prev, livingAddress: livingAddress }));
        getDistanceFunc(workAddress, livingAddress);
      } else if (props.isSaproperty == false) {
        let myPropertyDetails = JSON.parse(
          await localStorage.getItem("myPropertyDetails")
        );
        let livingAddress = {
          lat: myPropertyDetails.latitude,
          lng: myPropertyDetails.longitude,
          workAddress: myPropertyDetails.address,
        };
        setState((prev) => ({ ...prev, livingAddress: livingAddress }));
        getDistanceFunc(workAddress, livingAddress);
      }
    }
  };
  const getDistanceFunc = async (workAddress, livingAddress) => {
    if (workAddress != "" && livingAddress != "") {
      let getDistance = getDistancebtn(workAddress, livingAddress);
      setState((prev) => ({ ...prev, getDistance: getDistance }));
    }
  };
  const flyToPlace = (row, index, type, i) => {
    // Check if placemarker exists and has the specified index
    const placemarker = type === "bus" ? state.markerBus : state.markerRail;
    if (placemarker && placemarker.length > index) {
      setState((prev) => ({ ...prev, activeNumber: i, activeMain: index }));
      let latLng = new google.maps.LatLng(row.lat, row.lon);
      // Check if map is defined and call panTo
      if (state.map) {
        state.map.panTo(latLng);
      }
      // Trigger the event only if placemarker[index] exists
      if (placemarker[index]) {
        google.maps.event.trigger(placemarker[index], "click");
      }
    } else {
      console.error(
        `Invalid placemarker or index: type=${type}, index=${index}`
      );
    }
  };
  const rad = (x) => {
    return (x * Math.PI) / 180;
  };

  const getDistancebtn = (p1, p2) => {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d * 0.000741371).toFixed(1); // returns the distance in meter
  };
  const setLocation = async (Id, e) => {
    e.persist();

    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id)
    );

    google.maps.event.addListener(places, "place_changed", async function () {
      var place = places.getPlace();

      if (Id == "gecode1") {
        let workAddress = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        if (state.livingAddress != "") {
          let getDistance = getDistancebtn(workAddress, state.livingAddress);
          setState((prev) => ({ ...prev, getDistance: getDistance }));
        }
        setState((prev) => ({ ...prev, workAddress: workAddress }));
      }
      if (Id == "gecode2") {
        let livingAddress = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        if (state.workAddress != "") {
          let getDistance = getDistancebtn(state.workAddress, livingAddress);
          setState((prev) => ({ ...prev, getDistance: getDistance }));
        }
        setState((prev) => ({ ...prev, livingAddress: livingAddress }));
      }
    });
  };
  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  return (
    <div className="row m-0 ">
      <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
        <div className="bb-white">
          <div className="communityNew-publictransitapp citybg">
            <div id={"map2"} className="b-radius-8 relomap-height"></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 p-0">
        <div className="map-right-view rounded-end-4 ">
          <span className="toggleArrow" onClick={() => heightView()}>
            <i class="fa-solid fa-angle-up"></i>
          </span>

          <div className="heightGrid">
            <div className="schools-btns">
              <a
                className={
                  state.transitMenu == "commute" ? "btn active" : "btn"
                }
                type="button"
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={() => ChangeTransit("commute")}
              >
                Commute
              </a>
              <a
                className={
                  state.transitMenu == "buslines" ? "btn active" : "btn"
                }
                type="button"
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={() => ChangeTransit("buslines")}
              >
                Bus Lines
              </a>
              <a
                className={
                  state.transitMenu == "raillines" ? "btn active" : "btn"
                }
                type="button"
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={() => ChangeTransit("raillines")}
              >
                Rail Lines
              </a>
            </div>

            <div className="transit-tabview">
              <div className="tab-content">
                {state.transitMenu == "raillines" && (
                  <div id="raillines">
                    <ul>
                      {state.railines != undefined &&
                      state.railines != "" &&
                      state.railines != null ? (
                        state.railines.map((row, index) => {
                          return row.route_summary.map((subrow, i) => {
                            return (
                              <li
                                key={i}
                                onClick={() =>
                                  flyToPlace(row, index, "rail", i)
                                }
                                className={
                                  state.activeNumber == i &&
                                  state.activeMain == index
                                    ? "active"
                                    : ""
                                }
                              >
                                <span className="main">
                                  {subrow.name}
                                  <span>
                                    <b>Route : </b>
                                    {row.name}
                                  </span>
                                </span>
                                <span className="mins">
                                  {row.distance.toFixed(1)} mi
                                </span>
                              </li>
                            );
                          });
                        })
                      ) : (
                        <h3 className="no-content-msg MiddleText">
                          No public transit data available
                        </h3>
                      )}
                    </ul>
                  </div>
                )}

                {state.transitMenu == "commute" && (
                  <div id="commute">
                    <p className="errorsms input-p">
                      <span>
                        <img
                          src="/assets/images/map-Orange_icon.png"
                          alt="img"
                          style={{ width: "16px" }}
                        />
                        <input
                          className="form-control"
                          placeholder="Enter work adddress"
                          onChange={(e) => setLocation("gecode1", e)}
                          type="text"
                          defaultValue={
                            Object.keys(state.workAddress).length > 0
                              ? state.workAddress.workAddress
                              : ""
                          }
                          id="gecode1"
                        />
                      </span>

                      <span>
                        <img
                          src="/assets/images/map-Green_icon.png"
                          alt="img"
                          style={{ width: "16px" }}
                        />{" "}
                        <input
                          className="form-control"
                          placeholder="Enter property/living adddress"
                          type="text"
                          id="gecode2"
                          onChange={(e) => setLocation("gecode2", e)}
                          defaultValue={
                            Object.keys(state.livingAddress).length > 0
                              ? state.livingAddress.workAddress
                              : ""
                          }
                        />
                      </span>
                    </p>
                    {state.getDistance != 0 ? (
                      <p className="errorsms">
                        <span className="dis">Distance (approx.) :</span>
                        <span id="distanceID"> {state.getDistance} Miles</span>
                      </p>
                    ) : null}
                  </div>
                )}

                {state.transitMenu == "buslines" && (
                  <div id="buslines">
                    <ul>
                      {state.busline != undefined &&
                      state.busline != "" &&
                      state.busline != null ? (
                        state.busline.map((row, index) => {
                          return row.route_summary.map((subrow, i1) => {
                            return (
                              <li
                                key={i1}
                                onClick={() =>
                                  flyToPlace(row, index, "bus", i1)
                                }
                                className={
                                  state.activeNumber == i1 &&
                                  state.activeMain == index
                                    ? "active"
                                    : ""
                                }
                              >
                                <span className="main">
                                  {subrow.name}
                                  <span>
                                    <b>Route : </b>
                                    {row.name}
                                  </span>
                                </span>
                                <span className="mins">
                                  {row.distance.toFixed(1)} mi
                                </span>
                              </li>
                            );
                          });
                        })
                      ) : (
                        <h3 className="no-content-msg MiddleText">
                          No public transit data available
                        </h3>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicTransit;
