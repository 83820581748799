import React, { useState, useEffect } from "react";
import "./Schedules.css";
// import "../../DestinationServicesStatic/Dsprofile/Dsprofile.css";
// import { momentLocalizer } from "react-big-calendar";
import Header from "../header and footer/Header";
// import Footer from "../header and footer/Footer";
import Footer from "../../../common/footer/footerWithoutchat";
import moment from "moment";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert";
import apiServices from "../../../../services/apiServices";
import ThirdPartyApi from "../../../../services/thirdPartyService";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import Modal from "antd/es/modal/Modal";
import { useRef } from "react";

import * as $ from "jquery";
import ModalService from "./modalPopUp/bookingPopUp";
import UpdatePopUP from "./modalPopUp/updatePopUp";
import ShowDetailsPopUp from "./modalPopUp/showDetailsPopUp";
const Schedule = () => {
  const [state, setstate] = useState({
    updateEdit: false,
    load: false,
    events: [],
    businessHours: [],
    selectInfo: "",
    succesMsg: "",
    isConfirmed: false,
    confirmError: "",
    betweenTwoSlots: {},
    Modalerror: false,
    ErrorMsg: "",
    typeId: "",
    serviceDrodown: "",
    serviceNote: "",
    start: "",
    end: "",
    // diffTime: timeDiff / (1000 * 3600),
    startTimeId: "",
    endTimeId: "",
    startTime: "",
    endTime: "",
    dayName: "",
    popupDate: "",
    dayNameLabel: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    betweenTwoSlots: {},
    countrycode: "",
    phoneNumber: "",
    EmailId: "",
    editedSlot: {
      availabledate: "",
      availablefromtime: "",
      availabletotime: "",
      typecode: "",
      start: "",
      end: "",
      title: "",
      rendering: "",
      backgroundColor: "",
    },

    businessHoursLoader: false,
    showModal: false,
  });
  const calendarRef = useRef();
  async function handleSelect(event) {
    console.log("event", event);
    let calendarApi = calendarRef.current.getApi();
    const results = await filterEvents(event.start, event.end);
    console.log("results", results);
    if (results.length == 0) {
      let currentDate = new Date();
      let date1 = new Date(event.start);

      let date2 = new Date(event.end);
      let timeDiff = Math.abs(date2.getTime() - date1.getTime());
      let startTime = moment(date1).format("hh:mm A");
      let endTime = moment(date2).format("hh:mm A");
      let dayName = date1.getDay();

      let popupDate = moment(date1).format("LL");

      setstate((prev) => ({
        ...prev,
        succesMsg: "",
        isConfirmed: false,
        confirmError: "",
        betweenTwoSlots: {},
        Modalerror: false,
        ErrorMsg: "",
        typeId: "",
        serviceDrodown: "",
        serviceNote: "",
        start: event.start,
        end: event.end,
        diffTime: timeDiff / (1000 * 3600),
        startTime: startTime,
        endTime: endTime,
        dayName: dayName,
        popupDate: popupDate,
      }));

      if (date1 >= currentDate && calendarApi.view.type != "dayGridMonth") {
        $("document").ready(function () {
          $("#myModalService").modal("show");
        });
      }
    }
    if (
      results.length > 0 &&
      results[0].rendering == "background" &&
      results[0].availablefromtime != undefined &&
      results[0].availabletotime != undefined
    ) {
      let CurrentDate = new Date();
      let date1 = new Date(results[0].start);
      let date2 = new Date(results[0].end);
      let timeDiff = Math.abs(date2.getTime() - date1.getTime());
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      let startTime = moment(date1).format("h:mm A");
      let endTime = moment(date2).format("h:mm A");
      let dayName = date1.getDay();
      let popupDate = moment(date1).format("LL");
      let startIndex = state.timeSlots
        ? state.timeSlots.indexOf(results[0].availablefromtime)
        : -1;
      let endIndex = state.timeSlots
        ? state.timeSlots.indexOf(results[0].availabletotime)
        : -1;

      // let timeSlots = state.timeSlots.slice(startIndex, endIndex + 1);
      let startTimeId = results[0].availablefromtime;
      console.log(endIndex, "endIndex");
      await setstate((prev) => ({
        ...prev,
        timeSlots: state.timeSlots,
        editedSlot: results[0],
        betweenTwoSlots: {},
        Modalerror: false,
        ErrorMsg: "",
        typeId: "",
        serviceDrodown: "",
        serviceNote: "",
        start: event.start,
        end: event.end,
        diffTime: timeDiff / (1000 * 3600),
        startTimeId: startTimeId,
        endTimeId: results[0].availabletotime,
        dayName: dayName,
        popupDate: popupDate,
      }));
      getTimeStamps();
      if (date1 >= CurrentDate && calendarApi.view.type != "dayGridMonth") {
        $("document").ready(function () {
          $("#update-event").modal("show");
        });
      }
    }

    // console.log("calendarApi", calendarApi.view.type);
  }
  // console.log("result", state.events);
  function filterEvents(start, end) {
    // console.log(start, "start", end);
    if (start != "" && end != "") {
      let results = state.events.filter((d) => {
        if (
          (d.start.getTime() >= start.getTime() &&
            d.start.getTime() < end.getTime()) ||
          (d.end.getTime() >= start.getTime() + 1 &&
            d.end.getTime() <= end.getTime() + 1) ||
          (d.start.getTime() <= start.getTime() &&
            d.end.getTime() >= end.getTime())
        ) {
          return d;
        }
      });
      return results;
    }
  }

  async function moveEdit(event) {
    console.log(event.event._def.extendedProps, "events");

    // setstate((prev) => ({ ...prev, updateEdit: true }));
    await setstate((prev) => ({ ...prev, meetinglink: "" }));
    let cuurentDate = parseInt(
      moment(new Date()).add(4, "hours").format("YYYYMMDDHHMM")
    );
    //let startDate = parseInt(moment(EditMoveDate).format("YYYYMMDDHHMM"));
    let startDate = parseInt(
      moment(new Date(event.event.start)).format("YYYYMMDDHHMM")
    );

    let date1 = new Date(event.event.start);
    let date2 = new Date(event.event.end);
    console.log(date1, "date1", date2);
    let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let startTime = moment(date1).format("h:mm A");
    let endTime = moment(date2).format("h:mm A");
    let dayName = date1.getDay();
    let popupDate = moment(date1).format("LL");
    event.event.startTime = moment(date1).format("hh:mm A");
    event.event.endTime = moment(date2).format("hh:mm A");

    if (
      event.event.extendedProps.typecode == "WORKING" &&
      cuurentDate < startDate
    ) {
      // console.log(event.event.extendedProps, "event.event.extendedProps");
      await localStorage.setItem(
        "eventDetails",
        JSON.stringify(event.event.extendedProps)
      );
      await setstate((prev) => ({
        ...prev,
        serviceNote: event.event.extendedProps.servicenotes,
        pickupLocation: event.event.extendedProps.pickuplocation,
        moveModalerror: false,
        ErrorMsgExitsMsg: "",
        EditEvent: event.event.title,
        EditMoveDate: new Date(event.event.start),
        eventDetails: event.event,
        startTimeId: event.event.startTime,
        endTimeId: event.event.endTime,
        dayName: dayName,
        popupDate: popupDate,
        transfareeemailId: event.event.extendedProps.emailid,
        transfareefirstname: event.event.extendedProps.firstname,
        transfareelastname: event.event.extendedProps.lastname,
        meetinglink: event.event.extendedProps.meetinglink,
        emailnotes: "",
        // editedSlot: event.event._def.extendedProps,
        editedSlot: {
          availabledate: event.event._def.extendedProps.availabledate,
          availablefromtime: event.event._def.extendedProps.availablefromtime,
          availabletotime: event.event._def.extendedProps.availabletotime,
          typecode: event.event._def.extendedProps.typecode,
          start: date1,
          end: date2,
          title: "",
          rendering: event.event._def.extendedProps.rendering,
          backgroundColor: "",
        },
      }));
      getTimeStamps();
      $("document").ready(function () {
        $("#update-event").modal("show");
      });
      // setstate((prev) => ({ ...prev, updateEdit: true }));
    } else if (
      event.event.extendedProps.type == "guest" &&
      cuurentDate > startDate
    ) {
      await setstate((prev) => ({
        ...prev,
        itineraryPropertiesList:
          event.event.extendedProps.itineraryPropertiesList,
        pickupLocation: event.event.extendedProps.pickuplocation,
        serviceNote: event.event.extendedProps.servicenotes,
        diffTime: timeDiff / (1000 * 3600),
        startTime: startTime,
        endTime: endTime,
        dayName: dayName,
        popupDate: popupDate,
        moveModalerror: false,
        ErrorMsgExitsMsg: "",
        EditEvent: event.event.title,
        EditMoveDate: new Date(event.event.start),
        eventDetails: event.event,
        transfareeemailId: event.event.extendedProps.emailid,
        transfareefirstname: event.event.extendedProps.firstname,
        transfareelastname: event.event.extendedProps.lastname,
        meetinglink: event.event.extendedProps.meetinglink,
        editedSlot: {
          availabledate: event.event._def.extendedProps.availabledate,
          availablefromtime: event.event._def.extendedProps.availablefromtime,
          availabletotime: event.event._def.extendedProps.availabletotime,
          typecode: event.event._def.extendedProps.typecode,
          start: date1,
          end: date2,
          title: "",
          rendering: event.event._def.extendedProps.rendering,
          backgroundColor: "",
        },
      }));
      $("document").ready(function () {
        $("#showDetails").modal("show");
      });
    }
  }
  async function getTimeStamps() {
    let periodsInADay = moment.duration(8, "hours").as("minutes");

    let timeLabels = [];
    let startTimeMoment = moment("0:00", "h:mm");
    for (let i = 0; i <= periodsInADay; i += 5) {
      startTimeMoment.add(i === 0 ? 0 : 15, "minutes");
      timeLabels.push(startTimeMoment.format("hh:mm A"));
    }
    setstate((prev) => ({ ...prev, timeSlots: timeLabels }));
  }
  async function onChangeEvent(e) {
    e.persist();
    let InputValue = e.target.value;
    setstate((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      typeId: InputValue,
    }));

    await setstate({ Modalerror: false, ErrorMsg: "" });
  }
  const onChangeTimeSlotEvent = async (e) => {
    e.persist();

    let InputValue = e.target.value;
    if (e.target.name == "startTimeId") {
      await setstate((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
        startTimeId: InputValue,
      }));
    } else {
      await setstate((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
        endTimeId: InputValue,
      }));
    }
    let startTime = moment(state.startTimeId, "HH:mm A");
    let endTime = moment(state.endTimeId, "HH:mm A");
    if (endTime.diff(startTime, "hours") < 1) {
      await setstate((prev) => ({
        ...prev,
        moveModalerror: true,
        ErrorMsgExitsMsg: "Please Select Slot of min 1 hr Duration",
      }));
    } else {
      await setstate((prev) => ({
        ...prev,
        Modalerror: false,
        ErrorMsg: "",
        moveModalerror: false,
        ErrorMsgExitsMsg: "",
      }));
    }
  };
  const updateEvent = async (isdelete) => {
    console.log(isdelete, "isdelete");
    await setstate((prev) => ({ ...prev, Modalerror: false, ErrorMsg: "" }));

    let result = await filterEvents(
      state.editedSlot.start,
      state.editedSlot.end
    );
    console.log(result, "result of the filter events");
    result = _.filter(result, function (item) {
      return item.rendering != "background" || item.rendering == undefined;
    });
    let startTime = state.startTimeId;
    let endTime = state.endTimeId;
    let checkResult = _.filter(result, function (item) {
      return (
        moment(endTime, ["h:mm A"]).format("HH:mm") >=
          moment(item.scheduletotime, ["h:mm A"]).format("HH:mm") &&
        moment(startTime, ["h:mm A"]).format("HH:mm") <=
          moment(item.schedulefromtime, ["h:mm A"]).format("HH:mm")
      );
    });
    let startTimeDiff = moment(state.startTimeId, "HH:mm A");
    let endTimeDiff = moment(state.endTimeId, "HH:mm A");

    if (endTimeDiff.diff(startTimeDiff, "seconds") < 100) {
      await setstate((prev) => ({
        ...prev,
        Modalerror: true,
        ErrorMsg: "Start time must be less than End time",
      }));
    } else if (checkResult.length == result.length) {
      if (result.length > 0 && isdelete) {
        await setstate((prev) => ({
          ...prev,
          Modalerror: true,
          ErrorMsg: "Please Move the Event Before Deleting the slot",
        }));
      } else {
        let selectedDayEvents = [];
        let events = state.events;
        let editedSlot = state.editedSlot;
        let userInfo = await ThirdPartyApi.getLoginInfo({});

        selectedDayEvents = _.filter(events, function (o) {
          if (o.availabledate != null && o.availabledate != undefined) {
            o.type = o.typecode;
            o.consultantuniqueid = userInfo.userUniqueId;
            return o.availabledate === editedSlot.availabledate;
          }
        });
        console.log(selectedDayEvents, "selectedDayEvents");
        let updateEventObject = {};
        selectedDayEvents = _.filter(selectedDayEvents, function (o) {
          return (
            o.availablefromtime != editedSlot.availablefromtime &&
            o.availabletotime != editedSlot.availabletotime
          );
        });
        console.log(selectedDayEvents, "selectedDayEvents2");
        if (selectedDayEvents.length < 1) {
          updateEventObject = {
            availabledate: editedSlot.availabledate,
            consultantuniqueid: userInfo.userUniqueId,
          };
        }
        if (!isdelete) {
          updateEventObject = {
            availabledate: editedSlot.availabledate,
            availablefromtime: state.startTimeId,
            availabletotime: state.endTimeId,
            consultantuniqueid: userInfo.userUniqueId,
            type: "WORKING",
          };
        }
        selectedDayEvents.push(updateEventObject);

        $("document").ready(function () {
          $("#update-event").modal("hide");
        });
        let createEvent = await apiServices.CreateConsultantSchedules(
          selectedDayEvents
        );
        setstate((prev) => ({ ...prev, load: true }));
        // console.log(createEvent, "createEvent");
        if (createEvent.error == 0) {
          getScheduleList();
          setstate((prev) => ({ ...prev, load: false }));
        }
      }
    } else {
      await setstate((prev) => ({
        ...prev,
        Modalerror: true,
        ErrorMsg: "Please Move the Event and Update the slot",
      }));
    }
  };
  // console.log("kkhkk", state);
  async function saveEvent() {
    setstate((prev) => ({ ...prev, load: true }));
    let continuosSlot = false;
    let selectedDayEvents = [];
    let userInfo = await ThirdPartyApi.getLoginInfo({});
    let createEventObject = {
      availabledate: moment(state.start).format("YYYY/MM/DD"),
      availablefromtime: state.startTime,
      availabletotime: state.endTime,

      consultantuniqueid: userInfo.userUniqueId,
      type: "WORKING",
    };
    createEventObject.availabledate = moment(
      createEventObject.availabledate
    ).format("YYYY-MM-DDTHH:mm:ss");

    let events = [];
    events = state.events;

    if (events.length > 0) {
      selectedDayEvents = events.filter((o) => {
        if (o.availabledate != null && o.availabledate != undefined) {
          o.type = o.typecode;

          o.consultantuniqueid = userInfo.userUniqueId;
          return o.availabledate === createEventObject.availabledate;
        }
      });
      if (selectedDayEvents > 0) {
        let checkBetweenTwoSlots = Object.assign([], selectedDayEvents);
        let duplecateCreateObject = Object.assign({}, createEventObject);

        selectedDayEvents.forEach(async (element) => {
          if (
            element.availabletotime === duplecateCreateObject.availablefromtime
          ) {
            continuosSlot = true;
            await getCheckDaySelectedAvailablity(
              duplecateCreateObject,
              1,
              checkBetweenTwoSlots
            );
            element.availabletotime = duplecateCreateObject.availabletotime;
          }
          if (
            element.availablefromtime === duplecateCreateObject.availabletotime
          ) {
            continuosSlot = true;
            await getCheckDaySelectedAvailablity(
              duplecateCreateObject,
              2,
              checkBetweenTwoSlots
            );
            element.availablefromtime = duplecateCreateObject.availablefromtime;
          }
        });
      }
    }

    if (!continuosSlot) {
      selectedDayEvents.push(createEventObject);
      console.log("selectedDayEvents12", selectedDayEvents);
    }
    if (
      continuosSlot &&
      state.betweenTwoSlots.availablefromtime != undefined &&
      state.betweenTwoSlots.availabletotime != undefined
    ) {
      selectedDayEvents.push(state.betweenTwoSlots);
      selectedDayEvents = selectedDayEvents.filter((o) => {
        return o.isBetween == undefined;
      });
    }
    let createEvent = await apiServices.CreateConsultantSchedules(
      selectedDayEvents
    );

    console.log("createEvent", createEvent);
    if (createEvent.error == 0) {
      await setstate((prev) => ({ ...prev, isConfirmed: true }));
      let eventDate = moment(createEventObject.availabledate).format(
        "YYYY/MM/DD"
      );
      createEventObject.start = new Date(
        eventDate + " " + createEventObject.availablefromtime
      );
      createEventObject.end = new Date(
        eventDate + " " + createEventObject.availabletotime
      );
      createEventObject.rendering = "background";
      createEventObject.backgroundColor = "#5bff31";
      console.log("createEventObject", createEventObject);
      // getAvailability();
    }
    getAvailability();
  }

  async function getCheckDaySelectedAvailablity(
    duplecateCreateObject,
    type,
    selectedDayEvents
  ) {}

  async function sendPhoneSms() {
    setstate((prev) => ({ ...prev, succesMsg: "" }));

    let data = {
      phoneNo:
        "+" +
        state.countrycode.replace("+", "") +
        state.phoneNumber.replace(" ", ""),
      type: 1,
      subject:
        "Your availability has been created on " +
        state.popupDate +
        " " +
        state.startTime +
        "-" +
        state.endTime,
    };

    let sendSms = await ThirdPartyApi.sendSms(data);

    if (sendSms.response) {
      setstate((prev) => ({
        ...prev,
        succesMsg: "Sms has been sent successfully!",
      }));
    } else {
      setstate((prev) => ({
        ...prev,
        confirmError: "Please try again!",
      }));
    }
  }

  async function sendEmailSms() {
    await setstate((prev) => ({ ...prev, succesMsg: "" }));
    let data = {
      email: state.EmailId,
      title: "WORKING",
      startTime: state.startTime,
      endTime: state.endTime,
      popupDate: state.popupDate,
      subject: "Availability Details",
    };
    let sendSms = await ThirdPartyApi.sendEmail(data);

    if (sendSms.response) {
      setstate((prev) => ({
        ...prev,
        succesMsg: "Mail has been sent successfully!",
      }));
    } else {
      setstate((prev) => ({
        ...prev,
        confirmError: "Please try again!",
      }));
    }
  }

  async function getAvailability() {
    await setstate((prev) => ({
      ...prev,
      businessHoursLoader: true,
      load: true,
    }));

    let userInfo = await ThirdPartyApi.getLoginInfo({});
    let calendarApi = calendarRef.current.getApi();

    let fromDate = moment(calendarApi.view.currentStart).format("MM-DD-YYYY");

    let toDate = moment(fromDate, "MM-DD-YYYY")
      .add(6, "days")
      .format("MM-DD-YYYY");

    let getScheduleObject = {
      consultantUId: userInfo.userUniqueId,

      fromDate: fromDate,
      toDate: toDate,
    };
    let schedules = await apiServices.GetConsultantScheduledTransferees(
      getScheduleObject
    );

    let businessHoursObject = {
      consultantUId: userInfo.userUniqueId,

      fromDate: fromDate,
      toDate: toDate,
      isconsultant: true,
    };

    let getbusinessHours = await apiServices.GetConsultantAvaliability(
      businessHoursObject
    );
    console.log("getbusinessHours", getbusinessHours);
    let events = [];
    let businessHours = [];

    schedules.message.scheduleslist.forEach(async (localEvent) => {
      let EditMoveDate = moment(localEvent.scheduledate).format("YYYY/MM/DD");

      let cuurentDate = parseInt(
        moment(new Date()).add(4, "hours").format("YYYYMMDDHHMM")
      );
      //let startDate = parseInt(moment(EditMoveDate).format("YYYYMMDDHHMM"));
      let startDate = parseInt(
        moment(
          new Date(EditMoveDate + " " + localEvent.schedulefromtime)
        ).format("YYYYMMDDHHMM")
      );
      let serviceTitle = "";
      if (localEvent.scheduleServicesList.length > 0) {
        localEvent.scheduleServicesList.forEach((service) => {
          serviceTitle = service.ServiceName + "," + serviceTitle;
        });
      }
      localEvent.start = new Date(
        EditMoveDate + " " + localEvent.schedulefromtime
      );
      localEvent.end = new Date(EditMoveDate + " " + localEvent.scheduletotime);
      localEvent.title =
        serviceTitle != ""
          ? serviceTitle.replace(/,\s*$/, "")
          : localEvent.servicename;
      (localEvent.serviceDrodown = localEvent.packageserviceuniqueid),
        (localEvent.pickuplocation = localEvent.pickuplocation),
        (localEvent.serviceNote = localEvent.servicenotes),
        (localEvent.type = "guest");
      localEvent.scheduleuniqueId = localEvent.scheduleuniqueId;
      localEvent.id = localEvent.scheduleuniqueId;
      localEvent.meetinglink = localEvent.meetinglink;

      localEvent.backgroundColor = cuurentDate < startDate ? "" : "#808080";
      events.push(localEvent);
    });

    if (getbusinessHours.error == 0) {
      await setstate((prev) => ({ ...prev, businessHoursLoader: false }));
      getbusinessHours.message.preferencesList.forEach(async (event) => {
        let cuurentDate = parseInt(moment(new Date()).format("YYYYMMDD"));
        let eventDate = moment(event.availabledate).format("YYYY/MM/DD");
        let businessDate = moment(event.availabledate).format("YYYYMMDD");

        event.start = new Date(eventDate + " " + event.availablefromtime);
        event.end = new Date(eventDate + " " + event.availabletotime);

        (event.title = ""), (event.rendering = "background");
        event.backgroundColor = "#5bff31";
        events.push(event);
        if (cuurentDate <= businessDate) {
          let NewHours = {
            startTime: moment(event.availablefromtime, ["h:mm A"]).format(
              "HH:mm"
            ),
            endTime: moment(event.availabletotime, ["h:mm A"]).format("HH:mm"),
            daysOfWeek: [moment(event.availabledate).day()],
            availabledateStart: new Date(
              moment(event.availabledate).format("MM-DD-YYYY") +
                " " +
                event.availablefromtime
            ),
            availabledateEnd: new Date(
              moment(event.availabledate).format("MM-DD-YYYY") +
                " " +
                event.availabletotime
            ),
          };
          businessHours.push(NewHours);
        }
      });
    }
    await state.events.push(events);
    await state.businessHours.push(businessHours);

    await setstate((prev) => ({
      ...prev,
      events: events,
      businessHours: businessHours,
      load: false,
    }));
  }

  useEffect(() => {
    loginCheck();
    // getAvailability();
    getScheduleList();
    let event = { start: moment().format("YYYY-MM-DD") };
    handleSelect(event);
  }, []);
  async function loginCheck() {
    let getSessionData = await ThirdPartyApi.getLoginInfo({});

    setstate((prev) => ({
      ...prev,
      EmailId: getSessionData.EmailId,
      PhoneNumber: getSessionData.PhoneNumber,
      countrycode: getSessionData.countrycode,
    }));
  }

  // async function eventStyleGetter(event, start, end, isSelected) {
  //   var backgroundColor = event.bgColor;
  //   var style = {
  //     backgroundColor: backgroundColor,
  //   };
  //   return {
  //     style: style,
  //   };
  // }

  async function onClose() {
    $("document").ready(function () {
      $("#myModalService").modal("hide");
    });
  }

  const getScheduleList = async () => {
    await setstate((prev) => ({ ...prev, businessHoursLoader: true }));
    let userInfo = await ThirdPartyApi.getLoginInfo({});
    let calendarApi = calendarRef.current.getApi();
    let fromDate = moment(calendarApi.view.currentStart).format("MM-DD-YYYY");
    let toDate = moment(fromDate, "MM-DD-YYYY")
      .add(6, "days")
      .format("MM-DD-YYYY");
    let getScheduleObject = {
      consultantUId: userInfo.userUniqueId,
      fromDate: fromDate,
      toDate: toDate,
    };
    let schedules = await apiServices.GetConsultantScheduledTransferees(
      getScheduleObject
    );
    console.log(schedules, "schedules");
    let businessHoursObject = {
      consultantUId: userInfo.userUniqueId,
      fromDate: fromDate,
      toDate: toDate,
      isconsultant: true,
    };

    let getbusinessHours = await apiServices.GetConsultantAvaliability(
      businessHoursObject
    );

    let events = [];
    let businessHours = [];

    console.log(schedules.message.scheduleslist, "==ioio==");
    _.forEach(schedules.message.scheduleslist, async (localEvent) => {
      let EditMoveDate = moment(localEvent.scheduledate).format("YYYY/MM/DD");

      let cuurentDate = parseInt(
        moment(new Date()).add(4, "hours").format("YYYYMMDDHHMM")
      );
      //let startDate = parseInt(moment(EditMoveDate).format("YYYYMMDDHHMM"));
      let startDate = parseInt(
        moment(
          new Date(EditMoveDate + " " + localEvent.schedulefromtime)
        ).format("YYYYMMDDHHMM")
      );
      let serviceTitle = "";
      if (localEvent.scheduleServicesList.length > 0) {
        _.forEach(localEvent.scheduleServicesList, async (service) => {
          serviceTitle = service.ServiceName + "," + serviceTitle;
        });
      }

      localEvent.start = new Date(
        EditMoveDate + " " + localEvent.schedulefromtime
      );
      localEvent.end = new Date(EditMoveDate + " " + localEvent.scheduletotime);
      localEvent.title =
        serviceTitle != ""
          ? serviceTitle.replace(/,\s*$/, "")
          : localEvent.servicename;
      (localEvent.serviceDrodown = localEvent.packageserviceuniqueid),
        (localEvent.pickuplocation = localEvent.pickuplocation),
        (localEvent.serviceNote = localEvent.servicenotes),
        (localEvent.type = "guest");
      localEvent.scheduleuniqueId = localEvent.scheduleuniqueId;
      localEvent.id = localEvent.scheduleuniqueId;
      localEvent.meetinglink = localEvent.meetinglink;

      localEvent.backgroundColor = cuurentDate < startDate ? "" : "#808080";
      events.push(localEvent);
    });
    if (getbusinessHours.error == 0) {
      await setstate((prev) => ({ ...prev, businessHoursLoader: false }));
      _.forEach(getbusinessHours.message.preferencesList, async (event) => {
        let cuurentDate = parseInt(moment(new Date()).format("YYYYMMDD"));
        let eventDate = moment(event.availabledate).format("YYYY/MM/DD");
        let businessDate = moment(event.availabledate).format("YYYYMMDD");

        event.start = new Date(eventDate + " " + event.availablefromtime);
        event.end = new Date(eventDate + " " + event.availabletotime);

        (event.title = ""), (event.rendering = "background");
        event.backgroundColor = "#5bff31";
        events.push(event);
        if (cuurentDate <= businessDate) {
          let NewHours = {
            startTime: moment(event.availablefromtime, ["h:mm A"]).format(
              "HH:mm"
            ),
            endTime: moment(event.availabletotime, ["h:mm A"]).format("HH:mm"),
            daysOfWeek: [moment(event.availabledate).day()],
            availabledateStart: new Date(
              moment(event.availabledate).format("MM-DD-YYYY") +
                " " +
                event.availablefromtime
            ),
            availabledateEnd: new Date(
              moment(event.availabledate).format("MM-DD-YYYY") +
                " " +
                event.availabletotime
            ),
          };
          businessHours.push(NewHours);
        }
      });
    }
    await state.events.push(events);
    await state.businessHours.push(businessHours);
    await setstate((prev) => ({
      ...prev,
      events: events,
      businessHours: businessHours,
    }));
  };

  async function handleEventResize(event) {
    console.log("handleEventResize", event);
  }
  const closeEditPopUp = () => {
    $("document").ready(function () {
      $("#update-event").modal("hide");
    });
  };
  const closeDetailsPopUp = () => {
    $("document").ready(function () {
      $("#showDetails").modal("hide");
    });
  };

  return (
    <div>
      {/* <Header /> */}
      {state.load == true ? <div className="loading">Loading</div> : ""}

      <div className="main_content  pt-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <FullCalendar
                ref={calendarRef}
                plugins={[
                  dayGridPlugin,
                  momentTimezonePlugin,
                  timeGridPlugin,
                  interactionPlugin,
                ]}
                eventResize={handleEventResize}
                initialView="timeGridWeek"
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                customButtons={{
                  prev: {
                    text: "prev",
                    click: () => {
                      let calendarApi = calendarRef.current.getApi();
                      getScheduleList();
                      calendarApi.prev();
                    },
                  },
                  next: {
                    text: "next",
                    click: () => {
                      let calendarApi = calendarRef.current.getApi();
                      getScheduleList();
                      calendarApi.next();
                    },
                  },
                  today: {
                    text: "Today",
                    click: () => {
                      let calendarApi = calendarRef.current.getApi();
                      getScheduleList();
                      calendarApi.today();
                    },
                  },
                }}
                events={state.events}
                weekends={true}
                eventClick={(event) => moveEdit(event)}
                selectLongPressDelay="25"
                slotEventOverlap={true}
                select={(event) => handleSelect(event)}
                selectable={true}
                slotDuration="00:15:00"
                slotLabelFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  omitZeroMinute: true,
                  meridiem: "long",
                }}
              />
            </div>
            <div className="col-md-12">
              <div className="calender-rightside main_cal_indicate">
                <div className="item">
                  <ul className="indicators mt-5">
                    <li className="one">
                      {" "}
                      <span></span> <b>---</b> Completed Appointments
                    </li>
                    <li className="two">
                      {" "}
                      <span></span> <b>---</b>Upcoming Appointments
                    </li>
                    <li className="four">
                      {" "}
                      <span></span> <b>---</b>WORKING
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="myModalService"
          className="modal fade"
          role="dialog"
          style={{ zIndex: 1060 }}
        >
          <ModalService
            state={state}
            onClose={onClose}
            saveEvent={saveEvent}
            sendPhoneSms={sendPhoneSms}
            sendEmailSms={sendEmailSms}
          />
        </div>

        <div
          id="update-event"
          className="modal fade"
          role="dialog"
          style={{ zIndex: 1060 }}
        >
          <UpdatePopUP
            state={state}
            updateEvent={updateEvent}
            onChangeTimeSlotEvent={onChangeTimeSlotEvent}
            onChangeEvent={onChangeEvent}
            closeEditPopUp={closeEditPopUp}
          />
        </div>
        <div
          id="showDetails"
          className="modal fade"
          role="dialog"
          style={{ zIndex: 1060 }}
        >
          <ShowDetailsPopUp
            state={state}
            closeDetailsPopUp={closeDetailsPopUp}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Schedule;
