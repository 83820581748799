import React from "react";
import moment from "moment";
import AppController from "../controllers/appController";

function Weather(props) {
  // console.log(props, "props");
  if (props.weather_current.length == 0 || props.weather.length === 0) {
    return (
      <div className="city-bg mb-4 mt-lg-4">
        <label className="checklist">
          No Weather data available at this locations
        </label>
      </div>
    );
  }
  const itemsWeather =
    parseInt(screen.availWidth) <= 767
      ? 1
      : parseInt(screen.availWidth) <= 991
      ? 2
      : 3;
  const startPosition = "0";
  let dateee = new Date(props.weather_current.dt * 1000);
  let weather = props.weather;
  for (let i = 0; i < 4; i++) {
    weather[i]["week"] = moment(dateee)
      .add("days", i + 1)
      .format("YYYY/MM/DD");
  }
  return (
    <div className="city-bg mb-4 mt-lg-4">
      <h1 className="">Weather</h1>
      <div className="weather-view-page row">
        <div className="col-md-6">
          <div className="weather-view-main-details">
            {/* <img
              src={
                "https://openweathermap.org/img/w/" +
                props.weather_current.weather[0].icon +
                ".png"
              }
              alt=""
              title=""
            /> */}
            <i class="fa-light fa-clouds"></i>
            <span>
              {props.weather_current.main.temp.toFixed(0)}
              <sup>o</sup>F
              <p>
                {moment(dateee).format("LL")} {moment(dateee).format("dddd")}
              </p>
            </span>
          </div>

          <div className="weather-view-sub-details">
            <ul>
              <li>
                <img src="/assets/images/air-icon.svg" alt="" />
                <span>
                  {props.weather_current.wind != undefined
                    ? props.weather_current.wind.speed
                    : "N/A"}
                  <sup>mph</sup>
                  <p>WIND</p>
                </span>
              </li>
              <li>
                <img src="/assets/images/rain-icon.svg" alt="" />
                <span>
                  {props.weather_current.clouds != undefined
                    ? props.weather_current.clouds.all
                    : "N/A"}
                  <sup>%</sup>
                  <p>Clouds</p>
                </span>
              </li>
              <li>
                <img src="/assets/images/water-drop-icon.svg" alt="" />
                <span>
                  {props.weather_current.main != undefined
                    ? props.weather_current.main.humidity
                    : "N/A"}
                  <sup>%</sup>
                  <p>HUMIDITY</p>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-6">
          <div className="weather-view-week-details">
            {weather.map((row, index) => {
              if (index < 3) {
                let dateResult = row.weatherData.date.split("-");
                let date =
                  dateResult[0] + "/" + dateResult[1] + "/" + dateResult[2];
                return (
                  <div className="item" key={index}>
                    <span>{AppController.getDayName(row.week)}</span>
                    <img
                      src={
                        "https://openweathermap.org/img/w/" +
                        row.weatherData.weather[0].icon +
                        ".png"
                      }
                      alt=""
                      title=""
                    />
                    <p>
                      {row.weatherDataMax.toFixed(0)}
                      <sup>o</sup>F
                    </p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Weather;
