import React from "react";

function SourceModal(props) {
  console.log(props.url, "url");
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#mySourceModal").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="ifram-html-view">
              <iframe src={props.url} title="Title"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SourceModal;
