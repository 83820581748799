import React from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

function SubmitPopUp(props) {
  const { state, setState } = props;
  const navigate = useNavigate();
  const bungalowNavigate = () => {
    // console.log("geetha");
    navigate("/myProperty");
  };
  const cityListNavigate = () => {
    // console.log("vani");
    navigate("/citylist");
  };
  return (
    <Modal
      open={state.submitPopUp}
      footer={null}
      onCancel={() => setState((prev) => ({ ...prev, submitPopUp: false }))}
    >
      <div className=" myModalconfirmdes">
        <div className="">
          <div>
            <div className="width-70per pd-y-60">
              <h6>Your preferences have been saved successfully!</h6>
              <div className=" justify-content-center">
                <button
                  className="btn theme-btn  mt-3"
                  onClick={bungalowNavigate}
                >
                  Home Finding
                </button>{" "}
                &nbsp;
                <button
                  className="btn theme-btn  mt-3"
                  onClick={cityListNavigate}
                >
                  City List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SubmitPopUp;
