import React from "react";
// import Modal from "react-modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tooltip, Popover, Spin, Modal } from "antd";

const compareModal = ({ isOpen, onClose, goToMap, state }) => {
  // console.log(state.finalObjectCampare, "finalObjectCampare");
  const Onclose = () => {
    $("#compare-community-Modal").modal("hide");
  };
  return (
    <div
      className="modal fadeInDown animated  modal-fullscreen-xl "
      id="compare-community-Modal"
      style={{
        //background: "rgba(0, 0, 0, 0.8)",
        opacity: "1",
        transition: "none",
        zIndex: 1060,
      }}
      tabIndex="1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div class="modal-header">
            <div class="item">
              <button class="btn" type="button" onClick={goToMap}>
                Show on the map &#x2197;
              </button>
            </div>
            <div class="item">
              <h4 class="modal-title">Compare Rentals</h4>
            </div>
            <div class="item">
              <span class="close" data-dismiss="modal" onClick={Onclose}>
                ×
              </span>
            </div>
          </div>

          <div className="modal-body mb-4">
            {state.finalObjectCampare && state.finalObjectCampare.length > 0 ? (
              <div>
                <div className="compare-details">
                  {/* {state.finalObjectCampare.map((row, index) => {
                    return row.propertyrankitems != null &&
                      row.propertyrankitems != "" &&
                      row.propertyrankitems != undefined ? (
                      <div>
                        <div className="item-address" key={"item" + index}>
                          <img
                            src={
                              row.property_image != "" &&
                              row.property_image != null
                                ? row.property_image.split(",")[0]
                                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                            }
                            alt=""
                          />

                          <div>
                            <h1>{row.propertyname}</h1>
                            <h2>
                              {typeof row.address == "object"
                                ? row.city
                                : row.address}
                            </h2>
                          </div>
                        </div>
                        <div className="" key={"item" + index}>
                          <table>
                            <tr>
                              <th>{}</th>
                              <th>{row.propertyname}</th>
                            </tr>
                            <tr>
                              <td> Commute</td>
                              <td></td>
                            </tr>
                          </table>
                          <div className="content">
                            <div>
                              <p>
                                Commute
                                <Tooltip title="Commute calculated from Work address to Property address">
                                  <i className="fa fa-info-circle"></i>
                                </Tooltip>
                                <span>{row.commutedistance} Miles </span>
                              </p>
                            </div>

                            <div>
                              <p>
                                Walk Score{" "}
                                <Tooltip
                                  title={
                                    row.walkscore >= 90
                                      ? "Walker's Paradise, Daily errands do not require a car."
                                      : row.walkscore >= 70 &&
                                        row.walkscore < 90
                                      ? "Very Walkable, Most errands can be accomplished on foot."
                                      : row.walkscore >= 50 &&
                                        row.walkscore < 70
                                      ? "Somewhat Walkable, Some errands can be accomplished on foot."
                                      : row.walkscore >= 25 &&
                                        row.walkscore < 50
                                      ? "Car-Dependent, Most errands require a car."
                                      : "Car-Dependent, Almost all errands require a car."
                                  }
                                >
                                  <i className="fa fa-info-circle"></i>
                                </Tooltip>
                                <span>{row.walkscore} </span>
                              </p>
                              <div className="progress ">
                                <div
                                  className="progress-bar clr_2"
                                  style={{
                                    width:
                                      Math.round((row.walkscore / 100) * 100) +
                                      "%",
                                  }}
                                  role="progressbar"
                                  aria-valuenow="70"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <span className="sr-only">70% Complete</span>
                                </div>
                              </div>
                            </div>

                            <div>
                              <p>
                                Bike Score{" "}
                                <Tooltip
                                  title={
                                    row.bikescore >= 90
                                      ? "Biker's Paradise, Daily errands can be accomplished on a bike."
                                      : row.bikescore >= 70 &&
                                        row.bikescore < 90
                                      ? "Very Bikeable, Biking is convenient for most trips."
                                      : row.bikescore >= 50 &&
                                        row.bikescore < 70
                                      ? "Bikeable, Some bike infrastructure."
                                      : "Somewhat Bikeable, Minimal bike infrastructure."
                                  }
                                >
                                  <i className="fa fa-info-circle"></i>
                                </Tooltip>
                                <span>{row.bikescore} </span>
                              </p>
                              <div className="progress ">
                                <div
                                  className="progress-bar clr_3"
                                  style={{
                                    width:
                                      Math.round((row.bikescore / 100) * 100) +
                                      "%",
                                  }}
                                  role="progressbar"
                                  aria-valuenow="70"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <span className="sr-only">70% Complete</span>
                                </div>
                              </div>
                            </div>

                            <div>
                              <p>
                                Monthly Rent
                                <span>
                                  {" "}
                                  ${" "}
                                  {row.monthlyrent != null
                                    ? row.monthlyrent
                                    : "N/A"}{" "}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p className="hometypPopup">
                                Home Type
                                <span>
                                  {" "}
                                  {row.hometype != null && row.hometype != ""
                                    ? row.hometype.replace(/,/g, ", ")
                                    : "N/A"}{" "}
                                </span>
                              </p>
                            </div>
                            {row.propertyrankitems != null &&
                              row.propertyrankitems != "" &&
                              row.propertyrankitems != undefined && (
                                <div>
                                  <span className="ScoreLabelPopup">
                                    <b>
                                      {Math.trunc(
                                        row.propertyrankitems.matchpercent
                                      )}
                                      %
                                    </b>
                                  </span>
                                </div>
                              )}
                            {row.propertyrankitems.propertyrankingitems !=
                              null &&
                              row.propertyrankitems.propertyrankingitems !=
                                "" &&
                              row.propertyrankitems.propertyrankingitems !=
                                undefined && (
                                <div className="priorityListPopUp">
                                  {row.propertyrankitems.propertyrankingitems.map(
                                    (Pitem) => {
                                      return (
                                        <p
                                          key={
                                            "popu" +
                                            index +
                                            Pitem.prioritylistitem
                                          }
                                        >
                                          {Pitem.prioritylistitem ==
                                          "Must Haves" ? (
                                            <Popover
                                              placement="leftTop"
                                              content={
                                                <div>
                                                  {Pitem.matcheditems.map(
                                                    (matcheditem) => {
                                                      return (
                                                        matcheditem.itemname !=
                                                          "" && (
                                                          <p
                                                            key={
                                                              "mmypp" +
                                                              index +
                                                              matcheditem.itemname
                                                            }
                                                          >
                                                            {
                                                              matcheditem.itemname
                                                            }
                                                            {matcheditem.ismatched ==
                                                              true && (
                                                              <i className="fa fa-check"></i>
                                                            )}
                                                            {matcheditem.ismatched ==
                                                              false && (
                                                              <i className="fa fa-times"></i>
                                                            )}
                                                          </p>
                                                        )
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              }
                                              title="Matched Must Haves"
                                              trigger="hover"
                                            >
                                              {Pitem.prioritylistitem}
                                              <i
                                                className="fa fa-info-circle"
                                                style={{ color: "#000000" }}
                                              ></i>
                                            </Popover>
                                          ) : (
                                            Pitem.prioritylistitem
                                          )}
                                          {Pitem.ismatched == true &&
                                            Pitem.isallmatched == false && (
                                              <i className="fa fa-check yellow_clr"></i>
                                            )}
                                          {Pitem.ismatched == true &&
                                            Pitem.isallmatched == true && (
                                              <i className="fa fa-check"></i>
                                            )}
                                          {Pitem.ismatched == true &&
                                            Pitem.isallmatched == null && (
                                              <i className="fa fa-check"></i>
                                            )}
                                          {Pitem.ismatched == false && (
                                            <i className="fa fa-times"></i>
                                          )}
                                        </p>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    );
                  })} */}
                </div>
                <table className="compare-table">
                  <tr>
                    <th></th>
                    {state.finalObjectCampare.map((row, index) => {
                      return row.propertyrankitems != null &&
                        row.propertyrankitems != "" &&
                        row.propertyrankitems != undefined ? (
                        <td>
                          <div className="item-address" key={"item" + index}>
                            <img
                              src={
                                row.property_image != "" &&
                                row.property_image != null
                                  ? row.property_image.split(",")[0]
                                  : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                              }
                              alt=""
                            />

                            <div>
                              <h1>{row.propertyname}</h1>
                              <h2>
                                {typeof row.address == "object"
                                  ? row.city
                                  : row.address}
                              </h2>
                            </div>
                          </div>
                        </td>
                      ) : (
                        ""
                      );
                    })}
                    {/* <td>one</td>
                    <td>two</td>
                    <td>three</td>
                    <td></td> */}
                  </tr>
                  {/* <tr>
                    <th>{""}</th>
                    {state.finalObjectCampare.map((row, index) => (
                      <td key={index}>{row.propertyname}</td>
                    ))}
                  </tr> */}
                  <tr>
                    <th>Match Percentage</th>
                    {state.finalObjectCampare.map((row, index) => (
                      <td key={index}>
                        {row.propertyrankitems != null &&
                          row.propertyrankitems != "" &&
                          row.propertyrankitems != undefined && (
                            <div>
                              <span className="ScoreLabelPopup">
                                <b>
                                  {Math.trunc(
                                    row.propertyrankitems.matchpercent
                                  )}
                                  %
                                </b>
                              </span>
                            </div>
                          )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>
                      Commute{" "}
                      <Tooltip title="Commute calculated from Work address to Property address">
                        <i className="fa fa-info-circle"></i>
                      </Tooltip>
                    </th>
                    {state.finalObjectCampare.map((row, index) => (
                      <td key={index}>{row.commutedistance} Miles</td>
                    ))}
                  </tr>
                  <tr>
                    <th>
                      Walk Score{" "}
                      <Tooltip
                        title={
                          "Very Walkable, Most errands can be accomplished on foot."
                          // row.walkscore >= 90
                          //   ? "Walker's Paradise, Daily errands do not require a car."
                          //   : row.walkscore >= 70 && row.walkscore < 90
                          //   ? "Very Walkable, Most errands can be accomplished on foot."
                          //   : row.walkscore >= 50 && row.walkscore < 70
                          //   ? "Somewhat Walkable, Some errands can be accomplished on foot."
                          //   : row.walkscore >= 25 && row.walkscore < 50
                          //   ? "Car-Dependent, Most errands require a car."
                          //   : "Car-Dependent, Almost all errands require a car."
                        }
                      >
                        <i className="fa fa-info-circle"></i>
                      </Tooltip>
                    </th>
                    {state.finalObjectCampare.map((row, index) => (
                      <td key={index}>{row.walkscore}</td>
                    ))}
                  </tr>
                  <tr>
                    <th>
                      {" "}
                      Bike Score{" "}
                      <Tooltip
                        title={
                          "Very Bikeable, Biking is convenient for most trips."
                          // row.bikescore >= 90
                          //   ? "Biker's Paradise, Daily errands can be accomplished on a bike."
                          //   : row.bikescore >= 70 && row.bikescore < 90
                          //   ? "Very Bikeable, Biking is convenient for most trips."
                          //   : row.bikescore >= 50 && row.bikescore < 70
                          //   ? "Bikeable, Some bike infrastructure."
                          //   : "Somewhat Bikeable, Minimal bike infrastructure."
                        }
                      >
                        <i className="fa fa-info-circle"></i>
                      </Tooltip>{" "}
                    </th>
                    {state.finalObjectCampare.map((row, index) => (
                      <td key={index}>{row.bikescore}</td>
                    ))}
                  </tr>
                  <tr>
                    <th>
                      Monthly Rent <span className="dollersign">($)</span>
                    </th>
                    {state.finalObjectCampare.map((row, index) => (
                      <td key={index}>
                        <span>
                          {" "}
                          {row.monthlyrent != null
                            ? row.monthlyrent
                            : "N/A"}{" "}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th> Home Type</th>
                    {state.finalObjectCampare.map((row, index) => (
                      <td key={index}>
                        <div>
                          {" "}
                          {row.hometype != null && row.hometype != ""
                            ? row.hometype.replace(/,/g, ", ")
                            : "N/A"}{" "}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>Budget</th>
                    {state.finalObjectCampare.map((row, index) => {
                      return (
                        row.propertyrankitems.propertyrankingitems != null &&
                        row.propertyrankitems.propertyrankingitems != "" &&
                        row.propertyrankitems.propertyrankingitems !=
                          undefined && (
                          <td className="priorityListPopUp">
                            {row.propertyrankitems.propertyrankingitems.map(
                              (Pitem) => {
                                return (
                                  <p
                                    key={
                                      "popu" + index + Pitem.prioritylistitem
                                    }
                                  >
                                    {Pitem.ismatched == true &&
                                      Pitem.isallmatched == null && (
                                        <i className="fa fa-check"></i>
                                      )}
                                  </p>
                                );
                              }
                            )}
                          </td>
                        )
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Commute</th>
                    {state.finalObjectCampare.map((row, index) => {
                      return (
                        row.propertyrankitems.propertyrankingitems != null &&
                        row.propertyrankitems.propertyrankingitems != "" &&
                        row.propertyrankitems.propertyrankingitems !=
                          undefined && (
                          <td className="priorityListPopUp">
                            {row.propertyrankitems.propertyrankingitems.map(
                              (Pitem) => {
                                return (
                                  <p
                                    key={
                                      "popu" +
                                      index +
                                      1 +
                                      Pitem.prioritylistitem
                                    }
                                  >
                                    {Pitem.ismatched == false &&
                                      Pitem.index == 4 && (
                                        <i className="fa fa-times"></i>
                                      )}
                                  </p>
                                );
                              }
                            )}
                          </td>
                        )
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Must Haves</th>

                    {state.finalObjectCampare.map((row, index) => {
                      console.log(
                        row.propertyrankitems.propertyrankingitems,
                        "propr12"
                      );
                      return (
                        row.propertyrankitems.propertyrankingitems != null &&
                        row.propertyrankitems.propertyrankingitems != "" &&
                        row.propertyrankitems.propertyrankingitems !=
                          undefined && (
                          <td className="priorityListPopUp">
                            {row.propertyrankitems.propertyrankingitems.map(
                              (Pitem) => {
                                return (
                                  <p
                                    key={
                                      "popu" +
                                      index +
                                      1 +
                                      Pitem.prioritylistitem
                                    }
                                  >
                                    {Pitem.prioritylistitem == "Must Haves" ? (
                                      <Popover
                                        placement="leftTop"
                                        content={
                                          <div>
                                            {Pitem.matcheditems.map(
                                              (matcheditem) => {
                                                return (
                                                  matcheditem.itemname !=
                                                    "" && (
                                                    <p
                                                      key={
                                                        "mmypp" +
                                                        index +
                                                        matcheditem.itemname
                                                      }
                                                    >
                                                      {matcheditem.itemname}
                                                      {matcheditem.ismatched ==
                                                        true && (
                                                        <i className="fa fa-check"></i>
                                                      )}
                                                      {matcheditem.ismatched ==
                                                        false && (
                                                        <i className="fa fa-times"></i>
                                                      )}
                                                    </p>
                                                  )
                                                );
                                              }
                                            )}
                                          </div>
                                        }
                                        title="Matched Must Haves"
                                        trigger="hover"
                                      >
                                        {Pitem.ismatched == true &&
                                        Pitem.isallmatched == false ? (
                                          <i className="fa fa-info-circle yellow_clr"></i>
                                        ) : (
                                          <i className="fa fa-info-circle text-danger"></i>
                                        )}
                                      </Popover>
                                    ) : (
                                      ""
                                    )}
                                    {/* {Pitem.ismatched == false &&
                                      Pitem.index == 2 && (
                                        <i className="fa fa-times"></i>
                                      )}
                                    {Pitem.ismatched == true &&
                                      Pitem.isallmatched == false && (
                                        <i className="fa fa-check yellow_clr"></i>
                                      )} */}
                                  </p>
                                );
                              }
                            )}
                          </td>
                        )
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Bedrooms</th>
                    {state.finalObjectCampare.map((row, index) => {
                      return (
                        row.propertyrankitems.propertyrankingitems != null &&
                        row.propertyrankitems.propertyrankingitems != "" &&
                        row.propertyrankitems.propertyrankingitems !=
                          undefined && (
                          <td className="priorityListPopUp">
                            {row.propertyrankitems.propertyrankingitems.map(
                              (Pitem) => {
                                return (
                                  <p
                                    key={
                                      "popu" + index + Pitem.prioritylistitem
                                    }
                                  >
                                    {Pitem.ismatched == false &&
                                      Pitem.index == 1 && (
                                        <i className="fa fa-times"></i>
                                      )}
                                  </p>
                                );
                              }
                            )}
                          </td>
                        )
                      );
                    })}
                  </tr>
                </table>
              </div>
            ) : (
              <Spin />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compareModal;
