import React, { useState } from "react";
import moment from "moment";

function MapSchools() {
  const [state, setState] = useState({
    data: {
      latitude: 37.7739852,
      longitude: -122.3912407,
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    items: "",
    latitude: 37.7739852,
    longitude: -122.3912407,
    schoolsList: [],
    greatSchoolsnew: [],
    greatSchoolsDistrict: [],
    selectedSchools: "public",
    Date: moment(new Date()).format("YYYYMMDD"),
    schoolsCategories: ["public", "private", "charter"],
    selectedType: "",
  });
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="bb-white  b-radius-8">
          <div className="shresultsmap">
            <div id="map" className="b-radius-8 relomap-height"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapSchools;
