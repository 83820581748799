import React, { useState } from "react";
import { Select } from "antd";

function DriverLicensePopUp(props) {
  //   const { onChangeTypes, selectedState, statelist1 } = props;
  const [selectedState, setSelectedState] = useState("CA");
  const statelist1 = [];
  var stateList = ["CA", "OR", "WA"];
  let statename = ["California (CA)", "Oregon (OR)", "Washington (WA)"];
  for (let i = 0; i < stateList.length; i++) {
    statelist1.push(
      <Select.Option key={stateList[i]}>{statename[i]}</Select.Option>
    );
  }
  const onChangeTypes = async (value) => {
    setSelectedState(value);
  };
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalDriversLicense").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>

        <div className="modal-body">
          <div className="widthModal">
            <div className="form-group selectState">
              <label className="label_cls">Select state :</label>
              <Select
                className="autoComplete"
                id="selectedState"
                style={{ width: "25%" }}
                value={selectedState}
                onChange={onChangeTypes}
              >
                {statelist1}
              </Select>
            </div>
          </div>
          {selectedState == "CA" ? (
            <div className="widthModal">
              <div className="item-view">
                <h2>Obtaining Your Driver’s License</h2>
                <p>
                  Below are links and information to get a driver’s license in
                  your new state. As soon as you become a California resident,
                  you have 10 days to become a registered driver. Check{" "}
                  <a
                    target="_blank"
                    href="https://www.dmv.ca.gov/portal/file/residency-documents-list-pdf/"
                  >
                    here
                  </a>{" "}
                  to see the list of acceptable documents you can use to prove
                  your California residency.
                </p>
              </div>

              <div className="tails-row">
                <div className="tails">
                  <div className="item-view">
                    <h2>If you’ve never had a Driver’s License</h2>
                    <p>
                      If you are over 18 years of age and you have never had a
                      DL in any state or country, you may obtain an instruction
                      permit until you are ready to take your driving test. 
                    </p>
                    <p>
                      An instruction permit would be issued to a person over age
                      18. Once you complete the requirements of the instruction
                      permit, you can take the driving test to get a California
                      DL.{" "}
                    </p>
                  </div>
                  <div className="item-view">
                    <h2>International Transferees </h2>
                    <p>
                      If you are from outside of the U.S. and moving to the U.S.
                      and have a license issued by another country, follow the
                      process noted for Domestic Transferees. You will also have
                      to take a Behind the Wheel driving test after passing the
                      Knowledge Test.
                    </p>
                    <ol>
                      <li>
                        To help reduce wait times and make sure you secure an
                        appointment, schedule your driving test ahead of time.
                        Schedule your driving test{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/make-an-appointment/"
                        >
                          here
                        </a>
                        . You must schedule a test, as they are not given
                        without an appointment.
                      </li>
                      <li>
                        The vehicle you bring for your driving test must meet
                        the requirements outlined{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/driver-education-and-safety/educational-materials/fast-facts/preparing-for-your-driving-test-ffdl-22/"
                        >
                          here
                        </a>
                        .
                      </li>
                    </ol>
                    <p>
                      Similar to the Knowledge Test, you will have three
                      opportunities to pass the Behind the Wheel Test. If you
                      fail all three attempts, you will have to reapply. 
                    </p>
                    <h2>Don’t Forget</h2>
                    <p>
                      While rental cars may be used for the driving test, you
                      must show that the vehicle is properly insured before the
                      test begins. Find out more about what documents are
                      accepted as proof of insurance{" "}
                      <a
                        target="_blank"
                        href="https://www.dmv.ca.gov/portal/driver-education-and-safety/educational-materials/fast-facts/preparing-for-your-driving-test-ffdl-22/"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="tails">
                  <div className="item-view">
                    <h2>Domestic Transferees </h2>
                    <p>
                      If you are from the U.S. and moving within the U.S and
                      have a license issued by another state, here is how to
                      apply for a California Driver's License.
                    </p>

                    <ol>
                      <li>
                        Fill out an application, found{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/driver-licenses-identification-cards/dl-id-online-app-edl-44/"
                        >
                          here
                        </a>
                        .{" "}
                      </li>
                      <li>
                        It's highly suggested to schedule an appointment for
                        your Knowledge Test. This will help you avoid lines and
                        wait times. Find a DMV location and schedule your
                        appointment{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/locations/"
                        >
                          here
                        </a>
                        .{" "}
                      </li>
                      <li>
                        Study the CA Driver's Handbook to prepare for the test.{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/file/california-driver-handbook-pdf/"
                        >
                          Here is a copy to the handbook
                        </a>
                        .{" "}
                      </li>
                      <li>
                        Take some{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/driver-education-and-safety/educational-materials/sample-driver-license-dl-knowledge-tests/"
                        >
                          practice tests
                        </a>{" "}
                        beforehand.{" "}
                      </li>
                    </ol>
                    <p>
                      You will pay a fee when taking your Knowledge Test, and
                      you will have three opportunities to pass the test. If you
                      fail all three attempts, you will have to reapply.
                    </p>
                    <h2>Don't Forget</h2>

                    <p>
                      Once you pass, don't forget to register your vehicle. More
                      information about registering your vehicle can be found{" "}
                      <a
                        target="_blank"
                        href="https://www.dmv.ca.gov/portal/vehicle-registration/new-registration/"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedState == "OR" ? (
            //  -- OR--
            <div className="widthModal">
              <div className="item-view">
                <h2>Obtaining Your Driver’s License</h2>
                <p>
                  Below are links and information to get a driver’s license in
                  your new state. As soon as you become an Oregon resident, you
                  have 30 days to become a registered driver. Check{" "}
                  <a
                    target="_blank"
                    href="https://www.oregon.gov/odot/dmv/pages/driverid/residency.aspx"
                  >
                    here
                  </a>{" "}
                  to see if you qualify as a resident.
                </p>
              </div>

              <div className="tails-row">
                <div className="tails">
                  <div className="item-view">
                    <h2>If you’ve never had a Driver’s License</h2>
                    <p>
                      If you are over 18 years of age and you have never had a
                      DL in any state or country, you will have to pass both the
                      Knowledge and Driving Tests. How to apply and take these
                      tests are noted in the Domestic and International
                      Transferees areas.
                    </p>
                    {/* <p>An instruction permit would be issued to a person over age 18. Once you complete the requirements of the instruction permit, you can take the driving test to get a California DL. </p> */}
                  </div>
                  <div className="item-view">
                    <h2>International Transferees</h2>
                    <p>
                      If you are from outside of the U.S. and moving to the U.S.
                      and have a license issued by another country, follow the
                      process noted for Domestic Transferees. You will also have
                      to take a Drive Test after passing the Knowledge Test.{" "}
                    </p>
                    <ol>
                      <li>
                        To help reduce wait times and make sure you secure an
                        appointment, schedule your driving test ahead of time.
                        Schedule your driving test{" "}
                        <a
                          target="_blank"
                          href="https://dmv2u.oregon.gov/eServices/_/"
                        >
                          here
                        </a>
                        . You must schedule a test, as they are not given
                        without an appointment.
                      </li>
                      <li>
                        The vehicle you bring for your driving test must meet
                        the requirements outlined{" "}
                        <a
                          target="_blank"
                          href="https://www.oregon.gov/odot/DMV/pages/driverid/licenseget.aspx#Drive"
                        >
                          here
                        </a>
                        .
                      </li>
                    </ol>
                    <p>
                      Similar to the Knowledge Test, you will have three
                      opportunities to pass the Drive Test. If you fail all
                      three attempts, you will have to reapply. 
                    </p>
                    <h2>Don’t Forget</h2>
                    <p>
                      While rental cars may be used for the driving test, you
                      must show that the vehicle is properly insured before the
                      test begins. Find out more about what documents are
                      accepted as proof of insurance{" "}
                      <a
                        target="_blank"
                        href="https://www.oregon.gov/odot/DMV/pages/driverid/licenseget.aspx#Drive"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="tails">
                  <div className="item-view">
                    <h2>Domestic Transferees </h2>
                    <p>
                      If you are from the U.S. and moving within the U.S and
                      have a license issued by another state, here is how to
                      apply for an Oregon Driver's License. You will have to
                      apply and pass the Knowledge Test.
                    </p>

                    <ol>
                      <li>
                        Fill out an application, found{" "}
                        <a
                          target="_blank"
                          href="https://dmv2u.oregon.gov/eServices/_/"
                        >
                          here
                        </a>
                        .{" "}
                      </li>
                      <li>
                        It's highly suggested to schedule an appointment for
                        your Knowledge Test. This will help you avoid lines and
                        wait times. Find a DMV location and schedule your
                        appointment{" "}
                        <a
                          target="_blank"
                          href="https://dmv2u.oregon.gov/eServices/_/"
                        >
                          here
                        </a>
                        .
                      </li>
                      <li>
                        Study the OR Driver's Manual to prepare for the test.{" "}
                        <a
                          target="_blank"
                          href="https://www.oregon.gov/odot/Forms/DMV/37.pdf"
                        >
                          Here is a copy to the handbook
                        </a>{" "}
                      </li>
                      <li>
                        Take some{" "}
                        <a
                          target="_blank"
                          href="https://www.oregon.gov/odot/DMV/Pages/practice_test.aspx"
                        >
                          practice tests
                        </a>{" "}
                        beforehand.{" "}
                      </li>
                    </ol>
                    <p>
                      You will pay a fee when taking your Knowledge Test, and
                      you will have three opportunities to pass the test. If you
                      fail all three attempts, you will have to reapply.
                    </p>
                    <h2>Don't Forget</h2>

                    <p>
                      Once you pass, don't forget to register your vehicle. More
                      information about registering your vehicle can be found{" "}
                      <a
                        target="_blank"
                        href="https://www.oregon.gov/odot/DMV/Pages/Vehicle/index.aspx"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedState == "WA" ? (
            // ---Washington --
            <div className="widthModal">
              <div className="item-view">
                <h2>Obtaining Your Driver’s License</h2>
                <p>
                  Below are links and information to get a driver’s license in
                  your new state. As soon as you become a Washington resident,
                  you have 30 days to become a registered driver. Check{" "}
                  <a
                    target="_blank"
                    href="https://dor.wa.gov/contact-us/washington-state-residency-definition"
                  >
                    here
                  </a>{" "}
                  to see if you qualify as a WA resident.
                </p>
              </div>

              <div className="tails-row">
                <div className="tails">
                  <div className="item-view">
                    <h2>If you’ve never had a Driver’s License</h2>
                    <p>
                      If you are over 18 years of age and you have never had a
                      DL in any state or country, you will have to pass the
                      Knowledge and Driving Tests. How to apply and take these
                      tests are noted under International Transferees.{" "}
                    </p>
                  </div>
                  <div className="item-view">
                    <h2>International Transferees</h2>
                    <p>
                      If you are from outside of the U.S. and moving to the U.S.
                      and have a license issued by another country, here are the
                      steps for getting a Washington driver’s license. You will
                      need to pass a Knowledge and Drive Test.{" "}
                    </p>
                    <ol>
                      <li>
                        Fill out an application, found{" "}
                        <a
                          target="_blank"
                          href="https://www.dol.wa.gov/driverslicense/preapply.html"
                        >
                          here
                        </a>
                        .
                      </li>
                      <li>
                        To help reduce wait times and make sure you secure an
                        appointment, schedule your driving test ahead of time.
                        Schedule your driving test{" "}
                        <a
                          target="_blank"
                          href="https://www.dol.wa.gov/appointments/index.html"
                        >
                          here
                        </a>
                        . You must schedule a test, as they are not given
                        without an appointment.
                      </li>
                      <li>
                        Study the WA Driver's Manual to prepare for the test.{" "}
                        <a
                          target="_blank"
                          href="https://www.dol.wa.gov/driverslicense/guide.html"
                        >
                          Here is a copy to the handbook.
                        </a>
                      </li>
                      <li>
                        Take some{" "}
                        <a
                          target="_blank"
                          href="https://www.dol.wa.gov/driverslicense/practicetest.html"
                        >
                          practice tests
                        </a>{" "}
                        beforehand.
                      </li>
                    </ol>
                    <p>
                      You will pay a fee when taking your Knowledge Test, and
                      you will have three opportunities to pass the test. If you
                      fail all three attempts, you will have to reapply.
                    </p>
                    <ol start="5">
                      {/* <li>
                                                Schedule your driving test <a target="_blank" href="https://www.dol.wa.gov/appointments/index.html">here</a>. You must schedule a test, as they are not given without an appointment.
                                                </li> */}
                      <li>
                        The vehicle you bring for your driving test must meet
                        the requirements outlined{" "}
                        <a
                          target="_blank"
                          href="https://www.dol.wa.gov/driverslicense/writtentest.html"
                        >
                          here
                        </a>
                        .
                      </li>
                    </ol>
                    <p>
                      Similar to the Knowledge Test, you will have three
                      opportunities to pass the Drive Test. If you fail all
                      three attempts, you will have to reapply. 
                    </p>
                    <h2>Don’t Forget</h2>
                    <p>
                      While rental cars may be used for the driving test, you
                      must show that the vehicle is properly insured before the
                      test begins. Find out more about what documents are
                      accepted as proof of insurance{" "}
                      <a
                        target="_blank"
                        href="https://www.dol.wa.gov/driverslicense/writtentest.html"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="tails">
                  <div className="item-view">
                    <h2>Domestic Transferees </h2>
                    <p>
                      If you are from the U.S. and moving within the U.S and
                      have a license issued by another state, you might qualify
                      for a Washington driver’s license.
                    </p>
                    <p>
                      If you have a valid license from another U.S. state, the
                      District of Columbia, U.S. Territory, U.S. Department of
                      State, British Columbia, Germany, South Korea, Taiwan, and
                      Japan, you DO NOT need to pass any tests to get your
                      license.
                    </p>
                    <p>
                      Simply, apply online for your license. You can apply{" "}
                      <a
                        target="_blank"
                        href="https://www.dol.wa.gov/driverslicense/preapply.html"
                      >
                        here
                      </a>
                      .
                    </p>
                    <h2>Don't Forget</h2>

                    <p>
                      Don’t forget to register your vehicle. More information
                      about registering your vehicle can be found{" "}
                      <a
                        target="_blank"
                        href="https://www.dol.wa.gov/vehicleregistration/registervehicle.html"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedState == "VA" ? (
            // ---VA--
            <div className="widthModal">
              <div className="item-view">
                <h1>Obtaining Your Driver’s License</h1>
                <p>
                  Below are links and information to get a driver’s license in
                  your new state. As soon as you become a Virginia resident, you
                  have 60 days to become a registered driver. Check{" "}
                  <a
                    target="_blank"
                    href="https://www.dmv.virginia.gov/webdoc/pdf/dmv141.pdf"
                  >
                    here
                  </a>{" "}
                  to see what documents you will need to provide as a VA
                  resident.
                </p>
              </div>

              <div className="tails-row">
                <div className="tails">
                  <div className="item-view">
                    <h2>If you’ve never had a Driver’s License</h2>
                    <p>
                      If you’ve never had a driver’s license issued by another
                      state or country, you will have to pass both the Knowledge
                      and Driving Tests. The steps to take the Knowledge and
                      Driving Test are outlined under International Transferees.{" "}
                    </p>
                  </div>
                  <div className="item-view">
                    <h2>International Transferees</h2>
                    <p>
                      If you are from outside of the U.S. and moving to the U.S.
                      and have a license issued by another country, you will
                      have to pass both the Knowledge and Driving Tests.
                    </p>
                    <ol>
                      <li>
                        Fill out an application, which can be found{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/webdoc/pdf/dl1p.pdf"
                        >
                          here
                        </a>{" "}
                        .
                      </li>
                      <li>
                        It is highly suggested to schedule an appointment for
                        your Knowledge Test. This will help you avoid lines and
                        wait times. Find a DMV location and schedule your
                        appointment{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/general/#appointments.asp"
                        >
                          here
                        </a>
                        .
                      </li>
                      <li>
                        Review and study the Virginia Driver’s Manual{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/drivers/manual.html"
                        >
                          here
                        </a>
                        .
                      </li>
                      <li>
                        Take some{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/general/#ske_intro.asp"
                        >
                          practice tests
                        </a>{" "}
                        beforehand.
                      </li>
                      <li>
                        After passing the knowledge test, you will{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/general/#appointments.asp"
                        >
                          schedule your driving test
                        </a>
                        . More information on the road skills test can be found{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/general/#skillstest.asp"
                        >
                          here
                        </a>
                        .{" "}
                      </li>
                    </ol>
                    <p>
                      There are fees when taking the Driving Test. Fees and
                      charges can be found{" "}
                      <a
                        target="_blank"
                        href="https://www.dmv.virginia.gov/webdoc/pdf/dmv201.pdf"
                      >
                        here
                      </a>
                      . Once you’ve passed your driving test, your license will
                      be sent to you in the mail.
                    </p>
                    <h2>Don’t Forget</h2>
                    <p>
                      You will have to provide documentation that you are a
                      Virginia resident. Acceptable documents and proof of
                      residency are provided{" "}
                      <a
                        target="_blank"
                        href="https://www.dmv.virginia.gov/webdoc/pdf/dmv141.pdf"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="tails">
                  <div className="item-view">
                    <h2>Domestic Transferees </h2>
                    <p>
                      If you are from the U.S. and moving within the U.S and
                      have a license issued by another state, here is how to
                      apply for a Virginia Driver’s License.
                    </p>
                    <ol>
                      <li>
                        You will exchange your out-of-state license while
                        proving your VA residency. Acceptable documents to prove
                        residency can be found{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/webdoc/pdf/dmv141.pdf"
                        >
                          here
                        </a>
                        .
                      </li>
                      <li>
                        Fill out an application{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/webdoc/pdf/dl1p.pdf"
                        >
                          here
                        </a>{" "}
                        and find a DMV near you{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/general/#appointments.asp"
                        >
                          here
                        </a>
                        .
                      </li>
                    </ol>
                    <h2>Don't Forget</h2>

                    <p>
                      Don’t forget to register your vehicle. More information
                      about registering your vehicle can be found{" "}
                      <a
                        target="_blank"
                        href="https://www.dmv.virginia.gov/vehicles/#vehiclereg.asp"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* <div className="modal-footer"></div> */}
      </div>
    </div>
  );
}

export default DriverLicensePopUp;
