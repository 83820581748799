import React, { useEffect, useState } from "react";
import AppController from "../../../controllers/appController";
import MapSchools from "./mapSchools";
import axios from "axios";

function SchoolsSearch(props) {
  const [state, setState] = useState({
    propertysList: [],
    mapRes: [],
    mapIndex: 0,
    propertysLoading: false,
    address: "",
    addressLat: 0.0,
    addressLng: 0.0,
    index: 0,
    map: {},
    completeAddress: {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
    },
    searchLoader: false,
    modalShow: false,
    modalpopupInfo: {},
    activeNumber: -1,
    zillowdata: false,
    sharedLiving: true,
    activeProperties: [],
    GetTransfereeProperties: [],
    isSharedliving: "",
    bungalowMarkets: [],
    slug: "",
    isLoad: false,
    profileAddress: "",
    tooltipText: "",
    searchedData: [],
    propertyloader: false,
    pageLoader: true,
    data: {
      latitude: 37.7739852,
      longitude: -122.3912407,
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    schoolsList: [],
    greatSchools: [],
    types: [
      {
        name: "Restaurants",
        type: "restaurant",
        faicon: "fa fa-cutlery",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/restarent.png",
      },
      {
        name: "Bars",
        type: "bar",
        faicon: "fa fa-glass",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/bars.png",
      },
      {
        name: "Groceries",
        type: "supermarket",
        faicon: "fa fa-shopping-bag",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/groceries.png",
      },
      {
        name: "Parks",
        type: "park",
        faicon: "fa fa-tree",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/parks.png",
      },
      {
        name: "Shopping",
        type: "shopping_mall",
        faicon: "fa fa-shopping-cart",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/shopping.png",
      },
      {
        name: "Entertainment",
        type: "night_club",
        faicon: "fa fa-film",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/entertainment.png",
      },
    ],
    type: ["restaurant"],
    typeName: "",
    location: [],
    placeSearched: [],
    placeSearchedMarkers: [],
    markerList: [],
    showlist: false,
    showSearch: false,
    typeicon: 0,
    directionsService: {},
    directionsDisplay: {},
    zipId: "",
    commuteData: {
      latitude: "",
      longitude: "",
      state_name: "",
      city_name: "",
      address: "",
    },
    Loader: false,
    isProperty: false,
    worklatitude: "",
    worklongitude: "",
    workAddress: "",
  });

  // useEffect(() => {
  //   console.log(props.data, "data school");
  //   setState((prev) => ({ ...prev, data: props.data }));
  //   loadSchoolMarkers("public");
  // }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        console.log(props.data, "data school");
        setState((prev) => ({ ...prev, data: props.data }));
        let workAddress = JSON.parse(await localStorage.getItem("workAddress"));
        workAddress = workAddress.preferencesanswer;
        console.log(workAddress);
        await setState((prev) => ({
          ...prev,
          workAddress: workAddress,
        }));
        let address = {
          city: workAddress,
        };
        axios
          .get("/work-lat-log", { params: address })
          .then((response) => {
            let data = response.data;
            console.log(data, "image");
            setState((prev) => ({
              ...prev,
              worklatitude: data.latitude,
              worklongitude: data.longitude,
            }));
            loadSchoolMarkers("public", data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const loadSchoolMarkers = async (type, data) => {
    await setState((prev) => ({
      ...prev,
      schoolsList: [],
      mapRes: [],
      Loader: true,
    }));

    let greatSchoolsdata = await AppController.greatSchools(state.data);
    let greatSchools = greatSchoolsdata.schools;
    greatSchools.forEach((item) => {
      item.overviewurl = item["overview-url"];
      item.schoolsummary = item["school-summary"];
      delete item["school-summary"];
      delete item["overview-url"];
    });
    console.log(greatSchools, "greatSchools");
    await setState((prev) => ({ ...prev, greatSchools: greatSchools }));
    let publicScools =
      state.greatSchools.length > 0
        ? _.filter(state.greatSchools, function (o) {
            return o.type == type;
          })
        : [];

    await setState((prev) => ({
      ...prev,
      mapRes: _.concat(state.mapRes, {
        address: state.data.address,
        addressCity: "",
        city: state.data.city,
        latitude: state.data.latitude,
        longitude: state.data.longitude,
        name: state.data.city,
        images: "",
        zoom: 10 + i,
        iconShowCustom: false,
        isWork: true,
        isSchool: false,
        isProperty: false,
        communityObject: {
          latitude: state.data.latitude,
          longitude: state.data.longitude,
        },
      }),
    }));
    const updatedMapRes1 = _.concat(state.mapRes, {
      address: state.data.address,
      addressCity: "",
      city: state.data.city,
      latitude: state.data.latitude,
      longitude: state.data.longitude,
      name: state.data.city,
      images: "",
      zoom: 10 + i,
      iconShowCustom: false,
      isWork: true,
      isSchool: false,
      isProperty: false,
      communityObject: {
        latitude: state.data.latitude,
        longitude: state.data.longitude,
      },
    });
    const updatedMapRes2 = _.concat(state.mapRes, {
      address: state.workAddress,
      addressCity: "",
      city: state.workAddress,
      latitude: data.worklatitude,
      longitude: data.worklongitude,
      name: state.workAddress,
      images: "",
      zoom: 10 + i,
      iconShowCustom: false,
      isWork: true,
      isSchool: false,
      isProperty: true,
      communityObject: {
        latitude: data.worklatitude,
        longitude: data.worklongitude,
      },
    });
    const combinedMapRes = _.concat(updatedMapRes1, updatedMapRes2);
    console.log(combinedMapRes, "combinedMapRes");
    mapView(combinedMapRes);
    await setState((prev) => ({
      ...prev,
      mapRes: _.concat(state.mapRes, {
        address: state.workAddress,
        addressCity: "",
        city: state.workAddress,
        latitude: state.worklatitude,
        longitude: state.worklongitude,
        name: state.workAddress,
        images: "",
        zoom: 10 + i,
        iconShowCustom: false,
        isWork: true,
        isSchool: false,
        isProperty: true,
        communityObject: {
          latitude: state.worklatitude,
          longitude: state.worklongitude,
        },
      }),
    }));
    mapView(state.mapRes);
    await setState((prev) => ({ ...prev, schoolsList: publicScools }));
    console.log(publicScools, "publicScoolsschoolsList", state.schoolsList);
    for (var i = 0; i < state.schoolsList.length; i++) {
      await setState((prev) => ({
        ...prev,
        mapRes: _.concat(state.mapRes, {
          address:
            state.schoolsList[i].street +
            " , " +
            state.schoolsList[i].city +
            " , " +
            state.schoolsList[i].state +
            " " +
            state.schoolsList[i].zip,
          addressCity: state.schoolsList[i].city,
          latitude: state.schoolsList[i].lat,
          longitude: state.schoolsList[i].lon,
          schoolName: state.schoolsList[i].name,
          overviewLink: state.schoolsList[i].overviewurl,

          name: "",
          images: "",
          zoom: 10 + i,
          iconShowCustom: false,
          isWork: false,
          isSchool: true,
          isProperty: state.isProperty,
          communityObject: {
            latitude: state.addressLat,
            longitude: state.addressLng,
          },
        }),
      }));
      mapView(state.mapRes);
    }
    await setState((prev) => ({ ...prev, Loader: false }));
  };

  const mapView = async (
    data,
    searchRes = [],
    centerPoint = {},
    destinationAdd = ""
  ) => {
    console.log(data[0], "data map view");
    // GoogleApiWrapper.initMap(data, true, 0, searchRes, centerPoint, destinationAdd);
    let locations = data
      .filter((row) => row.latitude !== null)
      .map((row, index) => {
        var address;
        if (row.latitude != 0 && row.address != undefined) {
          let imgs =
            row.APiType == "Zillow" ? row.images.split(",") : row.images;
          address =
            '<div id="content" class="map-thumbs">' +
              '<div id="siteNotice">' +
              "</div>" +
              '<div id="bodyContent" style="width: auto">' +
              "<h1>" +
              row.address.city +
              "</h1>" +
              "<img src=" +
              imgs[0] +
              ">" +
              "<p>" +
              row.address.street ==
            undefined
              ? row.address.street
              : "" + "</p>" + "</div>" + "</div>";
        } else {
          address = "";
        }

        return [address, row.latitude, row.longitude, index, row.address, row];
      });
    const _this = this;
    const markers = [];
    var marker;
    var map = (map = new google.maps.Map(document.getElementById("map"), {
      zoom: 14,
      center: new google.maps.LatLng(
        Number(props.data.latitude),
        Number(props.data.longitude)
      ),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    }));
    for (var i = 0; i < locations.length; i++) {
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          Number(locations[i][1]),
          Number(locations[i][2])
        ),
        animation: google.maps.Animation.DROP,
        icon:
          data[i].iconShowCustom == true
            ? data[i].isProperty == true
              ? "../assets/images/propertyMarker.svg"
              : "../assets/images/maps-301.svg"
            : data[i].isWork == true
            ? data[i].isProperty == true
              ? "../assets/images/home-icon.svg"
              : location.pathname == "/city-landing"
              ? "../assets/images/propertyMarker1.svg"
              : "../assets/images/propertyMarker.svg"
            : "../assets/images/school-map.svg",
        map: map,
      });
      var infowindow = new google.maps.InfoWindow();
      google.maps.event.addListener(
        marker,
        "click",
        (function (marker, i) {
          return function () {
            let contentString = "";
            if (
              locations[i][5].isSchool == true &&
              locations[i][5].isWork == false
            ) {
              setState((prev) => ({
                ...prev,
                activeNumber: locations[i][5].schoolName,
              }));
              contentString =
                '<div id="content"><h6><b>' +
                locations[i][5].schoolName +
                '</b></h6> <div><div class=""><div></div><p>' +
                locations[i][4] +
                "</p> <a href=" +
                locations[i][5].overviewLink +
                ' target="_blank">More</a> | <a href="' +
                "https://www.google.com/maps/place/" +
                locations[i][4] +
                '" target="_blank">Get Directions</a></div>' +
                "</div>";
            } else {
              contentString =
                locations[i][4].street != undefined
                  ? locations[i][4].street
                  : locations[i][4];
            }
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
          };
        })(marker, i)
      );
      markers.push(marker);
    }
    await setState((prev) => ({ ...prev, markerList: markers }));
    await setState((prev) => ({ ...prev, map }));
  };

  return (
    <div>
      <MapSchools {...state} />
    </div>
  );
}

export default SchoolsSearch;
