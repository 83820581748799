import React, { useEffect, useState } from "react";
import axios from "axios";
import AppController from "../../../controllers/appController";
import OwlCarousel from "react-owl-carousel";
import schoolData from "../cityLanding/schoolData.json";
import _ from "lodash";

function SchoolsSearch(props) {
  const [state, setState] = useState({
    startPosition: "0",
    allScools: "",
    greatSchools: "",
    privateScools: "",
    publicScools: "",
    charterScools: "",
    PageLoader: false,
  });
  const [activeTab, setActiveTab] = useState("public");
  const SchoolData = schoolData;
  console.log(SchoolData, "SchoolData");

  useEffect(() => {
    async function fetchData() {
      try {
        console.log(props.data, "data school");
        setState((prev) => ({ ...prev, data: props.data }));
        let workAddress = JSON.parse(await localStorage.getItem("workAddress"));
        workAddress = workAddress.preferencesanswer;
        console.log(workAddress);
        await setState((prev) => ({
          ...prev,
          workAddress: workAddress,
        }));
        let address = {
          city: workAddress,
        };
        axios
          .get("/work-lat-log", { params: address })
          .then((response) => {
            let data = response.data;
            console.log(data, "image");
            setState((prev) => ({
              ...prev,
              worklatitude: data.latitude,
              worklongitude: data.longitude,
            }));
            getSchoolData(data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const getSchoolData = async (data) => {
    console.log(data, "data");
    // const greatSchools = await AppController.greatSchools2(data);
    // console.log(greatSchools, "greatSchools");
    // const privateScools = _.filter(greatSchools.schools, function (o) {
    //   o.schoolSummary = o["school-summary"];
    //   o.overviewUrl = o["overview-url"];
    //   return o.type == "private";
    // });
    // const publicScools = _.filter(greatSchools.schools, function (o) {
    //   o.schoolSummary = o["school-summary"];
    //   o.overviewUrl = o["overview-url"];
    //   return o.type == "public";
    // });
    // const charterScools = _.filter(greatSchools.schools, function (o) {
    //   o.schoolSummary = o["school-summary"];
    //   o.overviewUrl = o["overview-url"];
    //   return o.type == "charter";
    // });
    const privateScools = SchoolData.schools.filter(
      (o) => o.type === "private"
    );
    const publicScools = SchoolData.schools.filter((o) => o.type === "public");
    const charterScools = SchoolData.schools.filter(
      (o) => o.type === "charter"
    );
    setState((prev) => ({
      ...prev,
      allScools: publicScools,
      greatSchools: SchoolData,
      privateScools: privateScools,
      publicScools: publicScools,
      charterScools: charterScools,
    }));
  };

  const handleClick = (type) => {
    console.log(type, "type");
    setActiveTab(type);
    setState((prev) => ({ ...prev, PageLoader: true }));

    let data = [];

    if (type === "private") {
      data = state.privateScools;
    } else if (type === "public") {
      data = state.publicScools;
    } else if (type === "charter") {
      data = state.charterScools;
    }

    setState((prev) => ({ ...prev, allScools: data, PageLoader: false }));
  };

  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };
  return (
    <div>
      <section className="">
        <div className="">
          <div className="container">
            <div className="">
              {/* tabs */}
              <div className="tabs-view schollView">
                <ul className="nav nav-tabs">
                  <li>
                    <a
                      className="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      onClick={() => handleClick("public")}
                    >
                      Public
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      onClick={() => handleClick("private")}
                    >
                      Private
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      id="nav-contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-contact"
                      type="button"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                      onClick={() => handleClick("charter")}
                    >
                      Charter
                    </a>
                  </li>
                  <li className="schoollogo">
                    <a
                      rel="nofollow"
                      href="https://www.greatschools.org"
                      target="_blank"
                    >
                      <img
                        width={"120px"}
                        src="/assets/images/greatSchools/logomain.png"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <section className=" bg-white pt-5 position-relative">
                    {state.PageLoader == false ? (
                      state.allScools.length > 0 ? (
                        <div className="owl-slider pb-5 SAweb_sliderBottomArrows billingSlider">
                          <div id="carouselSchool">
                            <OwlCarousel
                              className="owl-theme"
                              nav
                              dots={false}
                              items={4}
                              startPosition={state.startPosition}
                              onDragged={(object) =>
                                updateCarouselPosition(object)
                              }
                              onTranslated={(object) =>
                                updateCarouselPosition(object)
                              }
                            >
                              {state.allScools.map((row, index) => {
                                // console.log(state.allScools, "state.allScools");
                                return (
                                  // individual schools card
                                  <div className="item" key={index}>
                                    <div className="caption">
                                      <p>
                                        <a
                                          target="_blank"
                                          href={row.overviewUrl}
                                          rel="nofollow"
                                        >
                                          {row.name}
                                        </a>
                                      </p>
                                      <p>
                                        {" "}
                                        <span>
                                          {row["school-summary"].substring(
                                            0,
                                            213
                                          )}
                                          {row["school-summary"].length > 216
                                            ? "... "
                                            : ""}
                                          {row["school-summary"].length >
                                          216 ? (
                                            <a
                                              target="_blank"
                                              href={row.overviewUrl}
                                              rel="nofollow"
                                            >
                                              more
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </span>{" "}
                                      </p>
                                      <ul>
                                        {row.rating != null ? (
                                          <li>
                                            <a
                                              target="_blank"
                                              href={row.overviewUrl}
                                              rel="nofollow"
                                            >
                                              <img
                                                className="rating-image"
                                                src={`/assets/images/greatSchools/ratings/${row.rating}-medium.png`}
                                              />
                                            </a>
                                            <span>
                                              GreatSchools Summary Rating
                                            </span>
                                          </li>
                                        ) : (
                                          <li>
                                            <a
                                              target="_blank"
                                              href={row.overviewUrl}
                                              rel="nofollow"
                                            >
                                              <img
                                                className="rating-image"
                                                src={`/assets/images/greatSchools/ratings/na-medium.png`}
                                              />
                                            </a>
                                            <span>
                                              GreatSchools Summary Rating
                                            </span>
                                          </li>
                                        )}
                                        <li>
                                          {" "}
                                          <i className="bi bi-telephone">
                                            {" "}
                                          </i>{" "}
                                          {row.phone}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              })}
                            </OwlCarousel>
                          </div>
                        </div>
                      ) : (
                        <div className="no_data_found">No Data Found!</div>
                      )
                    ) : (
                      <div className="loading"></div>
                    )}
                    <span className="school-copy-right">
                      Data provided by{" "}
                      <a
                        rel="nofollow"
                        href="https://www.greatschools.org"
                        target="_blank"
                      >
                        GreatSchools.org
                      </a>{" "}
                      © 2023. All rights reserved.
                    </span>
                  </section>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <section className=" bg-white pt-5 position-relative">
                    {state.PageLoader == false ? (
                      state.allScools.length > 0 ? (
                        <div className="owl-slider pb-5 SAweb_sliderBottomArrows billingSlider">
                          <div id="carouselSchool">
                            <OwlCarousel
                              className="owl-theme"
                              nav
                              dots={false}
                              items={4}
                              startPosition={state.startPosition}
                              onDragged={(object) =>
                                updateCarouselPosition(object)
                              }
                              onTranslated={(object) =>
                                updateCarouselPosition(object)
                              }
                            >
                              {state.allScools.map((row, index) => {
                                // console.log(state.allScools, "state.allScools");
                                return (
                                  // individual schools card
                                  <div className="item" key={index}>
                                    <div className="caption">
                                      <p>
                                        <a
                                          target="_blank"
                                          href={row.overviewUrl}
                                          rel="nofollow"
                                        >
                                          {row.name}
                                        </a>
                                      </p>
                                      <p>
                                        {" "}
                                        <span>
                                          {row["school-summary"].substring(
                                            0,
                                            213
                                          )}
                                          {row["school-summary"].length > 216
                                            ? "... "
                                            : ""}
                                          {row["school-summary"].length >
                                          216 ? (
                                            <a
                                              target="_blank"
                                              href={row.overviewUrl}
                                              rel="nofollow"
                                            >
                                              more
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </span>{" "}
                                      </p>
                                      <ul>
                                        {row.rating != null ? (
                                          <li>
                                            <a
                                              target="_blank"
                                              href={row.overviewUrl}
                                              rel="nofollow"
                                            >
                                              <img
                                                className="rating-image"
                                                src={`/assets/images/greatSchools/ratings/${row.rating}-medium.png`}
                                              />
                                            </a>
                                            <span>
                                              GreatSchools Summary Rating
                                            </span>
                                          </li>
                                        ) : (
                                          <li>
                                            <a
                                              target="_blank"
                                              href={row.overviewUrl}
                                              rel="nofollow"
                                            >
                                              <img
                                                className="rating-image"
                                                src={`/assets/images/greatSchools/ratings/na-medium.png`}
                                              />
                                            </a>
                                            <span>
                                              GreatSchools Summary Rating
                                            </span>
                                          </li>
                                        )}
                                        <li>
                                          {" "}
                                          <i className="bi bi-telephone">
                                            {" "}
                                          </i>{" "}
                                          {row.phone}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              })}
                            </OwlCarousel>
                          </div>
                        </div>
                      ) : (
                        <div className="no_data_found">No Data Found!</div>
                      )
                    ) : (
                      <div className="loading"></div>
                    )}
                    <span className="school-copy-right">
                      Data provided by{" "}
                      <a
                        rel="nofollow"
                        href="https://www.greatschools.org"
                        target="_blank"
                      >
                        GreatSchools.org
                      </a>{" "}
                      © 2023. All rights reserved.
                    </span>
                  </section>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-contact"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                >
                  {" "}
                  <section className=" bg-white pt-5 position-relative">
                    {state.PageLoader == false ? (
                      state.allScools.length > 0 ? (
                        <div className="owl-slider pb-5 SAweb_sliderBottomArrows billingSlider">
                          <div id="carouselSchool">
                            <OwlCarousel
                              className="owl-theme"
                              nav
                              dots={false}
                              items={4}
                              startPosition={state.startPosition}
                              onDragged={(object) =>
                                updateCarouselPosition(object)
                              }
                              onTranslated={(object) =>
                                updateCarouselPosition(object)
                              }
                            >
                              {state.allScools.map((row, index) => {
                                // console.log(state.allScools, "state.allScools");
                                return (
                                  // individual schools card
                                  <div className="item" key={index}>
                                    <div className="caption">
                                      <p>
                                        <a
                                          target="_blank"
                                          href={row.overviewUrl}
                                          rel="nofollow"
                                        >
                                          {row.name}
                                        </a>
                                      </p>
                                      <p>
                                        {" "}
                                        <span>
                                          {row["school-summary"].substring(
                                            0,
                                            213
                                          )}
                                          {row["school-summary"].length > 216
                                            ? "... "
                                            : ""}
                                          {row["school-summary"].length >
                                          216 ? (
                                            <a
                                              target="_blank"
                                              href={row.overviewUrl}
                                              rel="nofollow"
                                            >
                                              more
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </span>{" "}
                                      </p>
                                      <ul>
                                        {row.rating != null ? (
                                          <li>
                                            <a
                                              target="_blank"
                                              href={row.overviewUrl}
                                              rel="nofollow"
                                            >
                                              <img
                                                className="rating-image"
                                                src={`/assets/images/greatSchools/ratings/${row.rating}-medium.png`}
                                              />
                                            </a>
                                            <span>
                                              GreatSchools Summary Rating
                                            </span>
                                          </li>
                                        ) : (
                                          <li>
                                            <a
                                              target="_blank"
                                              href={row.overviewUrl}
                                              rel="nofollow"
                                            >
                                              <img
                                                className="rating-image"
                                                src={`/assets/images/greatSchools/ratings/na-medium.png`}
                                              />
                                            </a>
                                            <span>
                                              GreatSchools Summary Rating
                                            </span>
                                          </li>
                                        )}
                                        <li>
                                          {" "}
                                          <i className="bi bi-telephone">
                                            {" "}
                                          </i>{" "}
                                          {row.phone}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              })}
                            </OwlCarousel>
                          </div>
                        </div>
                      ) : (
                        <div className="no_data_found">No Data Found!</div>
                      )
                    ) : (
                      <div className="loading"></div>
                    )}
                    <span className="school-copy-right">
                      Data provided by{" "}
                      <a
                        rel="nofollow"
                        href="https://www.greatschools.org"
                        target="_blank"
                      >
                        GreatSchools.org
                      </a>{" "}
                      © 2023. All rights reserved.
                    </span>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SchoolsSearch;
