import React, { useEffect, useState } from "react";
import { Select } from "antd";
import jData from "./dataFoursquare.json";
import OwlCarousel from "react-owl-carousel";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import AppController from "../controllers/appController";

function MapViewFunctional(props) {
  const [state, setState] = useState({
    latitude: 0.0,
    longitude: 0.0,
    selectedCategory: "",
    mapRes: [],
    types: [
      {
        name: "Restaurants",
        type: "restaurant",
        faicon: "fa fa-cutlery",
        icon: "../assets/images/restaurant-new.svg",
      },
      {
        name: "Groceries",
        type: "supermarket",
        faicon: "fa fa-shopping-bag",
        icon: "../assets/images/grocery-1.svg",
      },
      {
        name: "Parks",
        type: "park",
        faicon: "fa fa-tree",
        icon: "../assets/images/park-1.svg",
      },
      {
        name: "Shopping",
        type: "shopping_mall",
        faicon: "fa fa-shopping-cart",
        icon: "../assets/images/shopping-3.svg",
      },
      {
        name: "Entertainment",
        type: "night_club",
        faicon: "fa fa-film",
        icon: "../assets/images/entertainment-1.svg",
      },
      {
        name: "Hospitals",
        type: "hospital",
        faicon: "fa fa-h-square",
        icon: "../assets/images/hos-1.svg",
      },
    ],
    type: ["restaurant"],
    typeName: "Restaurants",
    location: [],
    placeSearched: [],
    placeSearchedMarkers: [],
    markerList: [],
    showlist: false,
    showSearch: false,
    typeicon: 0,
    directionsService: {},
    directionsDisplay: {},
    communityName: "",
    restaurantsArr: [],
    restData: [],
    cityLanding: false,
    Loader: false,
    items: [],
    startPosition: 0,
    categories: [],
    RestaurantsMainArray: [],
    restaurantsList: [],
    typesList: [],
    RestauType: [],
    removeBoundary: false,
    mapText: "",
    activeNumber: -1,
    restaurantsRendered: 0,
    restlist: [],
    restlight: "",
    data: {
      latitude: 37.7739852,
      longitude: -122.3912407,
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    restlistarray: [],
    getlistarray: [],
    GetSquare: [],
    activeMaker: null,
    activeNumber: -1,
    propertyInfo: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      // let items =
      //   parseInt(screen.availWidth) <= 767
      //     ? 1
      //     : parseInt(screen.availWidth) <= 991
      //     ? 1
      //     : 1;

      setState((prev) => ({
        ...prev,
        // items: items,
        latitude: props.latitude,
        longitude: props.longitude,
        showlist: false,
        communityName: props.communityName,
        data: props.data,
      }));
      await onetype(state.types[0], 0);
      tab();
    }
    fetchData();
  }, []);
  const onetype = async (row, type) => {
    // console.log(row, "===", type);
    setState((prev) => ({
      ...prev,
      restaurantsArr: [],
      typesList: [],
      restaurantsList: [],
      Loader: true,
    }));
    if (row.type == "restaurant") {
      setState((prev) => ({
        ...prev,
        // type: [row.type],
        type: row.type,
        showlist: false,
        typeName: row.name,
        typeicon: type,
      }));
      let event = "4bf58dd8d48988d14c941735";
      setState((prev) => ({ ...prev, selectedCategory: event }));

      let marker = props.data;

      // console.log("marker", marker);
      let GetFourSquare = await AppController.fourSquarData(marker, event);
      setState((prev) => ({
        ...prev,
        restlist: GetFourSquare,
        GetSquare: "",
        restlistarray: [1, 2],
        latitude: props.latitude,
        longitude: props.longitude,
        Loader: false,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        type: [row.type],
        showlist: false,
        typeName: row.name,
        typeicon: type,
        restlistarray: [],
        Loader: true,
      }));
      let dataaaa = [
        { key: "restaurant", id: "4bf58dd8d48988d116941735" },
        { key: "bar", id: "4bf58dd8d48988d116941735" },
        { key: "supermarket", id: "4bf58dd8d48988d118951735" },
        { key: "park", id: "4bf58dd8d48988d163941735" },
        { key: "shopping_mall", id: "4bf58dd8d48988d1fd941735" },
        { key: "night_club", id: "4d4b7104d754a06370d81259" },
        { key: "hospital", id: "4bf58dd8d48988d196941735" },
      ];
      let obj = dataaaa.find((o) => o.key == row.type.toString());
      // console.log(obj, "obj");
      let GetSquare = await AppController.fourSquarData(state.data, obj.id);
      // console.log(GetSquare, "GetSquare");
      setState((prev) => ({
        ...prev,
        getlistarray: [1, 2],
        GetSquare: GetSquare,
        restlist: "",
        latitude: props.latitude,
        longitude: props.longitude,
        Loader: false,
      }));
    }
  };
  const onChangeCategory = async (event) => {
    // console.log(event, "event of selected category");
    setState((prev) => ({ ...prev, selectedCategory: event }));
    let GetFourSquare = await AppController.fourSquarData(state.data, event);
    // console.log(GetFourSquare, "GetFourSquare");
    setState((prev) => ({
      ...prev,
      restlist: GetFourSquare,
      restlistarray: [1, 2],
      latitude: props.latitude,
      longitude: props.longitude,
    }));
  };
  const handleMarkerInfo = (data) => {
    // console.log("handlemarker", data);
    setState((prev) => ({
      ...prev,
      activeMaker: data,
      activeNumber: data.name,
      latitude: data.location.lat,
      longitude: data.location.lng,
    }));
  };
  const clearActiveMarker = () => {
    setState((prev) => ({ ...prev, activeMaker: null }));
  };
  const loadPlacesWithMarkers = async (event) => {
    let GetFourSquare = await AppController.fourSquarData(state.data, event);
  };
  const propertyInfo = (city) => {
    setState((prev) => ({ ...prev, propertyInfo: true }));
  };
  const clearProperty = () => {
    setState((prev) => ({ ...prev, propertyInfo: false }));
  };
  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  let children = jData.data;
  let categoryList = [
    { name: "Restaurants", id: "4bf58dd8d48988d116941735" },
    { name: "Groceries", id: "4bf58dd8d48988d118951735" },
    { name: "Parks", id: "4bf58dd8d48988d163941735" },
    { name: "Shopping", id: "4bf58dd8d48988d1fd941735" },
    { name: "Entertainment", id: "4d4b7104d754a06370d81259" },
    { name: "Hospitals", id: "4bf58dd8d48988d196941735" },
  ];
  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };
  const tab = () => {
    let tabItems =
      parseInt(screen.availWidth) <= 767
        ? 2
        : parseInt(screen.availWidth) <= 991
        ? 2
        : 3;
    setState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };
  $(document).ready(function () {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 575) {
      $(".client-testimonial-carousel").owlCarousel({
        items: 2,
        margin: 30,
        loop: false,
        dots: false,
      });
    } else if (screenWidth <= 767) {
      $(".client-testimonial-carousel").owlCarousel({
        items: 3,
        margin: 30,
        loop: false,
        dots: false,
      });
    } else {
      $(".client-testimonial-carousel").owlCarousel({
        items: 2,
        margin: 30,
        loop: false,
        dots: false,
      });
    }
  });

  return (
    <div className="row m-0">
      <div className="col-md-12 p-0">
        {" "}
        <div className="">{console.log(state.showlist, "28mar")}</div>{" "}
      </div>
      <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
        <div className="relomap-height">
          <GoogleMap
            zoom={12}
            center={
              state.latitude !== 0 &&
              state.latitude !== null &&
              state.longitude !== 0 &&
              state.longitude !== null
                ? {
                    lat: state.latitude,
                    lng: state.longitude,
                  }
                : { lat: 37.7739852, lng: -122.3912407 }
            }
            mapContainerStyle={{
              height: "100%",
              width: "100%",
            }}
            // options={{
            //   disableDefaultUI: true,
            // }}
            options={{
              zoomControl: true, // Enable zoom controls
              zoomControlOptions: {
                position: window.google.maps.ControlPosition.RIGHT_BOTTOM, // Position of zoom controls
              },
            }}
          >
            {props.isProperty && (
              <Marker
                position={{
                  lat: props.data.latitude,
                  lng: props.data.longitude,
                }}
                icon="/assets/images/propertyMarker.svg"
                onClick={() => propertyInfo()}
              >
                {state.propertyInfo ? (
                  <InfoWindow onCloseClick={clearProperty}>
                    <>
                      <p> {props.data.address}</p>
                    </>
                  </InfoWindow>
                ) : null}
              </Marker>
            )}
            {state.restlist &&
              state.restlist.length > 0 &&
              state.restlist.map((place) => {
                const lat = place.venue.location.lat;
                const lng = place.venue.location.lng;
                const position = { lat, lng };
                return (
                  <Marker
                    key={place.venue.id}
                    position={position}
                    title={place.venue.name}
                    icon="/assets/images/icons/restaurant-new.svg"
                    onClick={() => handleMarkerInfo(place.venue)}
                  >
                    {state.activeMaker &&
                    state.activeMaker.id === place.venue.id ? (
                      <InfoWindow onCloseClick={clearActiveMarker}>
                        <>
                          {" "}
                          {/* Wrap child elements in a parent div */}
                          <h6
                            style={{
                              fontSize: "20px",
                              textAlign: "center",
                            }}
                          >
                            {place.venue.name}
                          </h6>
                          <div>
                            <p>
                              {" "}
                              {place.venue.location.formattedAddress.toString()}
                            </p>
                            <a
                              href={`https://www.google.com/maps/place/${place.venue.location.formattedAddress}`}
                              target="_blank"
                            >
                              Get Directions
                            </a>
                          </div>
                          {/* Other child elements */}
                        </>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                );
              })}
            {state.GetSquare &&
              state.GetSquare.length > 0 &&
              state.GetSquare.map((place) => {
                const lat = place.venue.location.lat;
                const lng = place.venue.location.lng;
                const position = { lat, lng };
                return (
                  <Marker
                    key={place.venue.id}
                    position={position}
                    title={place.venue.name}
                    icon={
                      state.typeName == "Groceries"
                        ? "/assets/images/icons/grocery-1.svg"
                        : state.typeName == "Parks"
                        ? "/assets/images/icons/park-1.svg"
                        : state.typeName == "Shopping"
                        ? "/assets/images/icons/shopping-3.svg"
                        : state.typeName == "Entertainment"
                        ? "/assets/images/icons/entertainment-1.svg"
                        : "/assets/images/icons/hos-1.svg"
                    }
                    onClick={() => handleMarkerInfo(place.venue)}
                  >
                    {state.activeMaker &&
                    state.activeMaker.id === place.venue.id ? (
                      <InfoWindow onCloseClick={clearActiveMarker}>
                        <>
                          {" "}
                          {/* Wrap child elements in a parent div */}
                          <h6
                            style={{
                              fontSize: "20px",
                              textAlign: "center",
                            }}
                          >
                            {place.venue.name}
                          </h6>
                          <div>
                            <p>
                              {" "}
                              {place.venue.location.formattedAddress.toString()}
                            </p>
                            <a
                              href={`https://www.google.com/maps/place/${place.venue.location.formattedAddress}`}
                              target="_blank"
                            >
                              Get Directions
                            </a>
                          </div>
                          {/* Other child elements */}
                        </>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                );
              })}
          </GoogleMap>
        </div>
      </div>

      {state.Loader == true ? <div className="loading">Loading</div> : null}

      <div className="col-md-4 p-0">
        <div className="map-right-view rounded-end-4">
          <span className="toggleArrow" onClick={() => heightView()}>
            <i class="fa-solid fa-angle-up"></i>
          </span>

          <div className="heightGrid">
            <div className="slider mb-3">
              {/* <OwlCarousel
                    className="owl-theme"
                    dots={false}
                    items={state.tabItems}
                    startPosition={state.startPosition}
                    onDragged={(object) => updateCarouselPosition(object)}
                    onTranslated={(object) => updateCarouselPosition(object)}
                  >
                    {state.types.map((type, index) => (
                      <div key={index} className="item">
                        <a
                          className={`nav-item${
                            (state.typeName === ""
                              ? " Restaurants"
                              : state.typeName) === type.name
                              ? " active"
                              : ""
                          }`}
                          data-toggle="tab"
                          id={type.name.replace(/[^a-zA-Z]/g, "")}
                          onClick={() => onetype(type, index)}
                        >
                          <i className={type.faicon}></i>
                          {type.name}
                        </a>
                      </div>
                    ))}
                  </OwlCarousel> */}
              <div class="owl-carousel client-testimonial-carousel">
                {state.types.map((type, index) => (
                  <div key={index} className="item">
                    <a
                      className={`nav-item${
                        (state.typeName === ""
                          ? " Restaurants"
                          : state.typeName) === type.name
                          ? " active"
                          : ""
                      }`}
                      data-toggle="tab"
                      id={type.name.replace(/[^a-zA-Z]/g, "")}
                      onClick={() => onetype(type, index)}
                    >
                      <i className={type.faicon}></i>
                      {type.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            {state.typeName == "Restaurants" &&
              state.restlistarray &&
              state.restlistarray.length > 0 && (
                <>
                  <div id="multi-rest">
                    <Select
                      value={state.selectedCategory}
                      placeholder="Please select a City"
                      onChange={(event) => onChangeCategory(event)}
                    >
                      {/* <Select.Option value="" key={0}>
                Please Select from the list
              </Select.Option> */}
                      {_.sortBy(children, [
                        function (o) {
                          return o;
                        },
                      ]).map((row, i) => {
                        return (
                          <Select.Option value={row.id} key={i}>
                            {row.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="point-tails">
                    {state.restlist && state.restlist.length > 0 ? (
                      state.restlist.map((row, index) => {
                        return (
                          <div
                            className={
                              row.venue.name == state.activeNumber
                                ? "tail-view active"
                                : "tail-view"
                            }
                            key={index}
                            // onClick={() => mapIndexChange(row, index)}
                            onClick={() => handleMarkerInfo(row.venue)}
                          >
                            {row.photos && (
                              <div className="image">
                                <img
                                  src={
                                    "https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photoreference=" +
                                    row.photos[0].photo_reference +
                                    "&key=AIzaSyDiSo4yN97tS6CIfMqJCXItYCwgzVkIJVc"
                                  }
                                />
                              </div>
                            )}
                            <ul>
                              <li>
                                <h1>{row.venue.name}</h1>
                              </li>
                              <li>
                                <span>
                                  {row.venue.location.formattedAddress.toString()}
                                </span>
                              </li>
                            </ul>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className="no-content-msg MiddleText">
                        There are no points of interest available. Please select
                        another category.
                      </h3>
                    )}
                  </div>
                </>
              )}
            {state.typeName != "Restaurants" &&
              state.getlistarray &&
              state.getlistarray.length > 0 && (
                <div className="point-tails">
                  {state.GetSquare && state.GetSquare.length > 0 ? (
                    state.GetSquare.map((row, index) => {
                      return (
                        <div
                          className={
                            row.venue.name == state.activeNumber
                              ? "tail-view active"
                              : "tail-view"
                          }
                          key={index}
                          //   onClick={() => mapIndexChangeTypes(row, index)}
                          onClick={() => handleMarkerInfo(row.venue)}
                        >
                          {row.photos && (
                            <div className="image">
                              <img
                                src={
                                  "https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photoreference=" +
                                  row.photos[0].photo_reference +
                                  "&key=AIzaSyDiSo4yN97tS6CIfMqJCXItYCwgzVkIJVc"
                                }
                              />
                            </div>
                          )}
                          <ul>
                            <li>
                              <h1>{row.venue.name}</h1>
                            </li>
                            <li>
                              <span>
                                {row.venue.location.formattedAddress.toString()}
                              </span>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <h3 className="no-content-msg MiddleText">
                      There are no data available at this time.
                    </h3>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapViewFunctional;
