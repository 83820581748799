import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWalking,
  faBicycle,
  faTrain,
} from "@fortawesome/fontawesome-free-solid";
import { Progress, Spin } from "antd";

function WalkScore(props) {
  // console.log(props, "props");
  const { walkScoreDetails } = props;

  const filteredWalkScore =
    walkScoreDetails != undefined
      ? _.filter(walkScoreDetails, function (o) {
          return o.Score != null;
        })
      : [];
  return walkScoreDetails != undefined ? (
    <div className="scoreView">
      {filteredWalkScore.map((row, index) => {
        return (
          <div className="item" key={index}>
            <i className="">
              <FontAwesomeIcon
                icon={
                  row.iconImg == 1
                    ? faWalking
                    : row.iconImg == 2
                    ? faBicycle
                    : faTrain
                }
              />
            </i>

            <div className="new-walkcircle">
              <h5>{row.name}</h5>
              <Progress
                // type="circle"
                percent={row.Score}
                format={(percent) => `${percent}`}
              />
              {/* <div className="progress-value">
                {row.iconImg == 1 ? (
                  row.Score >= 90 ? (
                    <p>Walker's Paradise</p>
                  ) : row.Score >= 70 && row.Score < 90 ? (
                    <p>Very Walkable</p>
                  ) : row.Score >= 50 && row.Score < 70 ? (
                    <p>Somewhat Walkable</p>
                  ) : row.Score >= 25 && row.Score < 50 ? (
                    <p>Car-Dependent</p>
                  ) : (
                    <p>Car-Dependent</p>
                  )
                ) : row.iconImg == 2 ? (
                  row.Score >= 90 ? (
                    <p>Biker's Paradise</p>
                  ) : row.Score >= 70 && row.Score < 90 ? (
                    <p>Very Bikeable</p>
                  ) : row.Score >= 50 && row.Score < 70 ? (
                    <p>Bikeable</p>
                  ) : (
                    <p>Somewhat Bikeable</p>
                  )
                ) : (
                  <p>{row.description}</p>
                )}
              </div> */}
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div className="min-height-200 divSpin">
      <Spin />
    </div>
  );
}

export default WalkScore;
