import React, { useEffect, useState } from "react";
import { Select } from "antd";
import jData from "./dataFoursquare.json";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import AppController from "../controllers/appController";
import { Empty, Popover } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getGooglePlacesResults } from "./utils";
import GoogleMapsPlaces from "../../services/mapsPlaces";
import OwlCarousel from "react-owl-carousel";

function NearByPlaces(props) {
  // console.log(props, "props");
  const [state, setState] = useState({
    latitude: props.latitude,
    longitude: props.longitude,
    selectedCategory: "",
    mapRes: [],
    types: [
      {
        name: "Restaurants",
        type: "restaurant",
        faicon: "fa fa-cutlery",
        icon: "../assets/images/restaurant-new.svg",
      },
      {
        name: "Groceries",
        type: "supermarket",
        faicon: "fa fa-shopping-bag",
        icon: "../assets/images/grocery-1.svg",
      },
      {
        name: "Parks",
        type: "park",
        faicon: "fa fa-tree",
        icon: "../assets/images/park-1.svg",
      },
      {
        name: "Shopping",
        type: "shopping_mall",
        faicon: "fa fa-shopping-cart",
        icon: "../assets/images/shopping-3.svg",
      },
      {
        name: "Entertainment",
        type: "night_club",
        faicon: "fa fa-film",
        icon: "../assets/images/entertainment-1.svg",
      },
      {
        name: "Hospitals",
        type: "hospital",
        faicon: "fa fa-h-square",
        icon: "../assets/images/hos-1.svg",
      },
    ],
    type: "restaurant",
    typeName: "Restaurants",
    location: [],
    placeSearched: [],
    placeSearchedMarkers: [],
    markerList: [],
    showlist: false,
    showSearch: false,
    typeicon: 0,
    directionsService: {},
    directionsDisplay: {},
    communityName: "",
    restaurantsArr: [],
    restData: [],
    cityLanding: false,
    Loader: false,
    items: [],
    startPosition: "0",
    categories: [],
    RestaurantsMainArray: [],
    restaurantsList: [],
    typesList: [],
    RestauType: [],
    removeBoundary: false,
    mapText: "",
    activeNumber: -1,
    restaurantsRendered: 0,
    restlist: [],
    restlight: "",
    data: {},
    restlistarray: [],
    NearPlaces: [],
    activeMaker: null,
    activeNumber: -1,
    propertyInfo: false,
    propertyDetails: false,
    isProperty: false,
    propertyData: false,
    propertyName: "",
    propertyAddress: "",
    propertyIndex: "",
  });

  const navigate = useNavigate();
  // console.log(state, "state");
  useEffect(() => {
    async function fetchData() {
      // console.log(props, "useEffectprops");
      setState((prev) => ({
        ...prev,
        latitude: props.latitude,
        longitude: props.longitude,
        showlist: false,
        communityName: props.communityName,
        data: props.data,
        // Loader: false,
      }));
      tab();
    }
    fetchData();
  }, [props]);

  const onetype = async (row, type) => {
    console.log(row, "===", type);
    setState((prev) => ({
      Loader: true,
      ...prev,
      restaurantsArr: [],
      typesList: [],
      restaurantsList: [],
    }));
    if (row.type == "restaurant") {
      let lat = state.latitude;
      let lng = state.longitude;
      let type = row.type;
      loadPlacesWithMarkers(lat, lng, row.type);
      setState((prev) => ({
        ...prev,
        type: row.type,
        showlist: false,
        typeName: row.name,
        typeicon: type,
        Loader: false,
      }));
    } else {
      let lat = state.latitude;
      let lng = state.longitude;
      let type = row.type;
      loadPlacesWithMarkers(lat, lng, row.type);
      setState((prev) => ({
        ...prev,
        type: row.type,
        showlist: false,
        typeName: row.name,
        typeicon: type,
        restlistarray: [],
        Loader: false,
      }));
    }
  };
  const propertyNearBy = async (event, index) => {
    // console.log(event, "event ofpropertyNearBy", index);
    let GetNearPlaces = await GoogleMapsPlaces.GetNearByPlaces({
      latitude: event.latitude,
      longitude: event.longitude,
      type: state.type,
      radius: 2000,
    });
    // console.log(GetNearPlaces.results, "GetFourSquare");
    setState((prev) => ({
      ...prev,
      NearPlaces: GetNearPlaces.results,
      latitude: event.latitude,
      longitude: event.longitude,
      isProperty: true,
      propertyAddress: event.address,
      propertyName: event.propertyname,
      propertyIndex: index,
    }));
  };
  const handleMarkerInfo = (data) => {
    // console.log("handlemarker", data);
    setState((prev) => ({
      ...prev,
      activeMaker: data,
      activeNumber: data.name,
      latitude: data.geometry.location.lat,
      longitude: data.geometry.location.lng,
    }));
  };
  const clearActiveMarker = () => {
    setState((prev) => ({ ...prev, activeMaker: null }));
  };
  const loadPlacesWithMarkers = async (lat, lng, type) => {
    // console.log("clicked the markers function", lat, lng, type);
    let GetNearPlaces = await GoogleMapsPlaces.GetNearByPlaces({
      latitude: lat,
      longitude: lng,
      type: type,
      radius: 2000,
    });
    // console.log(GetNearPlaces.results, "GetFourSquare");
    setState((prev) => ({
      ...prev,
      NearPlaces: GetNearPlaces.results,
    }));
  };
  const propertyDetailsInfo = () => {
    setState((prev) => ({ ...prev, propertyDetails: true }));
  };
  const clearpropertyDetails = () => {
    setState((prev) => ({ ...prev, propertyDetails: false }));
  };
  const propertyDataInfo = () => {
    setState((prev) => ({ ...prev, propertyData: true }));
  };
  const clearpropertyData = () => {
    setState((prev) => ({ ...prev, propertyData: false }));
  };
  const moreInfoWithDetails = async (row) => {
    await localStorage.setItem("myPropertyDetails", JSON.stringify(row));
    // $("document").ready(function () {
    //   $("#propertymap").modal("hide");
    // });
    $(function () {
      $(document).ready(async function () {
        $("#comparemap").modal("hide");
        $("#propertymap").modal("hide");
        $("body").removeAttr("style");
        $(".modal-backdrop").remove(); // Hide the modal backdrop
      });
    });

    // window.open("/my_property_details", "myWindow");
    navigate("/my_property_details");
  };

  function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };
  const tab = () => {
    let tabItems =
      parseInt(screen.availWidth) <= 767
        ? 2
        : parseInt(screen.availWidth) <= 991
        ? 2
        : 3;
    setState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };
  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  return (
    // <div>
    //   <div>
    <div className="city-landing-page newOne">
      {state.Loader == true ? <div className="loading"></div> : null}
      <div className="tabs-view ">
        <ul className="nav nav-pills" role="tablist">
          {state.types.map((row, index) => {
            return (
              <li
                key={index}
                onClick={() => onetype(row, index)}
                className={
                  (state.typeName == "" ? "Restaurants" : state.typeName) ==
                  row.name
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className={row.faicon}></i>
                <a>{row.name}</a>{" "}
              </li>
            );
          })}
        </ul>
        <div className="row sharlotte-view tab-content ">
          <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
            <div id="mapview" className="relomap-height">
              <GoogleMap
                zoom={12}
                center={
                  isNumeric(state.latitude) && isNumeric(state.longitude)
                    ? {
                        lat: parseFloat(state.latitude),
                        lng: parseFloat(state.longitude),
                      }
                    : isNumeric(props.latitude) && isNumeric(props.longitude)
                    ? {
                        lat: parseFloat(props.latitude),
                        lng: parseFloat(props.longitude),
                      }
                    : { lat: 0, lng: 0 } // Default to (0, 0) if no valid coordinates are available
                }
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                }}
                // options={{
                //   disableDefaultUI: false, // Enable default UI controls including zoom
                // }}
                options={{
                  zoomControl: true, // Enable zoom controls
                  zoomControlOptions: {
                    position: window.google.maps.ControlPosition.RIGHT_BOTTOM, // Position of zoom controls
                  },
                }}
              >
                {props.propertyDetails && (
                  <Marker
                    position={{
                      lat: isNumeric(props.data.latitude)
                        ? parseFloat(props.data.latitude)
                        : 0,
                      lng: isNumeric(props.data.longitude)
                        ? parseFloat(props.data.longitude)
                        : 0,
                    }}
                    icon="/assets/images/map-Orange_icon.png"
                    onClick={() => propertyDetailsInfo()}
                  >
                    {state.propertyDetails ? (
                      <InfoWindow onCloseClick={clearpropertyDetails}>
                        <>
                          <p> {props.data.address}</p>
                          <a
                            href={`https://www.google.com/maps/place/${props.communityName}`}
                            target="_blank"
                          >
                            Get Directions
                          </a>
                        </>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                )}
                {state.isProperty && (
                  <Marker
                    position={{
                      lat: state.latitude,
                      lng: state.longitude,
                    }}
                    icon={{
                      url: "/assets/images/mapNew.png",
                      zIndex: 10,
                    }}
                    label={{
                      text: String(state.propertyIndex),
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "bold",
                      zIndex: 20,
                    }}
                    onClick={() => propertyDataInfo()}
                  >
                    {state.propertyData ? (
                      <InfoWindow onCloseClick={clearpropertyData}>
                        <>
                          <h6
                            style={{
                              fontSize: "20px",
                              textAlign: "center",
                            }}
                          >
                            {state.propertyName}
                          </h6>
                          <div>
                            <p> {state.propertyAddress.toString()}</p>
                            <a
                              href={`https://www.google.com/maps/place/${state.propertyAddress}`}
                              target="_blank"
                            >
                              Get Directions
                            </a>
                          </div>
                        </>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                )}
                {state.NearPlaces.length > 0 &&
                  state.NearPlaces.map((place) => {
                    const lat = place.geometry.location.lat;
                    const lng = place.geometry.location.lng;
                    const position = { lat, lng };
                    return (
                      <Marker
                        key={place.place_id}
                        position={position}
                        title={place.name}
                        icon={
                          state.typeName == "Restaurants"
                            ? "/assets/images/icons/restaurant-new.svg"
                            : state.typeName == "Groceries"
                            ? "/assets/images/icons/grocery-1.svg"
                            : state.typeName == "Parks"
                            ? "/assets/images/icons/park-1.svg"
                            : state.typeName == "Shopping"
                            ? "/assets/images/icons/shopping-3.svg"
                            : state.typeName == "Entertainment"
                            ? "/assets/images/icons/entertainment-1.svg"
                            : state.typeName == "Hospitals" &&
                              "/assets/images/icons/hos-1.svg"
                        }
                        onClick={() => handleMarkerInfo(place)}
                      >
                        {state.activeMaker &&
                        state.activeMaker.place_id === place.place_id ? (
                          <InfoWindow onCloseClick={clearActiveMarker}>
                            <>
                              <h6
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              >
                                {place.name}
                              </h6>
                              <div>
                                <p> {place.vicinity.toString()}</p>
                                <a
                                  href={`https://www.google.com/maps/place/${place.vicinity}`}
                                  target="_blank"
                                >
                                  Get Directions
                                </a>
                              </div>
                            </>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    );
                  })}
              </GoogleMap>
            </div>{" "}
          </div>

          <div className="col-md-4 p-0">
            <div className="map-right-view rounded-end-4">
              <span className="toggleArrow" onClick={() => heightView()}>
                <i class="fa-solid fa-angle-up"></i>
              </span>
              <div className="heightGrid">
                {/* <div className="slider tabSlider mb-3">
                <OwlCarousel
                  className="owl-theme"
                  dots={false}
                  items={state.tabItems}
                  startPosition={state.startPosition}
                  onDragged={(object) => updateCarouselPosition(object)}
                  onTranslated={(object) => updateCarouselPosition(object)}
                >
                  {state.types.map((type, index) => (
                    <div key={index} className="item">
                      <a
                        className={`nav-item${
                          (state.typeName === ""
                            ? " Restaurants"
                            : state.typeName) === type.name
                            ? " active"
                            : ""
                        }`}
                        data-toggle="tab"
                        id={type.name.replace(/[^a-zA-Z]/g, "")}
                        onClick={() => onetype(type, index)}
                      >
                        <i className={type.faicon}></i>
                        {type.name}
                      </a>
                    </div>
                  ))}
                </OwlCarousel>
              </div> */}
                {props.propertyDetails && (
                  <div className="propertyresulttext">
                    {props.myProp.length > 0 ? (
                      props.myProp.map((row, index) => {
                        return (
                          <div className="shResultspageimages" key={index}>
                            <span className="ScoreLabel sharlotte">
                              <Popover
                                placement="leftTop"
                                content={
                                  <div>
                                    {row.propertyrankitems.propertyrankingitems.map(
                                      (Pitem) => {
                                        return (
                                          <p
                                            key={
                                              "sharlotte" +
                                              index +
                                              Pitem.prioritylistitem
                                            }
                                          >
                                            {Pitem.prioritylistitem ==
                                            "Must Haves" ? (
                                              <Popover
                                                placement="leftTop"
                                                content={
                                                  <div>
                                                    {Pitem.matcheditems.map(
                                                      (matcheditem) => {
                                                        return (
                                                          matcheditem.itemname !=
                                                            "" && (
                                                            <p
                                                              key={
                                                                "sharlottepps" +
                                                                index +
                                                                matcheditem.itemname
                                                              }
                                                            >
                                                              {
                                                                matcheditem.itemname
                                                              }
                                                              {matcheditem.ismatched ==
                                                                true && (
                                                                <i className="fa fa-check"></i>
                                                              )}
                                                              {matcheditem.ismatched ==
                                                                false && (
                                                                <i className="fa fa-times"></i>
                                                              )}
                                                            </p>
                                                          )
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                }
                                                title="Matched Must Haves"
                                                trigger="hover"
                                              >
                                                {Pitem.prioritylistitem}{" "}
                                                <i
                                                  className="fa fa-info-circle"
                                                  style={{
                                                    color: "#000000",
                                                  }}
                                                ></i>
                                              </Popover>
                                            ) : (
                                              Pitem.prioritylistitem
                                            )}
                                            {Pitem.ismatched == true &&
                                              Pitem.isallmatched == false && (
                                                <i className="fa fa-check yellow_clr"></i>
                                              )}
                                            {Pitem.ismatched == true &&
                                              Pitem.isallmatched == true && (
                                                <i className="fa fa-check"></i>
                                              )}
                                            {Pitem.ismatched == true &&
                                              Pitem.isallmatched == null && (
                                                <i className="fa fa-check"></i>
                                              )}
                                            {Pitem.ismatched == false && (
                                              <i className="fa fa-times"></i>
                                            )}
                                          </p>
                                        );
                                      }
                                    )}
                                  </div>
                                }
                                title="Matched Priorities"
                                trigger="hover"
                              >
                                {Math.trunc(row.propertyrankitems.matchpercent)}
                                %
                              </Popover>
                            </span>
                            <div
                              className={
                                state.propertyIndex == index + 1
                                  ? "shr_textmain active "
                                  : "shr_textmain "
                              }
                            >
                              {/* <div
                                className={
                                  state.propertyIndex == index + 1
                                    ? "selectnumber "
                                    : "selectnumber "
                                }
                                onClick={() => propertyNearBy(row, index + 1)}
                              >
                                {index + 1}
                              </div> */}
                              <div
                                className="shr_text"
                                // onClick={() => props.mapIndexChange(row, index)}
                                onClick={() => propertyNearBy(row, index + 1)}
                                style={{ cursor: "pointer" }}
                              >
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => moreInfoWithDetails(row)}
                                >
                                  {row.propertyname}{" "}
                                  <i className="fa-regular fa-arrow-up-right"></i>
                                </a>
                                <span> {row.address} </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <span className="MapEmptyText">
                        <Link to="/myProperty">
                          Please add rentals to see here
                        </Link>
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  );
}

export default NearByPlaces;
