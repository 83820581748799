import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import "./login.css";
import ApiServices from "../../../../services/apiServices";
import AppController from "../../../controllers/appController";
import LoginStore from "../../../../services/thirdPartyService";
const publicIp = require("react-public-ip");
import moment from "moment";
import EmailAndSso from "./EmailAndSso";
import OtpCheck from "./OtpCheck";
import ThirdPartyApiService from "../../../../services/thirdPartyService";
import { decode as base64_decode, encode as base64_encode } from "base-64";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let googlecloudclientkey =
    "1243334706-cd5rlh1uru2ifkqbj8vphaficdmruhjh.apps.googleusercontent.com";
  const [state, setState] = useState({
    errorMessage: "",
    loaderlogin: false,
    userName: "",
    ifphonenull: false,
    validateOtp: false,
    showOtpCheckComponent: false,
    phoneNumber: "",
    email: "",
    countryCode: "",
    userType: "",
    userUniqueId: "",
    userInfo: {},
    emailAddress: "",
    otp: "",
    view: "emailView",
    isLoading: false,
    message: "",
    color: "red",
    isLoggedIn: null,
    resendOtpTimer: 90,
    showOtpSendLinks: true,
    OtpMsg: "",
    errMsg: "",
    userOtpText: "",
    loader: false,
    otpMode: "",
  });

  function navigateTo(url) {
    navigate(url);
  }
  const encodeData = (data) => {
    try {
      let encodeString = data;
      for (var i = 0; i < 10; i++) {
        encodeString = base64_encode(encodeString);
      }
      return encodeString;
    } catch (error) {
      return error;
    }
  };
  async function fetchUserDetails(loginObject) {
    // alert("==");
    let LoginResult = await ApiServices.TransfereeLoginCheck(loginObject);
    return LoginResult;
  }

  // function handleGoogleLogin() {
  //   // Google's OAuth 2.0 endpoint for requesting an access token
  //   var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

  //   // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  //   var form = document.createElement("form");
  //   form.setAttribute("method", "GET"); // Send as a GET request.
  //   form.setAttribute("action", oauth2Endpoint);

  //   // Parameters to pass to OAuth 2.0 endpoint.
  //   var params = {
  //     client_id: googlecloudclientkey,
  //     redirect_uri: "http://localhost:8081/login?sso=google",
  //     response_type: "token",
  //     scope:
  //       "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
  //     include_granted_scopes: "true",
  //     state: "pass-through value",
  //   };

  //   // Add form parameters as hidden input values.
  //   for (var p in params) {
  //     var input = document.createElement("input");
  //     input.setAttribute("type", "hidden");
  //     input.setAttribute("name", p);
  //     input.setAttribute("value", params[p]);
  //     form.appendChild(input);
  //   }

  //   // Add form to page and submit it to open the OAuth 2.0 endpoint.
  //   document.body.appendChild(form);
  //   form.submit();
  // }

  // function handleFacebookLogin() {
  //   const appId = "352103890479028"; // Replace with your actual Facebook App ID
  //   const redirectUri = "http://localhost:8081/login?sso=facebook"; // Replace with your actual redirect URI
  //   const stateParam = ""; // Replace with your actual state parameter
  //   const response_type = "token";
  //   const scopes = "email";

  //   // Construct the OAuth URL
  //   const oauthUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&state=${stateParam}&response_type=${response_type}&scope=${scopes}`;
  //   fetch(oauthUrl);
  //   window.location.href = oauthUrl;
  // }

  async function fetchIPAddress() {
    const ipv6 = await publicIp.v4().then(
      (ip) => {
        return ip;
      },
      function (error) {
        return "Coudn't find your IP";
      }
    );
  }

  // check the user email id
  async function onSubmitHandler() {
    // updateState({ loaderlogin: true });

    const emailAddress = state.emailAddress;
    const loginObject = {
      username: emailAddress,
      authtoken: "",
      ipaddress: await fetchIPAddress(),
    };
    if (loginObject.username == "" || loginObject.username == null) {
      changeMessageAndColor("Email should not be empty.", "red");
      stopLoader();
      navigateTo("/login");
    }
    let userDetails = await fetchUserDetails(loginObject);
    // console.log(userDetails, "userDetails");
    if (userDetails.error === "1") {
      changeMessageAndColor("Login Failed. User does not exist.", "red");
      stopLoader();
      navigateTo("/login");
    } else {
      redirectOnAccountType(userDetails);
      // sendOtpToEmail(emailAddress);
    }
  }

  //send otp to  the email
  async function sendOtpToEmail(email) {
    let datatoemail = {
      username: email.trim(),
    };
    if (email.toLowerCase() === "karthikab@yopmail.com") {
      changeMessageAndColor("Please enter the OTP.", "green");
      changeView("otpView");
      stopLoader();
    } else {
      let sentemailotp = await ThirdPartyApiService.sentemailotp(datatoemail);
      // console.log(sentemailotp, "sentemailotp");
      // sentemailotp = "response";
      if (sentemailotp == "response") {
        changeMessageAndColor("OTP has been sent to your email.", "green");
        changeView("otpView");
        stopLoader();
      } else if (sentemailotp == "error") {
        changeMessageAndColor(
          "Looks like you don't have account with us, please register.",
          "red"
        );
        stopLoader();
      }
    }
  }
  // check the user type
  function redirectOnAccountType(userDetails, slogin = null) {
    let userInfo = userDetails.message.userInfo;
    const emailAddress = state.emailAddress;
    // console.log("redirect", userInfo);

    if (userInfo.type == "CONSULTANT" && userInfo.isactive == false) {
      changeMessageAndColor("Your account has been deactivated. ", "red");
      stopLoader();
    } else if (
      userInfo.type == "TRANSFEREE" &&
      (!userInfo.accessfromdate ||
        moment(new Date()).isBefore(userInfo.accessfromdate))
    ) {
      // console.log("no access");
      let errorMessage = !userInfo.accessfromdate
        ? "you are not allowed to access the account,Because your access Date was not specified"
        : `Your access to ReloNavigator starts from ${moment(
            userInfo.accessfromdate
          ).format("MM/DD/YYYY")}`;
      // console.log(errorMessage, "errorMessage");
      changeMessageAndColor(errorMessage, "red");
      stopLoader();
    } else if (
      userInfo.type == "TRANSFEREE" &&
      (!userInfo.accesstodate ||
        moment(new Date()).isAfter(
          moment(userInfo.accesstodate).add(1, "days").format("MM/DD/YYYY")
        ))
    ) {
      // console.log("access expired");
      let errorMessage =
        "Your access to ReloNavigator has expired on " +
        (userInfo.accesstodate != ""
          ? moment(userInfo.accesstodate).format("MM/DD/YYYY")
          : moment(new Date()).format("MM/DD/YYYY")) +
        ". ";
      changeMessageAndColor(errorMessage, "red");
      stopLoader();
    } else {
      sendOtpToEmail(emailAddress);
      // console.log("else statement", userInfo);
      updateState({
        showOtpCheckComponent: true,
        phoneNumber: userInfo.PhoneNumber,
        email: userInfo.EmailId,
        countryCode: userInfo.countrycode,
        validateOtp: slogin == "socialLogin" ? false : true,
        userType: userInfo.type,
        loader: true,
        userUniqueId: userInfo.userUniqueId,
        userInfo: userInfo,
      });

      let subscriptionDetails = {
        subscriptioncode: userInfo.subscriptioncode,
        subscriptiondesc: userInfo.subscriptiondesc,
      };
      localStorage.setItem(
        "loginSubscribeDetails",
        JSON.stringify(subscriptionDetails)
      );
      // }
      //    else {
      //     callOtpBy("resend");
      //   }
    }
  }

  async function sendLoggedIndataToMobileApp(userInfomarion) {
    try {
      let encodeDatainfo = encodeData(userInfomarion.EmailId);

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: userInfomarion.type,
          subscriptioncode: userInfomarion.subscriptioncode,
          istemporary: userInfomarion.istemporary,
          EmailId: encodeDatainfo,
        })
      );

      localStorage.setItem("LoggedInMobile", "true");
    } catch (error) {}
  }
  // based on the user type next navigation route
  async function navigateBasedOnUserType(userInfo) {
    console.log(userInfo, "userInfo");
    await AppController.logData(
      "Login",
      "Login",
      location.pathname,
      state.userUniqueId,
      ""
    );
    let logindata = await LoginStore.LoginDetailsStore(userInfo);
    // console.log(logindata, "logindata");
    let getLoginData = await LoginStore.getLoginInfo({});
    sendLoggedIndataToMobileApp(getLoginData);
    // console.log(getLoginData, "getLoginData");
    if (logindata.status == "true") {
      if (state.userType == "TRANSFEREE") {
        let userPreferanceObject = {
          transfereeuniqeuid: state.userUniqueId,
        };

        let userSavedPreferances = await ApiServices.GetTransfereePreferences(
          userPreferanceObject
        );
        console.log(userSavedPreferances, "userSavedPreferances");
        if (
          userSavedPreferances.error == 0 &&
          userSavedPreferances.message.preferences.length < 1
        ) {
          window.location.href = "/getStartedFirst";
        } else {
          window.location.href = "/dsLanding";
        }
      } else if (state.userType == "SUPPLIER") {
        window.location.href = "/Dashboard";
      } else {
        window.location.href = "/ConsultantDashboard";
      }
    }
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  function updateState(updateObj) {
    setState((prevObj) => ({
      ...prevObj,
      ...updateObj,
    }));
  }
  const emailView = {
    text: "Send OTP",
    "button-id": "email-button",
    "input-id": "email-input",
    value: state.emailAddress,
    inputPlaceholder: "Your Email Address",
    headerText: "Hello.",
    subHeaderText:
      "Enter your email address to receive a one-time password (OTP) to login",
  };

  const otpView = {
    text: "Verify OTP",
    "button-id": "otp-button",
    "input-id": "otp-input",
    value: state.otp,
    inputPlaceholder: "Enter OTP",
    headerText: "Hello.",
  };
  function getDetails(key) {
    if (state.view === "emailView") {
      return emailView[key];
    } else {
      return otpView[key];
    }
  }
  function stopLoader() {
    updateState({ isLoading: false });
  }
  function changeMessageAndColor(message, color) {
    updateState({ message, color });
  }
  function startLoader() {
    updateState({ isLoading: true });
  }
  function changeView(view) {
    updateState({ view });
  }
  function isValidNumber(number) {
    const numberRegex = /\d+/g;
    if (numberRegex.test(number)) {
      return true;
    }
    return false;
  }
  function handleChange(e) {
    const targetId = e.target.id;
    const value = e.target.value;
    if (targetId === "email-input") {
      state.message && changeMessageAndColor("", "red");
      updateState({ emailAddress: value });
    } else if (targetId === "otp-input") {
      // Check if the key pressed is backspace
      if (e.nativeEvent.inputType === "deleteContentBackward") {
        // If backspace pressed and the OTP value is empty, do nothing
        if (state.otp.length === 0) return;
        // Remove the last digit from the OTP value
        updateState({ otp: value.slice(0, -1) });
      } else if (isValidNumber(value)) {
        console.log(value, "value");
        // Only update the state if the value is a valid number
        updateState({ otp: value });
      }
    }
  }
  function handleArrowKeyPress(e) {
    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      // Prevent the default arrow key behavior (moving the cursor)
      e.preventDefault();
      // Implement your custom logic for moving the cursor here
      const inputValue = e.target.value;
      const inputElement = e.target;
      const cursorPosition = inputElement.selectionStart;

      if (e.key === "ArrowLeft" && cursorPosition > 0) {
        inputElement.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
      } else if (e.key === "ArrowRight" && cursorPosition < inputValue.length) {
        inputElement.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
      }
    }
  }
  //verify the OTP
  async function verifyOtp(e) {
    e.preventDefault();
    updateState({ loader: true, OtpMsg: "", errMsg: "" });

    let dataverify = {
      connection: "email",
      email: state.emailAddress.trim(),
      verification_code: state.otp,
    };
    if (dataverify.verification_code == "") {
      changeMessageAndColor("OTP Should not be empty", "red");
      stopLoader();
      navigateTo("/login");
    }
    if (dataverify.email.toLowerCase() === "karthikab@yopmail.com") {
      let verifyOTP = 956010;
      if (verifyOTP == dataverify.verification_code) {
        updateState({ loader: true, OtpErrMsg: "", resendMsg: "" });
        await navigateBasedOnUserType(state.userInfo);
      } else if (
        !(dataverify.verification_code == "") &&
        verifyOTP != dataverify.verification_code
      ) {
        // console.log(otpVerification, "===");
        stopLoader();
        changeMessageAndColor("Please enter a valid verification code", "red");
      }
    } else {
      let otpVerification = await ThirdPartyApiService.sentotpverify(
        dataverify
      );
      //otpVerification = "success";
      if (
        !(dataverify.verification_code == "") &&
        otpVerification === "success"
      ) {
        updateState({ loader: true, OtpErrMsg: "", resendMsg: "" });
        await navigateBasedOnUserType(state.userInfo);
      } else if (
        !(dataverify.verification_code == "") &&
        otpVerification === "wrong"
      ) {
        console.log(otpVerification, "===");
        stopLoader();
        changeMessageAndColor("Please enter a valid verification code", "red");
      }
    }
  }
  function handleClick(e) {
    const targetId = e.target.id;
    if (targetId) {
      startLoader();
    }
    if (targetId === "email-button") {
      console.log("email button");
      onSubmitHandler();
    } else if (targetId === "otp-button") {
      verifyOtp(e);
    }
  }
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(location.search);

  //   const type = urlParams.get("type");
  //   const key = urlParams.get("key");

  //   console.log(key, "==", type);
  //   if (key && type) {
  //     continueToRegisterUser(key, type);
  //   }
  // }, []);

  return (
    // <div className="ds-login-container">
    //   {state.showOtpCheckComponent ? (
    //     <OtpCheck
    //       email={state.email}
    //       phoneNumber={state.phoneNumber}
    //       countrycode={state.countryCode}
    //       navigateBasedOnUserType={navigateBasedOnUserType}
    //       userInfo={state.userInfo}
    //     />
    //   ) : (
    //     <EmailAndSso
    //       errorMessage={state.errorMessage}
    //       userName={state.userName}
    //       loaderlogin={state.loaderlogin}
    //       handleGoogleLogin={handleGoogleLogin}
    //       handleFacebookLogin={handleFacebookLogin}
    //       inputHandler={(e) => inputHandler("userName", e.target.value)}
    //       onSubmitHandler={onSubmitHandler}
    //       googlecloudclientkey={googlecloudclientkey}
    //     />
    //   )}
    // </div>
    <div className="relonav-login ">
      <div className="item">
        <img
          src="/assets/newImages/login-banner.png"
          alt="logo"
          className="logoBanner"
        />
      </div>
      <div className="item">
        <form className="form-signin">
          <img src="/assets/newImages/relo-b&WLogin.png" height="50" alt="" />

          <Header
            headerText={getDetails("headerText")}
            subHeaderText={getDetails("subHeaderText")}
          />
          <MessageBox message={state.message} color={state.color} />
          <Input
            inputId={getDetails("input-id")}
            value={getDetails("value")}
            handleChange={handleChange}
            handleArrowKeyPress={handleArrowKeyPress}
            placeholder={getDetails("inputPlaceholder")}
          />

          {state.isLoading ? (
            <Loader />
          ) : (
            <div onClick={handleClick}>
              <Button
                buttonText={getDetails("text")}
                buttonId={getDetails("button-id")}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
const Button = ({ buttonText, buttonId }) => {
  return (
    <button className="btn btn-dark  px-5 mt-3" id={buttonId}>
      {buttonText}
    </button>
  );
};

const Loader = () => {
  return (
    <div className="mt-3">
      <span
        style={{
          fontSize: "20px",
          width: "100%",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <i className="fa fa-spinner fa-spin" />
        <span style={{ marginLeft: "10px" }}> please wait ...!</span>{" "}
      </span>
    </div>
  );
};

const Input = ({
  inputId,
  value,
  handleChange,
  handleArrowKeyPress,
  placeholder,
}) => {
  return (
    <div className="form-group input-group m-0 d-block">
      <span className="has-float-label">
        <input
          className="form-control"
          id={inputId}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={handleArrowKeyPress}
        />
      </span>
    </div>
  );
};

const MessageBox = ({ message, color }) => {
  return (
    <>
      {message != "" && (
        <div className="mt-3">
          {/* <h1 className="h3 mb-3 fw-normal pt-4">Hello.</h1> */}
          <div
            className={`alert ${
              color === "red" ? "alert" : "alert alert-dismissible"
            }`}
            style={{ fontSize: "15px" }}
          >
            {message}
          </div>
        </div>
      )}
    </>
  );
};

const Header = ({ headerText, subHeaderText }) => {
  return (
    <>
      {headerText && <h1 className="h3 mb-3 fw-bold pt-4">{headerText}</h1>}
      {subHeaderText && <span>{subHeaderText}</span>}
    </>
  );
};

export default Login;
