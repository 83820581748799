import React from "react";
import * as $ from "jquery";
import { useNavigate } from "react-router-dom";

function MovingTips() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalMovingTips").modal("hide");
      });
    });
  };
  const navigate = useNavigate();
  const handleItemClick = () => {
    navigate("/city-landing", { state: { fromCommute: true } });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Moving Tips</h2>
              <p>
                Relocating for an internship is an exciting step, but it can
                also be overwhelming. To make your transition as smooth as
                possible, we’ve compiled a list of moving tips designed to help
                you prepare and settle into your corporate housing with ease.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>Pre-Move Preparation</h2>
                  <h3>Organize Important Documents:</h3>

                  <ul>
                    <li>
                      Collect all necessary paperwork such as your internship
                      offer letter, identification, travel tickets, and any
                      documents related to your move.
                    </li>
                    <li>
                      Keep these documents in a dedicated folder or digital file
                      for easy access during your move.
                    </li>
                  </ul>

                  <h3>Plan Your Travel:</h3>
                  <ul>
                    <li>
                      Book your travel arrangements well in advance, whether
                      you’re flying, driving, or taking a train to your new
                      city.
                    </li>
                    <li>
                      Check the luggage allowance if flying, and plan for how
                      you’ll transport your essentials.
                    </li>
                  </ul>
                  <h3>Notify Relevant Parties:</h3>
                  <ul>
                    <li>
                      Inform your current landlord, if applicable, of your
                      move-out date.
                    </li>
                    <li>
                      Notify your bank, insurance providers, and any other
                      relevant institutions of your change of address if
                      necessary.
                    </li>
                  </ul>

                  <h3>Prepare a Moving Essentials Kit:</h3>
                  <ul>
                    <li>
                      Pack a bag with essential items you’ll need immediately
                      upon arrival, such as toiletries, a change of clothes,
                      medications, and important documents.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tails">
                <div className="item-view">
                  <h2>Packing Tips</h2>

                  <h3>Pack Efficiently:</h3>
                  <ul>
                    <li>
                      Use sturdy boxes and quality packing materials to protect
                      your belongings.
                    </li>
                    <li>
                      Label each box with its contents and the room it belongs
                      to for easy unpacking.
                    </li>
                  </ul>
                  <h3>Prioritize Essentials:</h3>
                  <ul>
                    <li>
                      Focus on packing items you’ll need during the first few
                      weeks, including work clothes, electronics, and personal
                      care items.
                    </li>
                    <li>
                      Consider the climate of your new location and pack
                      accordingly.
                    </li>
                  </ul>

                  <h3>Minimize Clutter:</h3>
                  <ul>
                    <li>
                      Take this opportunity to declutter. Donate, sell, or
                      dispose of items you no longer need.
                    </li>
                    <li>
                      Packing less will make your move easier and help you
                      settle in more quickly.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>Upon Arrival</h2>
                  <h3>Inspect Your Housing:</h3>
                  <ul>
                    <li>
                      Upon arrival, inspect your housing to ensure everything is
                      in order. Check for any damages or missing items and
                      report them immediately.
                    </li>
                    <li>
                      Familiarize yourself with the amenities and facilities
                      available to you.
                    </li>
                  </ul>

                  <h3>Settle In Quickly:</h3>
                  <ul>
                    <li>
                      Unpack your essentials first to make your new space feel
                      like home.
                    </li>
                    <li>
                      Arrange your belongings in a way that makes you
                      comfortable and helps you stay organized.
                    </li>
                  </ul>
                  <h3>Explore Your New Surroundings:</h3>
                  <ul>
                    <li>
                      Take some time to explore your new neighborhood. Use the
                      <a href="/citylist"> Explore </a> page to locate
                      restaurants, grocery stores, hospitals, and other
                      essential services near you.
                    </li>
                    <li>
                      Get to know the area around your workplace to ease your
                      daily commute. Use the
                      <a
                        style={{ color: "#0066cc", cursor: "pointer" }}
                        onClick={() => handleItemClick()}
                      >
                        {" "}
                        Commute
                      </a>{" "}
                      section to identify the best routes and transportation
                      options.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tails">
                <div className="item-view">
                  <h2>Staying Connected and Organized</h2>
                  <h3>Stay Connected:</h3>
                  <ul>
                    <li>
                      Ensure you have a reliable internet connection set up for
                      work and personal use.
                    </li>
                    <li>
                      Update your friends and family with your new contact
                      information.
                    </li>
                  </ul>

                  <h3>Organize Your Schedule:</h3>
                  <ul>
                    <li>
                      Create a schedule to balance your work responsibilities
                      with personal time.
                    </li>
                    <li>
                      Set goals for your work to make the most of this
                      opportunity and gain valuable experience.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tails">
                <div className="item-view">
                  <h2>Final Tips</h2>
                  <h3>Reach Out for Help:</h3>
                  <ul>
                    <li>
                      Don’t hesitate to ask your project coordinator or housing
                      manager for assistance with settling in or resolving any
                      issues.
                    </li>
                    <li>
                      Join local or professional groups to build a support
                      network.
                    </li>
                  </ul>
                  <h3>Take Care of Yourself:</h3>
                  <ul>
                    <li>
                      Moving and starting a new internship can be stressful.
                      Make sure to take time for self-care and relaxation.
                    </li>
                    <li>
                      Stay active and maintain a healthy routine to keep your
                      energy levels up.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="item-view mt-4">
              <p>
                By following these tips, you can reduce the stress of moving and
                focus on making the most of your relocation experience. With
                careful preparation and a positive mindset, you’ll be ready to
                settle into your new surroundings and thrive in your new role.
                Welcome to this exciting new chapter – we’re here to help you
                every step of the way.
              </p>
            </div>
            {/* <p>
              Source:&nbsp;
              <a target="_blank" href="http://www.ssa.gov/ssnumber/">
                ssa.gov
              </a>{" "}
              & &nbsp;
              <a
                target="_blank"
                href="https://www.ssa.gov/pubs/EN-05-10096.pdf"
              >
                ssa.gov/pubs
              </a>
            </p> */}
          </div>
        </div>
        {/* <div className="modal-footer"></div> */}
      </div>
    </div>
  );
}

export default MovingTips;
