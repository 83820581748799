import React from "react";
import DatePicker from "react-datepicker";

function DatePickerComponent(props) {
  const { state, questionList, handleChangeStart, questionIndex } = props;
  return (
    <div key={"sub" + questionIndex} className="">
      <h1>{questionList.question}</h1>
      {questionList.description != null ? (
        <h6>{questionList.description}</h6>
      ) : null}
      <DatePicker
        id="startdate"
        dateFormat="MM/dd/yyyy"
        selected={
          state.answersPrefer[questionList.preferenceUId] &&
          state.answersPrefer[questionList.preferenceUId].preferencesanswer !=
            "Invalid date"
            ? new Date(
                state.answersPrefer[
                  questionList.preferenceUId
                ].preferencesanswer
              )
            : new Date()
        }
        // minDate={new Date()}
        onChange={(e) =>
          handleChangeStart("address", e, questionList.preferenceUId)
        }
        placeholderText="Select start date ..."
        className="form-control preferenceDatePicker"
        autoComplete="off"
      />
      {state.isError &&
        (state.answersPrefer[questionList.preferenceUId] == undefined ||
          state.answersPrefer[questionList.preferenceUId].preferencesanswer ==
            "") && (
          <div className="tooltip-validation">
            <div className="tooltip-inner">{state.toolTipText}</div>
          </div>
        )}
    </div>
  );
}

export default DatePickerComponent;
