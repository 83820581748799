import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faHome,
  faMoneyBill,
  faCheck,
  faBed,
} from "@fortawesome/fontawesome-free-solid";

function NewDragDrop(props) {
  const { state, handleRightItemsUpdate } = props;
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);

  const allOptions = [
    { id: "1", icon: "fa-thin fa-person-walking", name: "Commute" },
    { id: "2", icon: "fa-light fa-house", name: "Home-Type" },
    { id: "3", icon: "fa-light fa-money-check-dollar", name: "Budget" },
    { id: "4", icon: "fa-light fa-circle-check", name: "Must-Haves" },
    { id: "5", icon: "fa-light fa-bed-front", name: "Bedrooms" },
  ];

  useEffect(() => {
    if (state?.answersPrefer["03b0317a-9dd7-401f-bf46-f06ec35ca802"]) {
      let preferredNames =
        state.answersPrefer["03b0317a-9dd7-401f-bf46-f06ec35ca802"]
          .preferencesanswer;
      filterOptionsByNames(preferredNames, allOptions);
    }
  }, [state?.answersPrefer["03b0317a-9dd7-401f-bf46-f06ec35ca802"]]);

  const filterOptionsByNames = (preferredNames, allOptions) => {
    const preferredNamesArray = preferredNames
      .split(",")
      .map((name) => name.trim());
    console.log(preferredNamesArray, "preferredNamesArray");

    // Initialize arrays for filtered and remaining options
    const filteredOptions = [];
    const remainingOptions = [];

    // Loop through allOptions and categorize based on preferredNames
    allOptions.forEach((option) => {
      if (preferredNamesArray.includes(option.name)) {
        filteredOptions.push(option);
      } else {
        remainingOptions.push(option);
      }
    });
    // Sort filteredOptions based on the order in preferredNamesArray
    const sortedFilteredOptions = preferredNamesArray
      .map((name) => filteredOptions.find((option) => option.name === name))
      .filter((option) => option !== undefined);

    // Update the ID for sorted filtered options based on their new positions
    const updatedFilteredOptions = sortedFilteredOptions.map(
      (option, index) => ({
        ...option,
        id: (index + 1).toString(), // Update ID based on position (1-based index)
      })
    );
    console.log(updatedFilteredOptions, "updatedFilteredOptions");

    // Update the ID for remaining options based on their new positions
    const updatedRemainingOptions = remainingOptions.map((option, index) => ({
      ...option,
      id: (updatedFilteredOptions.length + index + 1).toString(), // Continue ID numbering from where filtered options left off
    }));
    // Concatenate remaining and updated filtered options
    const updatedOptions = [
      ...updatedFilteredOptions,
      ...updatedRemainingOptions,
    ];

    // Assuming setItems is a function to update the items
    setItems(updatedOptions);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = [...items];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    // Update the `id` property of each item based on its new position
    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      id: (index + 1).toString(), // Assign a new id based on the position
    }));
    setItems(updatedItems);
    handleRightItemsUpdate(updatedItems);
    console.log(reorderedItems, "reorderedItems", updatedItems);
  };

  useEffect(() => {
    setTimeout(() => {
      handleRightItemsUpdate(items);
    }, 200);
  }, [items, handleRightItemsUpdate]);

  return (
    <>
      {state.listOfProperties && state.listOfProperties.length > 0 ? (
        <div className="list-priority-view-heading">
          {error && state.DragOptionsCheck == "" && (
            <div className="tooltip-validation">
              <div className="tooltip-inner">{state.toolTipText}</div>
            </div>
          )}
          <h1>{state.listOfProperties[0].question}</h1>
          <p style={{ paddingTop: "10px" }}>
            {state.listOfProperties[0].description === "" ||
            state.listOfProperties[0].description === undefined
              ? "Drag and Drop the categories in to the box below in the order of importance."
              : state.listOfProperties[0].description}
          </p>
        </div>
      ) : null}
      {state.listOfProperties && state.listOfProperties.length > 0 ? (
        <div className="demo-drag-drop">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="list-priority-view"
                >
                  {items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="drag">{item.id}</div>{" "}
                          <div className="drag">
                            <i className={item.icon}></i>
                            <p>{item.name}</p>
                            <img
                              className="dd-icon"
                              src="assets/images/drag-drop.svg"
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default NewDragDrop;
