import React, { useEffect, useState } from "react";
import { Select, Modal } from "antd";
import Data from "./data.json";
import ApiServices from "../../../../services/apiServices";
import _ from "lodash";
import * as $ from "jquery";
import "../../../../public/assets/css/JquerySignalR";
import "../../../../public/assets/css/jquery.signalR-2.4.1";
import SourceModal from "./modalPopUp/sourceModal";
// import Modal from "react-modal";

function UsMap(props) {
  let data = Data;
  const [state, setState] = useState({
    modalUrl: "",
    selectedValue: "Housing",
    Category: [],
    colorCode: "blue",
    getMapData: [],
    rulesArray: [],
    stateOverView: [],
    Loader: false,
    city: "Arkansas",
    groupdeValue: [],
    dcFileter: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const customStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      marginTop: "50px",
      transform: "translate(-50%, -50%)",
      maxHeight: "60%",
      width: "100%%",
    },
  };
  let one = [
    "Anti-Conversion Therapy",
    "Transgender Healthcare",
    "Discrimination in Child welfare services",
    "Education",
    "Employment",
    "Gender Marker Updates on Identification Documents",
    "Hate Crimes",
    "Housing",
    "Marriage Equality and Other Relationship Recognition",
    "Public Accomodations",
    "School Anti-Bullying",
  ].sort();
  const onearray = [];
  for (let i = 0; i < one.length; i++) {
    onearray.push(
      <Select.Option key={one[i]}>
        {one[i] == "Discrimination in Child welfare services"
          ? "Discrimination in Child Welfare Services"
          : one[i]}
      </Select.Option>
    );
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      try {
        let data = {
          state: state.city,
        };
        let GetSupportedLawByState = await ApiServices.GetSupportedLawsByState(
          data
        );
        setState((prev) => ({
          ...prev,
          Category: GetSupportedLawByState.message.SupportedLaw,
        }));
        $("path, circle").on("click", async function (e) {
          const _this = this;
          if ((e.delegateTarget.id != null) & (e.delegateTarget.id != "")) {
            $("document").ready(async function () {
              let GetOverViewByState = await ApiServices.GetOverViewByState(
                e.delegateTarget.id
              );
              setState((prev) => ({
                ...prev,
                stateOverView: GetOverViewByState.message.stateOverview,
              }));
              //   $("#myModal").modal("show");
            });
          }
        });

        $("path, circle").mouseleave(function (e) {
          $("#info-box").css("display", "none");
        });

        $(document)
          .mousemove(function (e) {
            $("#info-box").css("top", e.pageY - $("#info-box").height() - 30);
            $("#info-box").css("left", e.pageX - $("#info-box").width() / 2);
          })
          .mouseover();

        // var ios =
        //   /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        // if (ios) {
        //   $("a").on("click touchend", function () {
        //     var link = $(this).attr("href");
        //     window.open(link, "_blank");
        //     return false;
        //   });
        // }
        $("path, circle").hover(function (e) {
          $("path").css("cursor", "pointer");
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    handleClick(state.selectedValue);
  }, []);
  const handleClick = async (event) => {
    let selectedValue = event;
    setState((prev) => ({ ...prev, Loader: true }));
    let GetUsMapDataByState = await ApiServices.GetColurRuleByCategory(
      selectedValue
    );
    let dcFileter = _.filter(
      GetUsMapDataByState.message.ColurRule,
      function (element) {
        return element.State == "District of Columbia";
      }
    );

    let filterData = GetUsMapDataByState.message.ColurRule;
    const uniqueObjectFilter = [
      ...new Map(filterData.map((item) => [item.ColorCodes, item])).values(),
    ];
    let uniqueObjects = _.sortBy(uniqueObjectFilter, function (obj) {
      if (obj.Sequence != null && obj.Sequence != null)
        return parseInt(obj.Sequence, 10);
    });
    let originalData = Object.assign([], GetUsMapDataByState.message.ColurRule);
    let grouped = originalData.reduce((r, a) => {
      r[a.Rule] = [...(r[a.Rule] || []), a];
      return r;
    }, {});

    setState((prev) => ({
      ...prev,
      selectedValue: selectedValue,
      getMapData: GetUsMapDataByState.message.ColurRule,
      rulesArray: uniqueObjects,
      groupdeValue: grouped,
      dcFileter: dcFileter,
      Loader: false,
    }));
  };
  const handlePopUp = async () => {
    // setIsModalOpen(true);
    $("#myModal").modal("show");
  };
  const handleViewMore = async () => {
    let data = {
      state: state.stateOverView[0].State,
    };
    let GetSupportedLawByState = await ApiServices.GetSupportedLawsByState(
      data
    );
    console.log(
      GetSupportedLawByState.message.SupportedLaw,
      "GetSupportedLawByState.message.SupportedLaw"
    );
    setState((prev) => ({
      ...prev,
      Category: GetSupportedLawByState.message.SupportedLaw,
    }));
    props.tabChange(GetSupportedLawByState.message.SupportedLaw);
    // setIsModalOpen(false);
    $("#myModal").modal("hide");
  };
  const closePopUp = () => {
    $("#myModal").modal("hide");
  };
  const handleSourceLinkClick = (event) => {
    event.preventDefault(); // Prevent the default behavior of opening in a new tab
    const url = event.target.href;
    $("#mySourceModal").modal("show");
    setState((prev) => ({ ...prev, modalUrl: url }));
  };
  return (
    <div>
      {state.Loader == true ? <div className="loading">Loading</div> : null}
      <div className="di_E_main">
        <div className="container">
          <div className="dropdown di_E_dropdown">
            <Select
              placeholder="Please Category"
              value={state.selectedValue}
              onChange={(event) => handleClick(event)}
            >
              {onearray}
            </Select>
          </div>

          <div className="equalityIndexMainView">
            <div className="">
              <div className="map-pic">
                <div className="info-box"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="500"
                  x="0"
                  y="0"
                  preserveAspectRatio="xMinYMin meet"
                  version="1.1"
                  viewBox="174 100 959 593"
                  xmlSpace="preserve"
                >
                  <g>
                    {data.data.map((obj, index) => (
                      <path
                        key={index}
                        fill={
                          state.getMapData && state.getMapData.length > 0
                            ? state.getMapData[index].Category ===
                              state.selectedValue
                              ? state.getMapData[index].ColorCodes
                              : "#dddddd"
                            : "#dddddd"
                        }
                        d={obj.d}
                        data-info={obj.State}
                        id={obj.State}
                        onClick={handlePopUp}
                      ></path>
                    ))}
                  </g>
                </svg>
              </div>
            </div>
            <div className="">
              <ul className="clr-indicators">
                {state.rulesArray && state.rulesArray.length > 0
                  ? state.rulesArray.map((obj, index) =>
                      obj.Rule != null ? (
                        <li key={index}>
                          <i
                            className="fa fa-circle"
                            style={{ color: obj.ColorCodes }}
                          ></i>
                          <p>
                            {obj.Rule}

                            <span>
                              {state.dcFileter.Rule == obj.Rule
                                ? state.groupdeValue[obj.Rule].length - 1
                                : state.groupdeValue[obj.Rule].length}{" "}
                              {state.groupdeValue[obj.Rule].length > 1
                                ? "States"
                                : "State"}
                              {state.dcFileter[0].Rule == obj.Rule
                                ? "& DC"
                                : ""}
                            </span>
                          </p>
                        </li>
                      ) : null
                    )
                  : null}
              </ul>
            </div>
          </div>
        </div>
        <div className="SourceBottom">
          <b>Source: </b>
          <a
            href="https://www.hrc.org/resources/state-equality-index"
            target="_blank"
            // onClick={handleSourceLinkClick}
          >
            https://www.hrc.org/resources/state-equality-index
          </a>{" "}
          Accessed 1 December 2020
        </div>
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="mySourceModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <SourceModal url={state.modalUrl} />
      </div>

      {/* ---Modal--- */}
      {/* <Modal isOpen={isModalOpen} style={customStyles} ariaHideApp={false}> */}
      {/* <Modal
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        width={1150}
      > */}

      <div
        id="myModal"
        className="modal di_E_modal new_modal_pop"
        role="dialog"
        style={{ zIndex: 1060 }}
      >
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="width-100per pd-y-50">
              <div className="only_close">
                <span
                  className="pointer close"
                  data-dismiss="modal"
                  onClick={closePopUp}
                >
                  &times;{" "}
                </span>
              </div>
              {Array.isArray(state.stateOverView) &&
                state.stateOverView.length > 0 && (
                  <div className="modal-body">
                    <div className="grid_view">
                      <div className="item">
                        <div className="image">
                          {/* <img src="/assets/images/san-francisco-map.png" alt="" /> */}
                          <img
                            src={`/assets/images/state-images/${
                              state.stateOverView.length > 0
                                ? state.stateOverView[0].State
                                : "Nevada"
                            }.svg`}
                            alt=""
                          />
                          <p>
                            {state.stateOverView.length > 0
                              ? state.stateOverView[0].State
                              : "San Francisco"}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="content">
                          {state.stateOverView.length > 0 ? (
                            <ul>
                              {state.stateOverView[0].Education === "Yes" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Yes.png" />{" "}
                                  Education
                                </li>
                              ) : state.stateOverView[0].Education ===
                                "Partial" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Partial.png" />{" "}
                                  Education
                                </li>
                              ) : state.stateOverView[0].Education === "No" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/No.png" />
                                  Education
                                </li>
                              ) : null}

                              {state.stateOverView[0].TransgenderHealthcare ===
                              "Yes" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Yes.png" />{" "}
                                  Transgender Healthcare
                                </li>
                              ) : state.stateOverView[0]
                                  .TransgenderHealthcare === "Partial" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Partial.png" />{" "}
                                  Transgender Healthcare
                                </li>
                              ) : state.stateOverView[0]
                                  .TransgenderHealthcare === "No" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/No.png" />{" "}
                                  Transgender Healthcare
                                </li>
                              ) : null}

                              {state.stateOverView[0].Housing === "Yes" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Yes.png" />{" "}
                                  Housing
                                </li>
                              ) : state.stateOverView[0].Housing ===
                                "Partial" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Partial.png" />{" "}
                                  Housing
                                </li>
                              ) : state.stateOverView[0].Housing === "No" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/No.png" />{" "}
                                  Housing
                                </li>
                              ) : null}

                              {state.stateOverView[0].Employment === "Yes" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Yes.png" />{" "}
                                  Employment
                                </li>
                              ) : state.stateOverView[0].Employment ===
                                "Partial" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Partial.png" />{" "}
                                  Employment
                                </li>
                              ) : state.stateOverView[0].Employment === "No" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/No.png" />{" "}
                                  Employment
                                </li>
                              ) : null}

                              {state.stateOverView[0]
                                .MarriageEqualityOtherRelationshipRecognition ===
                              "Yes" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Yes.png" />{" "}
                                  Marriage Equality & Other Relationship
                                  Recognition
                                </li>
                              ) : state.stateOverView[0]
                                  .MarriageEqualityOtherRelationshipRecognition ===
                                "Partial" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Partial.png" />{" "}
                                  Marriage Equality & Other Relationship
                                  Recognition
                                </li>
                              ) : state.stateOverView[0]
                                  .MarriageEqualityOtherRelationshipRecognition ===
                                "No" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/No.png" />{" "}
                                  Marriage Equality & Other Relationship
                                  Recognition
                                </li>
                              ) : null}

                              {state.stateOverView[0].HateCrimes === "Yes" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Yes.png" />{" "}
                                  Hate Crimes 
                                </li>
                              ) : state.stateOverView[0].HateCrimes ===
                                "Partial" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Partial.png" />{" "}
                                  Hate Crimes 
                                </li>
                              ) : state.stateOverView[0].HateCrimes === "No" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/No.png" />{" "}
                                  Hate Crimes 
                                </li>
                              ) : null}

                              {state.stateOverView[0].PublicAccomodations ===
                              "Yes" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Yes.png" />{" "}
                                  Public Accommodations
                                </li>
                              ) : state.stateOverView[0].PublicAccomodations ===
                                "Partial" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Partial.png" />{" "}
                                  Public Accommodations
                                </li>
                              ) : state.stateOverView[0].PublicAccomodations ===
                                "No" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/No.png" />{" "}
                                  Public Accommodations
                                </li>
                              ) : null}

                              {state.stateOverView[0].SchoolAntiBullying ===
                              "Yes" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Yes.png" />{" "}
                                  School Anti-Bullying
                                </li>
                              ) : state.stateOverView[0].SchoolAntiBullying ===
                                "Partial" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Partial.png" />{" "}
                                  School Anti-Bullying
                                </li>
                              ) : state.stateOverView[0].SchoolAntiBullying ===
                                "No" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/No.png" />{" "}
                                  School Anti-Bullying
                                </li>
                              ) : null}

                              {state.stateOverView[0]
                                .GenderMarkerUpdatesOnIdentificationDocuments ===
                              "Yes" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Yes.png" />{" "}
                                  Gender Marker Updates On Identification
                                  Documents
                                </li>
                              ) : state.stateOverView[0]
                                  .GenderMarkerUpdatesOnIdentificationDocuments ===
                                "Partial" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Partial.png" />{" "}
                                  Gender Marker Updates On Identification
                                  Documents
                                </li>
                              ) : state.stateOverView[0]
                                  .GenderMarkerUpdatesOnIdentificationDocuments ===
                                "No" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/No.png" />{" "}
                                  Gender Marker Updates On Identification
                                  Documents
                                </li>
                              ) : null}
                              {state.stateOverView[0].AntiConversionTherapy ===
                              "Yes" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Yes.png" />{" "}
                                  Anti-Conversion Therapy
                                </li>
                              ) : state.stateOverView[0]
                                  .AntiConversionTherapy === "Partial" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Partial.png" />{" "}
                                  Anti-Conversion Therapy
                                </li>
                              ) : state.stateOverView[0]
                                  .AntiConversionTherapy === "No" ? (
                                <li>
                                  <img src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/No.png" />
                                  Anti-Conversion Therapy
                                </li>
                              ) : null}
                            </ul>
                          ) : null}

                          <a
                            // href="#"
                            className="view_more_a"
                            onClick={() => handleViewMore(true)}
                          >
                            {" "}
                            ViewMore &gt;&gt;{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      {/* </Modal> */}

      {/* </Modal> */}
    </div>
  );
}

export default UsMap;
