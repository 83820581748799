import { useState, useEffect } from "react";
import LoginStore from "../../../../services/thirdPartyService";
// import "./dsProfile.css";
import ApiServices from "../../../../services/apiServices";

const dsConsultant = (props) => {
  const [state, setState] = useState({
    consultantName: "",
    consultantNotes: "",
    consultantProfileImage: "",
    consultantemail: "",
    consultantphone: "",
    timezone: "",
    consultantcountrycode: "",
  });
  useEffect(() => {
    const fetch = async () => {
      getTransfareeData();
    };

    fetch();
  }, []);
  const getTransfareeData = async () => {
    let userInfo = await LoginStore.getLoginInfo({});

    let getTransfereeObj = {
      consultantuniqueid: userInfo.consultantUId,
      transfereeuniqueid: userInfo.userUniqueId,
    };
    let TransfereesList_Result = await ApiServices.GetConsultantTransferees(
      getTransfereeObj
    );
    await setState({
      consultantProfileImage:
        TransfereesList_Result.message.userInfo.consultantprofilepic,
      consultantNotes: TransfereesList_Result.message.userInfo.consultantnotes,
      userName:
        TransfereesList_Result.message.userInfo.userFirstName +
        " " +
        TransfereesList_Result.message.userInfo.userLastName,
      consultantName: TransfereesList_Result.message.userInfo.consultantname,
      consultantCities:
        TransfereesList_Result.message.userInfo.consultantfavcities,
      consultantemail: TransfereesList_Result.message.userInfo.consultantemail,
      consultantphone: TransfereesList_Result.message.userInfo.consultantphone,
      consultantcountrycode:
        TransfereesList_Result.message.userInfo.consultantcountrycode,
      timezone: TransfereesList_Result.message.userInfo.timezone,
    });
    console.log(TransfereesList_Result, "TransfereesList_Result");
  };
  return (
    <div className="">
      <div className="new-profile">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="image-view">
                {state.consultantProfileImage != null && (
                  <img
                    src={
                      state.consultantProfileImage != "" &&
                      state.consultantProfileImage != null
                        ? state.consultantProfileImage
                        : ""
                    }
                    alt=""
                    width="100%"
                  />
                )}
              </div>
            </div>
            <div className="col-md-8">
              <div className="image-view">
                <h1>About Me:</h1>
                <p>{state.consultantNotes}</p>
              </div>
            </div>
          </div>

          <div className="row mt-4 mb-4">
            <div className="col-md-4">
              <div className="image-view">
                <ul>
                  <li className="emailLi">
                    <span>{state.consultantName}</span>
                  </li>
                  <li className="emailLi">
                    <span>
                      {state.consultantcountrycode} {state.consultantphone}
                    </span>
                  </li>
                  <li className="emailLi">
                    <span>{state.consultantemail}</span>
                  </li>
                  <li className="emailLi">
                    <span>{state.timezone}</span>
                  </li>
                  <li>Let's Start Your Adventure!</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="appointment" onClick={props.showSchedules}>
                <img src="../assets/images/headset.svg" alt="" />
                <h3>Schedule Appointment</h3>
              </div>
            </div>
            <div className="col-md-4">
              <div className="appointment" onClick={props.showChat}>
                <img src="../assets/images/chat.svg" alt="" />
                <h3>Chat with Me</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default dsConsultant;
