import React, { useEffect, useState } from "react";
import apiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import AppController from "../../../controllers/appController";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

function ReligiousCenters() {
  const [state, setState] = useState({
    address: "",
    latitude: "",
    longitude: "",
    state: "",
    city: "",
    submitEnable: false,
    pointofin: [],
    activeName: "",
    Loader: true,
    worklatitude: "",
    worklongitude: "",
    workstate: "",
    workcity: "",
    workaddress: "",
    data: {
      latitude: 37.7739852,
      longitude: -122.3912407,
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    onSelectfromdropdown: true,
    activeMaker: null,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        let getSessionData = await LoginStore.getLoginInfo({});
        let userObject = {
          transfereeuniqeuid: getSessionData.userUniqueId,
          istemporary: getSessionData.istemporary,
        };
        let getQuestionList = await apiServices.GetPreferencesList(userObject);
        let userPreferanceObject = {
          transfereeuniqeuid: getSessionData.userUniqueId,
          istemporary: getSessionData.istemporary,
        };
        let userSavedPreferances = await apiServices.GetTransfereePreferences(
          userPreferanceObject
        );
        if (userSavedPreferances.error == 0) {
          let userAnswerPreferanceObject =
            userSavedPreferances.message.preferences;
          if (userAnswerPreferanceObject != undefined) {
            let answersPrefer = {};
            if (userAnswerPreferanceObject.length > 0) {
              _.forEach(userAnswerPreferanceObject, function (answerObject) {
                answersPrefer[answerObject.preferencesmasteruniqueid] =
                  answerObject;
              });
              let sampleAnswerArray = _.filter(
                getQuestionList.message.preferencesList,
                function (o) {
                  return answersPrefer[o.preferenceUId] == undefined;
                }
              );
              if (sampleAnswerArray.length > 0) {
                let obj = sampleAnswerArray[0];
                obj.preferencesanswer = "";
                answersPrefer[sampleAnswerArray[0].preferenceUId] = obj;
              }
              let savedAnswers = answersPrefer;
              updateLocationState(getQuestionList, savedAnswers);

              setState((prev) => ({
                ...prev,
                answersPrefer: answersPrefer,
              }));
            } else {
              _.forEach(
                getQuestionList.message.preferencesList,
                function (answerObject) {
                  let answerDefaultVal = "";
                  if (answerObject.type == "money-range") {
                    answerDefaultVal = "500,1000";
                  }
                  if (answerObject.type == "Time-range") {
                    answerDefaultVal = "15,30";
                  }
                  answerObject.preferencesanswer = answerDefaultVal;
                  answerObject.transfereeuniqeuid = getSessionData.userUniqueId;
                  answerObject.preferencesmasteruniqueid =
                    answerObject.preferenceUId;
                  answerObject.latitude = null;
                  answerObject.longitude = null;
                  answersPrefer[answerObject.preferenceUId] = answerObject;
                }
              );
              setState((prev) => ({
                ...prev,
                answersPrefer: answersPrefer,
              }));
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const updateLocationState = (getQuestionList, answersPrefer) => {
    // console.log(answersPrefer, "answersPrefer");
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });
      var data = answersPrefer[workAddressArray[0].preferenceUId];
      let type = "4bf58dd8d48988d131941735";
      let datamakers = {
        latitude: data.latitude,
        longitude: data.longitude,
        state_name: data.state,
        city_name: data.city,
        city: data.city,
        address: data.address,
      };
      loadSchoolMarkers(datamakers, type);
      // console.log(data, "data");
      setState((prev) => ({
        ...prev,
        latitude: data.latitude,
        longitude: data.longitude,
        state: data.preferencesanswer.split(",")[2],
        city: data.preferencesanswer.split(",")[1],
        address: data.preferencesanswer,
        data: {
          latitude: data.latitude,
          longitude: data.longitude,
          state_name: data.preferencesanswer.split(",")[2],
          city_name: data.preferencesanswer.split(",")[1],
          city: data.preferencesanswer.split(",")[1],
          address: data.preferencesanswer,
        },
      }));
    }
  };
  const setLocation = async (Id, e) => {
    let address = e.target.value;
    setState((prev) => ({
      ...prev,
      [Id]: address,
    }));

    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    places.setComponentRestrictions({ country: ["us"] });
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;
      // console.log(relocatedAddress, "relocated");
      // setState((prev) => ({ ...prev, onSelectfromdropdown: true }));
      //await _ setState({ address: relocatedAddress,submitEnable:relocatedAddress !== '' || relocatedAddress !== null ? false : true });

      setState((prev) => ({
        ...prev,
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
        submitEnable:
          relocatedAddress !== "" || relocatedAddress !== null ? false : true,
      }));
    });
  };
  const loadSchoolMarkers = async (datass, typess) => {
    let PoiFourSquare = await AppController.fourSquarData(datass, typess);
    // console.log(PoiFourSquare, "PoiFourSquare");
    setState((prev) => ({
      ...prev,
      pointofin: PoiFourSquare,
      poiList: [],
      mapRes: [],
      Loader: false,
    }));
    // setState({ poiList: [], mapRes: [], pointofin: [], Loader: true });
    //  setState({ pointofin: PoiFourSquare })
    if (state.pointofin.length > 0) {
      setState((prev) => ({
        ...prev,
        Loader: false,
        mapRes: _.concat(state.mapRes, {
          address: datass.address,
          addressCity: "",
          city: datass.city,
          latitude: datass.latitude,
          longitude: datass.longitude,
          name: datass.city,
          images: "",
          zoom: 10,
          iconShowCustom: true,
          isWork: false,
          isProperty: true,
          communityObject: {
            latitude: datass.latitude,
            longitude: datass.longitude,
          },
        }),
      }));
      setState((prev) => ({
        ...prev,
        mapRes: _.concat(state.mapRes, {
          address: state.data.address,
          addressCity: "",
          city: state.data.city,
          latitude: state.data.latitude,
          longitude: state.data.longitude,
          name: state.data.address,
          images: "",
          zoom: 10,
          iconShowCustom: false,
          isWork: true,
          isProperty: true,
          communityObject: {
            latitude: state.data.latitude,
            longitude: state.data.longitude,
          },
        }),
      }));
      // for (var i = 0; i < state.pointofin.length; i++) {
      //   setState((prev) => ({
      //     ...prev,
      //     Loader: false,
      //     mapRes: _.concat(state.mapRes, {
      //       address:
      //         PoiFourSquare[i].venue.location.formattedAddress.toString(),
      //       addressCity: "",
      //       city: PoiFourSquare[i].venue.name,
      //       latitude: PoiFourSquare[i].venue.location.lat,
      //       longitude: PoiFourSquare[i].venue.location.lng,
      //       name: PoiFourSquare[i].venue.location.name,
      //       schoolName: PoiFourSquare[i].venue.name,
      //       images: "",
      //       zoom: 10 + i,
      //       iconShowCustom: false,
      //       isWork: false,
      //       isProperty: state.isProperty,
      //       communityObject: {
      //         latitude: PoiFourSquare[i].venue.location.lat,
      //         longitude: PoiFourSquare[i].venue.location.lng,
      //       },
      //     }),
      //   }));
      //   // mapView(state.mapRes);
      // }
      // setTimeout(async () => {
      //   setState((prev) => ({ ...prev, Loader: false }));
      // }, 150);
    }
  };
  const handleSubmitLocation = async () => {
    setState((prev) => ({ ...prev, onSelectfromdropdown: true }));
    let type = "4bf58dd8d48988d131941735";
    let data = {
      latitude: state.latitude,
      longitude: state.longitude,
      state_name: state.state,
      city_name: state.city,
      city: state.city,
      address: state.address,
    };
    loadSchoolMarkers(data, type);
  };
  const handleMarkerInfo = (data) => {
    // console.log("handlemarker", data);
    setState((prev) => ({
      ...prev,
      activeMaker: data,
      activeName: data.name,
      data: { latitude: data.location.lat, longitude: data.location.lng },
    }));
  };
  const clearActiveMarker = () => {
    setState((prev) => ({ ...prev, activeMaker: null }));
  };
  function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  const propertyDetailsInfo = () => {
    setState((prev) => ({ ...prev, propertyDetails: true }));
  };
  const clearpropertyDetails = () => {
    setState((prev) => ({ ...prev, propertyDetails: false }));
  };
  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  return (
    <div>
      {state.Loader == true ? <div className="loading">Loading</div> : null}
      {/* <div className="">
        <div className=""> */}
      <div className="religious_mainView ">
        <div className="row m-0">
          <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
            <div id="mapus" className="b-radius-8 relomap-height">
              <GoogleMap
                zoom={12}
                center={
                  isNumeric(state.latitude) && isNumeric(state.longitude)
                    ? {
                        lat: parseFloat(state.latitude),
                        lng: parseFloat(state.longitude),
                      }
                    : { lat: 37.7739852, lng: -122.3912407 } // Provide default coordinates
                }
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                }}
                // options={{
                //   disableDefaultUI: true,
                // }}
                options={{
                  zoomControl: true, // Enable zoom controls
                  zoomControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM, // Position of zoom controls
                  },
                }}
              >
                {state.pointofin.length > 0 &&
                  state.pointofin.map((place) => {
                    const lat = place.venue.location.lat;
                    const lng = place.venue.location.lng;
                    const position = { lat, lng };
                    return (
                      <Marker
                        key={place.venue.id}
                        position={position}
                        title={place.venue.name}
                        onClick={() => handleMarkerInfo(place.venue)}
                        icon={{
                          url: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg",
                          scaledSize: new window.google.maps.Size(30, 30),
                        }}
                      >
                        {/* {console.log(place.venue, "makers")} */}
                        {state.activeMaker &&
                        state.activeMaker.id === place.venue.id ? (
                          <InfoWindow onCloseClick={clearActiveMarker}>
                            <>
                              {" "}
                              {/* Wrap child elements in a parent div */}
                              <h6
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              >
                                {place.venue.name}
                              </h6>
                              <div>
                                <p>
                                  {" "}
                                  {place.venue.location.formattedAddress.toString()}
                                </p>
                                <a
                                  href={`https://www.google.com/maps/place/${place.venue.location.formattedAddress}`}
                                  target="_blank"
                                >
                                  Get Directions
                                </a>
                              </div>
                              {/* Other child elements */}
                            </>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    );
                  })}
                {state.data && (
                  <Marker
                    position={{
                      lat: isNumeric(state.latitude)
                        ? parseFloat(state.latitude)
                        : 0,
                      lng: isNumeric(state.longitude)
                        ? parseFloat(state.longitude)
                        : 0,
                    }}
                    onClick={() => propertyDetailsInfo()}
                    icon="/assets/images/propertyMarker.svg"
                  >
                    {state.propertyDetails ? (
                      <InfoWindow onCloseClick={clearpropertyDetails}>
                        <p> {state.address}</p>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                )}
              </GoogleMap>
            </div>
          </div>
          <div className="col-md-4">
            <div className="map-right-view rounded-end-4">
              <span className="toggleArrow" onClick={() => heightView()}>
                <i class="fa-solid fa-angle-up"></i>
              </span>
              <div className="heightGrid">
                <div className="explore-nearby-view m-0">
                  <form className="navbar-form" role="search">
                    <div className="input-group add-on">
                      <input
                        onChange={(e) => setLocation("address", e)}
                        className="form-control"
                        value={state.address}
                        placeholder="Search"
                        name="address"
                        id="address"
                        type="text"
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-default"
                          type="button"
                          onClick={() => handleSubmitLocation()}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="point-tails">
                  <div className="map_rightSide_tails">
                    {state.pointofin.length > 0 ? (
                      state.pointofin.map((row, index) => {
                        return (
                          <div
                            className={
                              state.activeName == row.venue.name
                                ? "tail-view active"
                                : "tail-view"
                            }
                            key={index}
                            // onClick={() => mapIndexChange(row, index)}
                            onClick={() => handleMarkerInfo(row.venue)}
                          >
                            {row.photos && (
                              <div className="image">
                                <img
                                  src={
                                    "https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photoreference=" +
                                    row.photos[0].photo_reference +
                                    "&key=AIzaSyDiSo4yN97tS6CIfMqJCXItYCwgzVkIJVc"
                                  }
                                />
                              </div>
                            )}
                            <ul>
                              <li>
                                <h1>{row.venue.name}</h1>
                              </li>
                              <li>
                                {/* <i
                                  class="fa-solid fa-location-dot"
                                  aria-hidden="true"
                                ></i> */}
                                {/* <i
                                  className="fa fa-map-marker"
                                  aria-hidden="true"
                                ></i> */}
                                <span>
                                  {row.venue.location.formattedAddress.toString()}
                                </span>
                              </li>
                            </ul>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className="no-content-msg MiddleText">
                        There are no data available at this time.
                      </h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  );
}

export default ReligiousCenters;
